<template>
    <div class="max-w-[100%] lg:max-w-[900px] mx-auto bg-secondary-light min-h-screen font-notoSans  text-tertiary">
        <div class=" flex justify-between items-center px-4">
            <router-link to="/calculate-bazi" class="cursor-pointer hidden-print">
                <font-awesome-icon icon="fa-solid fa-reply" size="lg" />
            </router-link>
            <span class="cursor-pointer hidden-print" @click="handlePrint">
                <font-awesome-icon icon="fa-solid fa-print" size="lg" />
            </span>
        </div>

        <div class="relative font-notoKhmer  w-full mx-auto bg-white py-4 my-2">
            <img class="w-4/5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-10 -z-0"
                src="../assets/logo.png" alt="">
            <div class="flex justify-center">
                <img class="w-[200px] md:w-[200px]" src="../assets/logo.png" alt="">
            </div>



            <div class="px-2 space-y-2 mt-10 text-lg ">

                <div class=" flex items-center space-x-2">
                    <p class="flex space-x-2">
                        <span>ឈ្មោះ</span>
                        <span> {{ name }}</span>
                    </p>
                    <p class="flex space-x-1">
                        <span>ភេទ</span>
                        <span>{{ gender === 'Male' ? "ប្រុស" : "ស្រី" }}</span>
                    </p>
                    <p class="flex space-x-1">
                        <span>កូនភ្លោះទី2</span>
                        <span>{{ twin === 'true' ? "✓" : "✖" }}</span>
                    </p>

                </div>
                <div class="font-notoKhmer flex flex-col justify-start md:flex-row items-start md:space-x-4">
                    <div class="flex space-x-2">
                        <p class="flex space-x-1">
                            <span>កើតថ្ងៃ</span>
                            <span>{{ day }}</span>
                        </p>
                        <p class="flex space-x-1">
                            <span>ខែ</span>
                            <span>{{ formatMonth(Number(month)) }}</span>
                        </p>
                        <p class="flex space-x-1">
                            <span>ឆ្នាំ</span>
                            <span>{{ year }}</span>
                        </p>
                    </div>

                    <div class="flex space-x-2">
                        <p class="flex space-x-1">
                            <span>ម៉ោង</span>
                            <span>{{ unknowTimeOfBirth === 'false' ? hour : "N/A" }}</span>
                            <span>:</span>
                            <span>{{ unknowTimeOfBirth === 'false' ? minute : "N/A" }}</span>
                        </p>
                        <p class="flex space-x-1">
                            <span>អាយុ</span>
                            <span>{{ userAge }}</span>
                        </p>
                        <p class="flex space-x-1">
                            <span>លេខគ័រ</span>
                            <span>{{ kouyNumber }}</span>
                        </p>
                    </div>

                </div>
            </div>

            <div class="relative  mt-2 p-2">
                <table class="w-full border-collapse border border-black max-w-lg">
                    <thead>
                        <tr class="border border-black  ">
                            <th class="w-[100px] text-center print-td">ម៉ោង</th>
                            <th class="w-[100px] text-center print-td">ថ្ងៃ</th>
                            <th class="w-[100px] text-center print-td">ខែ</th>
                            <th class="w-[100px] text-center print-td">ឆ្នាំ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in dataResultPredict" :key="p" class="border border-black ">
                            <td class="print-td text-center" :class="{
                                'text-color1': p.elementNameTime === 'ភ្លើង',
                                'text-color5': p.elementNameTime === 'ដី',
                                'text-color2': p.elementNameTime === 'ទឹក',
                                'text-color4': p.elementNameTime === 'មាស',
                                'text-color3': p.elementNameTime === 'ឈើ',

                                '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(p.elementNameTime)
                            }">
                                {{ p.elementNameTime }}{{ p.elementNameTime != 'N/A' ? p.elementSignTime : " " }}
                            </td>


                            <td class="print-td text-center" :class="{
                                'text-color1': p.elementNameDay === 'ភ្លើង',
                                'text-color5': p.elementNameDay === 'ដី',
                                'text-color2': p.elementNameDay === 'ទឹក',
                                'text-color4': p.elementNameDay === 'មាស',
                                'text-color3': p.elementNameDay === 'ឈើ',

                                '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(p.elementNameDay)
                            }"> {{ p.elementNameDay }}{{ p.elementSignDay }}</td>
                            <td class="print-td text-center" :class="{
                                'text-color1': p.elementNameMonth === 'ភ្លើង',
                                'text-color5': p.elementNameMonth === 'ដី',
                                'text-color2': p.elementNameMonth === 'ទឹក',
                                'text-color4': p.elementNameMonth === 'មាស',
                                'text-color3': p.elementNameMonth === 'ឈើ',

                                '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(p.elementNameMonth)
                            }"> {{ p.elementNameMonth }}{{ p.elementSignMonth }}</td>

                            <td class="print-td text-center" :class="{
                                'text-color1': p.elementNameYear === 'ភ្លើង',
                                'text-color5': p.elementNameYear === 'ដី',
                                'text-color2': p.elementNameYear === 'ទឹក',
                                'text-color4': p.elementNameYear === 'មាស',
                                'text-color3': p.elementNameYear === 'ឈើ',

                                '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(p.elementNameYear)
                            }"> {{ p.elementNameYear }}{{ p.elementSignYear }}</td>




                        </tr>

                        <tr v-for="p in dataResultPredict" :key="p">
                            <td class="print-td text-center" :class="{
                                'text-color2.1': p.zodiacSignTime === 'ជូត',
                                'text-color5': p.zodiacSignTime === 'ឆ្លូវ',
                                'text-color3': p.zodiacSignTime === 'ខាល',
                                'text-color3.1': p.zodiacSignTime === 'ថោះ',
                                'text-color5.1': p.zodiacSignTime === 'រោង',
                                'text-color1': p.zodiacSignTime === 'ម្សាញ់',
                                'text-color1.1': p.zodiacSignTime === 'មមី',
                                'text-color5.2': p.zodiacSignTime === 'មមែ',
                                'text-color4': p.zodiacSignTime === 'វក',
                                'text-color4.1': p.zodiacSignTime === 'រកា',
                                'text-color5.3': p.zodiacSignTime === 'ច',
                                'text-color2': p.zodiacSignTime === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(p.zodiacSignTime)
                            }">
                                {{ p.zodiacSignTime }}

                            </td>

                            <td class="print-td text-center" :class="{
                                'text-color2.1': p.zodiacSignDay === 'ជូត',
                                'text-color5': p.zodiacSignDay === 'ឆ្លូវ',
                                'text-color3': p.zodiacSignDay === 'ខាល',
                                'text-color3.1': p.zodiacSignDay === 'ថោះ',
                                'text-color5.1': p.zodiacSignDay === 'រោង',
                                'text-color1': p.zodiacSignDay === 'ម្សាញ់',
                                'text-color1.1': p.zodiacSignDay === 'មមី',
                                'text-color5.2': p.zodiacSignDay === 'មមែ',
                                'text-color4': p.zodiacSignDay === 'វក',
                                'text-color4.1': p.zodiacSignDay === 'រកា',
                                'text-color5.3': p.zodiacSignDay === 'ច',
                                'text-color2': p.zodiacSignDay === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(p.zodiacSignDay)
                            }">
                                {{ p.zodiacSignDay }}

                            </td>
                            <td class="print-td text-center" :class="{
                                'text-color2.1': p.zodiacSignMonth === 'ជូត',
                                'text-color5': p.zodiacSignMonth === 'ឆ្លូវ',
                                'text-color3': p.zodiacSignMonth === 'ខាល',
                                'text-color3.1': p.zodiacSignMonth === 'ថោះ',
                                'text-color5.1': p.zodiacSignMonth === 'រោង',
                                'text-color1': p.zodiacSignMonth === 'ម្សាញ់',
                                'text-color1.1': p.zodiacSignMonth === 'មមី',
                                'text-color5.2': p.zodiacSignMonth === 'មមែ',
                                'text-color4': p.zodiacSignMonth === 'វក',
                                'text-color4.1': p.zodiacSignMonth === 'រកា',
                                'text-color5.3': p.zodiacSignMonth === 'ច',
                                'text-color2': p.zodiacSignMonth === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(p.zodiacSignMonth)
                            }">
                                {{ p.zodiacSignMonth }}

                            </td>
                            <td class="print-td text-center" :class="{
                                'text-color2.1': p.zodiacSignYear === 'ជូត',
                                'text-color5': p.zodiacSignYear === 'ឆ្លូវ',
                                'text-color3': p.zodiacSignYear === 'ខាល',
                                'text-color3.1': p.zodiacSignYear === 'ថោះ',
                                'text-color5.1': p.zodiacSignYear === 'រោង',
                                'text-color1': p.zodiacSignYear === 'ម្សាញ់',
                                'text-color1.1': p.zodiacSignYear === 'មមី',
                                'text-color5.2': p.zodiacSignYear === 'មមែ',
                                'text-color4': p.zodiacSignYear === 'វក',
                                'text-color4.1': p.zodiacSignYear === 'រកា',
                                'text-color5.3': p.zodiacSignYear === 'ច',
                                'text-color2': p.zodiacSignYear === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(p.zodiacSignYear)
                            }">
                                {{ p.zodiacSignYear }}

                            </td>
                        </tr>
                        <tr v-for="p in dataResultPredict" :key="p" style="white-space: nowrap;">
                            <td class="print-td space-x-1 text-center">
                                <span :class="{
                                    'text-color1': p.rootZodiacTime[0] === 'ភ្លើង+' || p.rootZodiacTime[0] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacTime[0] === 'ដី+' || p.rootZodiacTime[0] === 'ដី-',
                                    'text-color2': p.rootZodiacTime[0] === 'ទឹក+' || p.rootZodiacTime[0] === 'ទឹក-',
                                    'text-color4': p.rootZodiacTime[0] === 'មាស+' || p.rootZodiacTime[0] === 'មាស-',
                                    'text-color3': p.rootZodiacTime[0] === 'ឈើ+' || p.rootZodiacTime[0] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacTime[0])
                                }">
                                    {{ p.rootZodiacTime[0] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacTime[1] === 'ភ្លើង+' || p.rootZodiacTime[1] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacTime[1] === 'ដី+' || p.rootZodiacTime[1] === 'ដី-',
                                    'text-color2': p.rootZodiacTime[1] === 'ទឹក+' || p.rootZodiacTime[1] === 'ទឹក-',
                                    'text-color4': p.rootZodiacTime[1] === 'មាស+' || p.rootZodiacTime[1] === 'មាស-',
                                    'text-color3': p.rootZodiacTime[1] === 'ឈើ+' || p.rootZodiacTime[1] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacTime[1])
                                }">
                                    {{ p.rootZodiacTime[1] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacTime[2] === 'ភ្លើង+' || p.rootZodiacTime[2] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacTime[2] === 'ដី+' || p.rootZodiacTime[2] === 'ដី-',
                                    'text-color2': p.rootZodiacTime[2] === 'ទឹក+' || p.rootZodiacTime[2] === 'ទឹក-',
                                    'text-color4': p.rootZodiacTime[2] === 'មាស+' || p.rootZodiacTime[2] === 'មាស-',
                                    'text-color3': p.rootZodiacTime[2] === 'ឈើ+' || p.rootZodiacTime[2] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacTime[2])
                                }">
                                    {{ p.rootZodiacTime[2] }}
                                </span>


                            </td>
                            <td class="print-td space-x-1 text-center">
                                <span :class="{
                                    'text-color1': p.rootZodiacDay[0] === 'ភ្លើង+' || p.rootZodiacDay[0] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacDay[0] === 'ដី+' || p.rootZodiacDay[0] === 'ដី-',
                                    'text-color2': p.rootZodiacDay[0] === 'ទឹក+' || p.rootZodiacDay[0] === 'ទឹក-',
                                    'text-color4': p.rootZodiacDay[0] === 'មាស+' || p.rootZodiacDay[0] === 'មាស-',
                                    'text-color3': p.rootZodiacDay[0] === 'ឈើ+' || p.rootZodiacDay[0] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacDay[0])
                                }">
                                    {{ p.rootZodiacDay[0] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacDay[1] === 'ភ្លើង+' || p.rootZodiacDay[1] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacDay[1] === 'ដី+' || p.rootZodiacDay[1] === 'ដី-',
                                    'text-color2': p.rootZodiacDay[1] === 'ទឹក+' || p.rootZodiacDay[1] === 'ទឹក-',
                                    'text-color4': p.rootZodiacDay[1] === 'មាស+' || p.rootZodiacDay[1] === 'មាស-',
                                    'text-color3': p.rootZodiacDay[1] === 'ឈើ+' || p.rootZodiacDay[1] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacDay[1])
                                }">
                                    {{ p.rootZodiacDay[1] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacDay[2] === 'ភ្លើង+' || p.rootZodiacDay[2] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacDay[2] === 'ដី+' || p.rootZodiacDay[2] === 'ដី-',
                                    'text-color2': p.rootZodiacDay[2] === 'ទឹក+' || p.rootZodiacDay[2] === 'ទឹក-',
                                    'text-color4': p.rootZodiacDay[2] === 'មាស+' || p.rootZodiacDay[2] === 'មាស-',
                                    'text-color3': p.rootZodiacDay[2] === 'ឈើ+' || p.rootZodiacDay[2] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacDay[2])
                                }">
                                    {{ p.rootZodiacDay[2] }}
                                </span>



                            </td>
                            <td class="print-td space-x-1 text-center">
                                <span :class="{
                                    'text-color1': p.rootZodiacMonth[0] === 'ភ្លើង+' || p.rootZodiacMonth[0] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacMonth[0] === 'ដី+' || p.rootZodiacMonth[0] === 'ដី-',
                                    'text-color2': p.rootZodiacMonth[0] === 'ទឹក+' || p.rootZodiacMonth[0] === 'ទឹក-',
                                    'text-color4': p.rootZodiacMonth[0] === 'មាស+' || p.rootZodiacMonth[0] === 'មាស-',
                                    'text-color3': p.rootZodiacMonth[0] === 'ឈើ+' || p.rootZodiacMonth[0] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacMonth[0])
                                }">
                                    {{ p.rootZodiacMonth[0] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacMonth[1] === 'ភ្លើង+' || p.rootZodiacMonth[1] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacMonth[1] === 'ដី+' || p.rootZodiacMonth[1] === 'ដី-',
                                    'text-color2': p.rootZodiacMonth[1] === 'ទឹក+' || p.rootZodiacMonth[1] === 'ទឹក-',
                                    'text-color4': p.rootZodiacMonth[1] === 'មាស+' || p.rootZodiacMonth[1] === 'មាស-',
                                    'text-color3': p.rootZodiacMonth[1] === 'ឈើ+' || p.rootZodiacMonth[1] === 'ឈើ-',
                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacMonth[1])
                                }">
                                    {{ p.rootZodiacMonth[1] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacMonth[2] === 'ភ្លើង+' || p.rootZodiacMonth[2] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacMonth[2] === 'ដី+' || p.rootZodiacMonth[2] === 'ដី-',
                                    'text-color2': p.rootZodiacMonth[2] === 'ទឹក+' || p.rootZodiacMonth[2] === 'ទឹក-',
                                    'text-color4': p.rootZodiacMonth[2] === 'មាស+' || p.rootZodiacMonth[2] === 'មាស-',
                                    'text-color3': p.rootZodiacMonth[2] === 'ឈើ+' || p.rootZodiacMonth[2] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacMonth[2])
                                }">
                                    {{ p.rootZodiacMonth[2] }}
                                </span>


                            </td>
                            <td class="print-td space-x-1 text-center">
                                <span :class="{
                                    'text-color1': p.rootZodiacYear[0] === 'ភ្លើង+' || p.rootZodiacYear[0] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacYear[0] === 'ដី+' || p.rootZodiacYear[0] === 'ដី-',
                                    'text-color2': p.rootZodiacYear[0] === 'ទឹក+' || p.rootZodiacYear[0] === 'ទឹក-',
                                    'text-color4': p.rootZodiacYear[0] === 'មាស+' || p.rootZodiacYear[0] === 'មាស-',
                                    'text-color3': p.rootZodiacYear[0] === 'ឈើ+' || p.rootZodiacYear[0] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacYear[0])
                                }">
                                    {{ p.rootZodiacYear[0] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacYear[1] === 'ភ្លើង+' || p.rootZodiacYear[1] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacYear[1] === 'ដី+' || p.rootZodiacYear[1] === 'ដី-',
                                    'text-color2': p.rootZodiacYear[1] === 'ទឹក+' || p.rootZodiacYear[1] === 'ទឹក-',
                                    'text-color4': p.rootZodiacYear[1] === 'មាស+' || p.rootZodiacYear[1] === 'មាស-',
                                    'text-color3': p.rootZodiacYear[1] === 'ឈើ+' || p.rootZodiacYear[1] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacYear[1])
                                }">
                                    {{ p.rootZodiacYear[1] }}
                                </span>
                                <span :class="{
                                    'text-color1': p.rootZodiacYear[2] === 'ភ្លើង+' || p.rootZodiacYear[2] === 'ភ្លើង-',
                                    'text-color5': p.rootZodiacYear[2] === 'ដី+' || p.rootZodiacYear[2] === 'ដី-',
                                    'text-color2': p.rootZodiacYear[2] === 'ទឹក+' || p.rootZodiacYear[2] === 'ទឹក-',
                                    'text-color4': p.rootZodiacYear[2] === 'មាស+' || p.rootZodiacYear[2] === 'មាស-',
                                    'text-color3': p.rootZodiacYear[2] === 'ឈើ+' || p.rootZodiacYear[2] === 'ឈើ-',

                                    '': !['ភ្លើង+', 'ដី+', 'ទឹក+', 'មាស+', 'ឈើ+', 'ភ្លើង-', 'ដី-', 'ទឹក-', 'មាស-', 'ឈើ-'].includes(p.rootZodiacYear[2])
                                }">
                                    {{ p.rootZodiacYear[2] }}
                                </span>


                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div class="relative  mt-2 p-2">
                <table class="w-full border-collapse border border-black max-w-full">
                    <thead>
                        <tr class="border border-black whitespace-nowrap ">
                            <th class="w-[70px] print-td text-center">ស្នេហ៍</th>
                            <th class="w-[70px] print-td text-center">ជំនួយ</th>
                            <th class="w-[70px] print-td text-center">ប្រាជ្ញា</th>
                            <th class="w-[70px] print-td text-center">ធ្វើដំណើរ</th>
                            <th class="w-[70px] print-td text-center">ឯការ</th>
                            <th class="w-[70px] print-td text-center">រទេះមាស</th>
                            <th class="w-[70px] print-td text-center">អាងទឹក</th>
                            <th class="w-[70px] print-td text-center">ឃុងបួង</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="s in dataResultStarPredict" :key="s" class="border border-black whitespace-nowrap">
                            <td class="w-[70px] print-td text-center" :class="{
                                'text-color2.1': s.zodiacNameLV === 'ជូត',
                                'text-color5': s.zodiacNameLV === 'ឆ្លូវ',
                                'text-color3': s.zodiacNameLV === 'ខាល',
                                'text-color3.1': s.zodiacNameLV === 'ថោះ',
                                'text-color5.1': s.zodiacNameLV === 'រោង',
                                'text-color1': s.zodiacNameLV === 'ម្សាញ់',
                                'text-color1.1': s.zodiacNameLV === 'មមី',
                                'text-color5.2': s.zodiacNameLV === 'មមែ',
                                'text-color4': s.zodiacNameLV === 'វក',
                                'text-color4.1': s.zodiacNameLV === 'រកា',
                                'text-color5.3': s.zodiacNameLV === 'ច',
                                'text-color2': s.zodiacNameLV === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameIT)
                            }">{{ s.zodiacNameLV }}</td>
                            <td class="w-[70px] print-td space-x-1 text-center">
                                <span :class="{
                                    'text-color2.1': s.zodiacNameNB[0] === 'ជូត',
                                    'text-color5': s.zodiacNameNB[0] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameNB[0] === 'ខាល',
                                    'text-color3.1': s.zodiacNameNB[0] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameNB[0] === 'រោង',
                                    'text-color1': s.zodiacNameNB[0] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameNB[0] === 'មមី',
                                    'text-color5.2': s.zodiacNameNB[0] === 'មមែ',
                                    'text-color4': s.zodiacNameNB[0] === 'វក',
                                    'text-color4.1': s.zodiacNameNB[0] === 'រកា',
                                    'text-color5.3': s.zodiacNameNB[0] === 'ច',
                                    'text-color2': s.zodiacNameNB[0] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameNB[0])
                                }">
                                    {{ s.zodiacNameNB[0] }}

                                </span>

                                <span :class="{
                                    'text-color2.1': s.zodiacNameNB[1] === 'ជូត',
                                    'text-color5': s.zodiacNameNB[1] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameNB[1] === 'ខាល',
                                    'text-color3.1': s.zodiacNameNB[1] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameNB[1] === 'រោង',
                                    'text-color1': s.zodiacNameNB[1] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameNB[1] === 'មមី',
                                    'text-color5.2': s.zodiacNameNB[1] === 'មមែ',
                                    'text-color4': s.zodiacNameNB[1] === 'វក',
                                    'text-color4.1': s.zodiacNameNB[1] === 'រកា',
                                    'text-color5.3': s.zodiacNameNB[1] === 'ច',
                                    'text-color2': s.zodiacNameNB[1] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameNB[1])
                                }">
                                    {{ s.zodiacNameNB[1] }}
                                </span>


                            </td>
                            <td class="w-[70px] print-td text-center" :class="{
                                'text-color2.1': s.zodiacNameIT === 'ជូត',
                                'text-color5': s.zodiacNameIT === 'ឆ្លូវ',
                                'text-color3': s.zodiacNameIT === 'ខាល',
                                'text-color3.1': s.zodiacNameIT === 'ថោះ',
                                'text-color5.1': s.zodiacNameIT === 'រោង',
                                'text-color1': s.zodiacNameIT === 'ម្សាញ់',
                                'text-color1.1': s.zodiacNameIT === 'មមី',
                                'text-color5.2': s.zodiacNameIT === 'មមែ',
                                'text-color4': s.zodiacNameIT === 'វក',
                                'text-color4.1': s.zodiacNameIT === 'រកា',
                                'text-color5.3': s.zodiacNameIT === 'ច',
                                'text-color2': s.zodiacNameIT === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameIT)
                            }">{{ s.zodiacNameIT }}</td>
                            <td class="w-[70px] print-td text-center" :class="{
                                'text-color2.1': s.zodiacNameSH === 'ជូត',
                                'text-color5': s.zodiacNameSH === 'ឆ្លូវ',
                                'text-color3': s.zodiacNameSH === 'ខាល',
                                'text-color3.1': s.zodiacNameSH === 'ថោះ',
                                'text-color5.1': s.zodiacNameSH === 'រោង',
                                'text-color1': s.zodiacNameSH === 'ម្សាញ់',
                                'text-color1.1': s.zodiacNameSH === 'មមី',
                                'text-color5.2': s.zodiacNameSH === 'មមែ',
                                'text-color4': s.zodiacNameSH === 'វក',
                                'text-color4.1': s.zodiacNameSH === 'រកា',
                                'text-color5.3': s.zodiacNameSH === 'ច',
                                'text-color2': s.zodiacNameSH === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameSH)
                            }">{{ s.zodiacNameSH }}</td>
                            <td class="w-[70px] print-td space-x-1 text-center ">

                                <span :class="{
                                    'text-color2.1': s.zodiacNameSL[0] === 'ជូត',
                                    'text-color5': s.zodiacNameSL[0] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameSL[0] === 'ខាល',
                                    'text-color3.1': s.zodiacNameSL[0] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameSL[0] === 'រោង',
                                    'text-color1': s.zodiacNameSL[0] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameSL[0] === 'មមី',
                                    'text-color5.2': s.zodiacNameSL[0] === 'មមែ',
                                    'text-color4': s.zodiacNameSL[0] === 'វក',
                                    'text-color4.1': s.zodiacNameSL[0] === 'រកា',
                                    'text-color5.3': s.zodiacNameSL[0] === 'ច',
                                    'text-color2': s.zodiacNameSL[0] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameSL[0])
                                }">
                                    {{ s.zodiacNameSL[0] }}
                                </span>
                                <span :class="{
                                    'text-color2.1': s.zodiacNameSL[1] === 'ជូត',
                                    'text-color5': s.zodiacNameSL[1] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameSL[1] === 'ខាល',
                                    'text-color3.1': s.zodiacNameSL[1] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameSL[1] === 'រោង',
                                    'text-color1': s.zodiacNameSL[1] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameSL[1] === 'មមី',
                                    'text-color5.2': s.zodiacNameSL[1] === 'មមែ',
                                    'text-color4': s.zodiacNameSL[1] === 'វក',
                                    'text-color4.1': s.zodiacNameSL[1] === 'រកា',
                                    'text-color5.3': s.zodiacNameSL[1] === 'ច',
                                    'text-color2': s.zodiacNameSL[1] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameSL[1])
                                }">
                                    {{ s.zodiacNameSL[1] }}
                                </span>
                            </td>
                            <td class="w-[70px] print-td text-center" :class="{
                                'text-color2.1': s.zodiacNameGD === 'ជូត',
                                'text-color5': s.zodiacNameGD === 'ឆ្លូវ',
                                'text-color3': s.zodiacNameGD === 'ខាល',
                                'text-color3.1': s.zodiacNameGD === 'ថោះ',
                                'text-color5.1': s.zodiacNameGD === 'រោង',
                                'text-color1': s.zodiacNameGD === 'ម្សាញ់',
                                'text-color1.1': s.zodiacNameGD === 'មមី',
                                'text-color5.2': s.zodiacNameGD === 'មមែ',
                                'text-color4': s.zodiacNameGD === 'វក',
                                'text-color4.1': s.zodiacNameGD === 'រកា',
                                'text-color5.3': s.zodiacNameGD === 'ច',
                                'text-color2': s.zodiacNameGD === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameGD)
                            }">{{ s.zodiacNameGD }}</td>
                            <td class="w-[70px] print-td space-x-1 text-center">

                                <span :class="{
                                    'text-color2.1': s.zodiacNameP[0] === 'ជូត',
                                    'text-color5': s.zodiacNameP[0] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameP[0] === 'ខាល',
                                    'text-color3.1': s.zodiacNameP[0] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameP[0] === 'រោង',
                                    'text-color1': s.zodiacNameP[0] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameP[0] === 'មមី',
                                    'text-color5.2': s.zodiacNameP[0] === 'មមែ',
                                    'text-color4': s.zodiacNameP[0] === 'វក',
                                    'text-color4.1': s.zodiacNameP[0] === 'រកា',
                                    'text-color5.3': s.zodiacNameP[0] === 'ច',
                                    'text-color2': s.zodiacNameP[0] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameP[0])
                                }">
                                    {{ s.zodiacNameP[0] }}
                                </span>
                                <span :class="{
                                    'text-color2.1': s.zodiacNameP[1] === 'ជូត',
                                    'text-color5': s.zodiacNameP[1] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameP[1] === 'ខាល',
                                    'text-color3.1': s.zodiacNameP[1] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameP[1] === 'រោង',
                                    'text-color1': s.zodiacNameP[1] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameP[1] === 'មមី',
                                    'text-color5.2': s.zodiacNameP[1] === 'មមែ',
                                    'text-color4': s.zodiacNameP[1] === 'វក',
                                    'text-color4.1': s.zodiacNameP[1] === 'រកា',
                                    'text-color5.3': s.zodiacNameP[1] === 'ច',
                                    'text-color2': s.zodiacNameP[1] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameP[1])
                                }">
                                    {{ s.zodiacNameP[1] }}
                                </span>


                            </td>
                            <td class="w-[70px] print-td space-x-1 text-center">


                                <span :class="{
                                    'text-color2.1': s.zodiacNameDE[0] === 'ជូត',
                                    'text-color5': s.zodiacNameDE[0] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameDE[0] === 'ខាល',
                                    'text-color3.1': s.zodiacNameDE[0] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameDE[0] === 'រោង',
                                    'text-color1': s.zodiacNameDE[0] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameDE[0] === 'មមី',
                                    'text-color5.2': s.zodiacNameDE[0] === 'មមែ',
                                    'text-color4': s.zodiacNameDE[0] === 'វក',
                                    'text-color4.1': s.zodiacNameDE[0] === 'រកា',
                                    'text-color5.3': s.zodiacNameDE[0] === 'ច',
                                    'text-color2': s.zodiacNameDE[0] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameDE[0])
                                }">
                                    {{ s.zodiacNameDE[0] }}
                                </span>
                                <span :class="{
                                    'text-color2.1': s.zodiacNameDE[1] === 'ជូត',
                                    'text-color5': s.zodiacNameDE[1] === 'ឆ្លូវ',
                                    'text-color3': s.zodiacNameDE[1] === 'ខាល',
                                    'text-color3.1': s.zodiacNameDE[1] === 'ថោះ',
                                    'text-color5.1': s.zodiacNameDE[1] === 'រោង',
                                    'text-color1': s.zodiacNameDE[1] === 'ម្សាញ់',
                                    'text-color1.1': s.zodiacNameDE[1] === 'មមី',
                                    'text-color5.2': s.zodiacNameDE[1] === 'មមែ',
                                    'text-color4': s.zodiacNameDE[1] === 'វក',
                                    'text-color4.1': s.zodiacNameDE[1] === 'រកា',
                                    'text-color5.3': s.zodiacNameDE[1] === 'ច',
                                    'text-color2': s.zodiacNameDE[1] === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(s.zodiacNameDE[1])
                                }">
                                    {{ s.zodiacNameDE[1] }}
                                </span>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>

            <div class="px-2 space-y-2 mt-2 mb-2">
                <p>រ័យចរ</p>
            </div>
            <div class="relative  mt-2 p-2">
                <table class="w-full border-collapse border border-black max-w-full ">

                    <tr v-for="(row, rowIndex) in dataRangeAgeWalk" :key="rowIndex" class="border border-black ">
                        <th v-for="(range, colIndex) in row" :key="colIndex"
                            class="w-[70px] print-td whitespace-nowrap text-center">{{ range }}
                        </th>
                    </tr>

                    <tr v-for="(row, rowIndex) in dataAgeW" :key="rowIndex" class="border border-black">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="w-[70px] print-td text-center"
                            :class="{
                                'text-color1': item.elementName === 'ភ្លើង',
                                'text-color5': item.elementName === 'ដី',
                                'text-color2': item.elementName === 'ទឹក',
                                'text-color4': item.elementName === 'មាស',
                                'text-color3': item.elementName === 'ឈើ',

                                '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(item.elementName)
                            }"> {{
                                item.elementName }}{{ item.elementSign }}</td>

                    </tr>
                    <tr v-for="(row, rowIndex) in dataAgeW" :key="rowIndex" class="border border-black">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="w-[70px] print-td text-center"
                            :class="{
                                'text-color2.1': item.zodiacName === 'ជូត',
                                'text-color5': item.zodiacName === 'ឆ្លូវ',
                                'text-color3': item.zodiacName === 'ខាល',
                                'text-color3.1': item.zodiacName === 'ថោះ',
                                'text-color5.1': item.zodiacName === 'រោង',
                                'text-color1': item.zodiacName === 'ម្សាញ់',
                                'text-color1.1': item.zodiacName === 'មមី',
                                'text-color5.2': item.zodiacName === 'មមែ',
                                'text-color4': item.zodiacName === 'វក',
                                'text-color4.1': item.zodiacName === 'រកា',
                                'text-color5.3': item.zodiacName === 'ច',
                                'text-color2': item.zodiacName === 'កុរ',

                                '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(item.zodiacName)
                            }">

                            {{ item.zodiacName }}
                        </td>
                    </tr>


                </table>
            </div>

            <div class="px-2 space-y-2 mt-4 mb-2">
                <p>ឆ្នាំ</p>
            </div>
            <div class="relative  mt-2 p-2">
                <table class="w-full border-collapse border border-black max-w-full">
                    <thead>
                        <tr v-for="(row, rowIndex) in datarangeYearFuture" :key="rowIndex" class="border border-black ">
                            <th v-for="(range, colIndex) in row" :key="colIndex" class="w-[70px] print-td text-center">
                                {{ range }}
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, rowIndex) in dataYearFuture" :key="rowIndex" class="border border-black">
                            <td v-for="(item, colIndex) in row" :key="colIndex" class="w-[70px] print-td text-center"
                                :class="{
                                    'text-color1': item.elementName === 'ភ្លើង',
                                    'text-color5': item.elementName === 'ដី',
                                    'text-color2': item.elementName === 'ទឹក',
                                    'text-color4': item.elementName === 'មាស',
                                    'text-color3': item.elementName === 'ឈើ',

                                    '': !['ភ្លើង', 'ដី', 'ទឹក', 'មាស', 'ឈើ'].includes(item.elementName)
                                }"> {{
                                    item.elementName }}{{ item.elementSign }}</td>

                        </tr>
                        <tr v-for="(row, rowIndex) in dataYearFuture" :key="rowIndex" class="border border-black">
                            <td v-for="(item, colIndex) in row" :key="colIndex" class="w-[70px] print-td text-center"
                                :class="{
                                    'text-color2.1': item.zodiacName === 'ជូត',
                                    'text-color5': item.zodiacName === 'ឆ្លូវ',
                                    'text-color3': item.zodiacName === 'ខាល',
                                    'text-color3.1': item.zodiacName === 'ថោះ',
                                    'text-color5.1': item.zodiacName === 'រោង',
                                    'text-color1': item.zodiacName === 'ម្សាញ់',
                                    'text-color1.1': item.zodiacName === 'មមី',
                                    'text-color5.2': item.zodiacName === 'មមែ',
                                    'text-color4': item.zodiacName === 'វក',
                                    'text-color4.1': item.zodiacName === 'រកា',
                                    'text-color5.3': item.zodiacName === 'ច',
                                    'text-color2': item.zodiacName === 'កុរ',

                                    '': !['ជូត', 'ឆ្លូវ', 'ខាល', 'ថោះ', 'រោង', 'ម្សាញ់', 'មមី', 'មមែ', 'វក', 'រកា', 'ច', 'កុរ'].includes(item.zodiacName)
                                }"> {{
                                    item.zodiacName }}</td>

                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import { getCollectionQuery } from '@/composables/getCollection';
import { onMounted, ref, computed } from 'vue';

import { orderBy, serverTimestamp } from 'firebase/firestore';
import { useRoute } from 'vue-router';
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/config";




export default {
    setup() {

        const route = useRoute();

        const dataResultPredict = ref([]);
        const userAge = ref(null);
        const gender = ref(null);
        const year = ref(null);
        const month = ref(null);
        const day = ref(null);
        const hour = ref(null);
        const minute = ref(null);
        const unknowTimeOfBirth = ref(null);
        const name = ref('');
        const twin = ref("");
        // const rootZodiac = ref([]);
        const dataZodiac = ref([]);
        const datasElements = ref([]);
        const dataAgeWalk = ref([]);
        const rangeAgeWalk = ref([]);
        const dataYear = ref([]);
        const rangeYearFuture = ref([]);
        const dataResultStarPredict = ref([]);
        const kouyNumber = ref(null);
        const monthoptions = ref([]);
        const timestamp = ref(null);
        timestamp.value = serverTimestamp();


        day.value = route.params.day;
        hour.value = route.params.hour;
        minute.value = route.params.minute;
        year.value = route.params.year;
        month.value = route.params.month;
        name.value = route.params.name;
        gender.value = route.params.gender;
        unknowTimeOfBirth.value = route.params.unknowT;
        twin.value = route.params.twin;





        monthoptions.value = [
            { month_m3: 'មករា', month_m4: 'January', month_id: 0 },
            { month_m3: 'កុម្ភៈ', month_m4: 'February', month_id: 1 },
            { month_m3: 'មិនា', month_m4: 'March', month_id: 2 },
            { month_m3: 'មេសា', month_m4: 'April', month_id: 3 },
            { month_m3: 'ឧសភា', month_m4: 'May', month_id: 4 },
            { month_m3: 'មិថុនា', month_m4: 'June', month_id: 5 },
            { month_m3: 'កក្ដដា', month_m4: 'July', month_id: 6 },
            { month_m3: 'សីហា', month_m4: 'August', month_id: 7 },
            { month_m3: 'កញ្ញា', month_m4: 'September', month_id: 8 },
            { month_m3: 'តុលា', month_m4: 'October', month_id: 9 },
            { month_m3: 'វិច្ឆិកា', month_m4: 'November', month_id: 10 },
            { month_m3: 'ធ្នូ', month_m4: 'December', month_id: 11 }
        ];

        const formatMonth = (ID) => {
            const m = monthoptions.value.find((m) => m.month_id === ID);
            return m ? m.month_m3 : "";
        }






        const userDOB = ref(null);
        const january1900 = ref(null);
        const days = ref(null);
        const handleCalcualteBazi = async () => {
            await getCollectionQuery("elements", [orderBy("elementCode")], (data) => {
                datasElements.value = data;
            }, null, false);

            await getCollectionQuery("zodiacSigns", [orderBy("zodiacCode")], (data) => {
                dataZodiac.value = data;

            }, null, false);
            userDOB.value = new Date(Number(year.value), Number((month.value)), Number(day.value), 0, 0); // Set time to 12:00 AM midnight


            january1900.value = new Date(1900, 0, 1); //january 1st 1900 at 12:00 AM midnight




            const differenceMilliseconds = userDOB.value.getTime() - january1900.value.getTime();


            // Convert milliseconds to days
            const total = Math.round(differenceMilliseconds / (1000 * 60 * 60 * 24))
            days.value = total + 1;//បូកថែម1ថ្ងៃបង្គ្រប់






            const indexSignbyTime = ref(null);
            const indexElementbyTime = ref(null);
            const indexElementByMonth = ref(null);
            const indexSignByMonth = ref(null);
            const findSignbyTime = ref({});
            const findSignByYear = ref({});
            const findElementByyear = ref({});
            const elementIndexByYear = ref(null);
            const signIndexByYear = ref(null);



            const resultElementbyDay = ((days.value - 1) % 10) + 1;

            const resultZodiacSignbyDay = ((days.value + 10 - 1) % 12) + 1;








            const findtimeSign = dataZodiac.value.find((z) => z.startTime === Number(hour.value) || z.endTime === Number(hour.value));
            if (findtimeSign) {
                const zodiacCode = findtimeSign.zodiacCode;

                const sliceLength = zodiacCode.length >= 2 ? 2 : 1;

                indexSignbyTime.value = zodiacCode.slice(-sliceLength);


                findSignbyTime.value = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLength) === indexSignbyTime.value);

            }

            indexElementbyTime.value = ((days.value - 1) * 12 + (Number(indexSignbyTime.value) - 1)) % 10 + 1;
            const sliceLengthTime = String(indexElementbyTime.value).length >= 2 ? 2 : 1
            const findElementByTime = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthTime) === String(indexElementbyTime.value));




            const sliceLengthDay = String(resultZodiacSignbyDay).length >= 2 ? 2 : 1;
            const findSignByDay = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLengthDay) === String(resultZodiacSignbyDay));
            const sliceLengthEDay = String(resultElementbyDay).length >= 2 ? 2 : 1;
            const findElementByDay = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthEDay) === String(resultElementbyDay));






            //find month

            const findselectMonth = monthoptions.value.find((m) => m.month_id === Number(month.value));
            // console.log("find select Month",findselectMonth);
            const findMonthSign = ref({});

            if (findselectMonth) {
                findMonthSign.value = dataZodiac.value.find((z) => z.startMonth === findselectMonth.month_m4 && z.startDay.includes(Number(day.value)) || (z.endMonth === findselectMonth.month_m4 && z.endDay.includes(Number(day.value))));

                //console.log("find Month Sign",findMonthSign)
                if (findMonthSign.value) {
                    const zodiacCode = findMonthSign.value.zodiacCode;
                    const sliceLength = zodiacCode.toString().length >= 2 ? 2 : 1;
                    indexSignByMonth.value = zodiacCode.slice(-sliceLength);

                }


            }


            //indexElementByMonth.value = ((Number(year.value) - 1) * 12 + (findselectMonth.month_id + 1) + 5 - 1) % 10 + 1;
            indexElementByMonth.value = (Number(year.value) * 12 + (findselectMonth.month_id + 1) + 3 - 1) % 10 + 1;
            console.log("index", indexElementByMonth)



            const findStartMonth = dataZodiac.value.find((z) => z.startMonth === findselectMonth.month_m4 && z.startDay[0]);
            console.log("find Start Month", findStartMonth);



            console.log("find start Month", findStartMonth.startDay[0])

            if (day.value < findStartMonth.startDay[0]) {
                indexElementByMonth.value -= 1;


                indexElementByMonth.value = indexElementByMonth.value === 0 ? 10 : indexElementByMonth.value
                console.log("index element after minus 1", indexElementByMonth);
            }




            // console.log("month sign startDay",monthSignStartDay)



            const index = String(indexElementByMonth.value);
            const sliceLength = index.length >= 2 ? 2 : 1;
            const findElementByMonth = datasElements.value.find((e) => e.elementCode.slice(-sliceLength) === index);



            // find kouy number

            if (gender.value === 'Male') {
                const digitalRoot = 1 + ((Number(year.value) - 1) % 9)
                const result = 11 - digitalRoot;
                kouyNumber.value = null;// Subtract the digital root from 11
                kouyNumber.value = 1 + ((result - 1) % 9);

                if (kouyNumber.value === 5) {

                    kouyNumber.value = 2;
                }





            } else if (gender.value === 'Female') {
                const digitalRoot = 1 + ((Number(year.value) - 1) % 9)
                const result = 4 + digitalRoot;
                kouyNumber.value = null;// Subtract the digital root from 11
                kouyNumber.value = 1 + ((result - 1) % 9);

                if (kouyNumber.value === 5) {

                    kouyNumber.value = 8;
                }




            }




            //find year element and sign 
            const inputDate = new Date(year.value, Number(month.value), Number(day.value)); // month - 1 because months are zero-based in JavaScript
            const feb4 = new Date(year.value, 1, 4);
            if (inputDate >= feb4) {
                // Calculate the ID of the element using the provided formula
                elementIndexByYear.value = (year.value - 3 - 1) % 10 + 1;
                signIndexByYear.value = (year.value - 3 - 1) % 12 + 1;





            } else if (inputDate < feb4) {
                elementIndexByYear.value = (year.value - 4 - 1) % 10 + 1;
                signIndexByYear.value = (year.value - 4 - 1) % 12 + 1;

            }



            const sliceLengthSignYear = String(signIndexByYear.value).length >= 2 ? 2 : 1;
            const sliceLengthEYear = String(elementIndexByYear.value).length >= 2 ? 2 : 1;
            findSignByYear.value = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLengthSignYear) === String(signIndexByYear.value));
            findElementByyear.value = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthEYear) === String(elementIndexByYear.value));





            const elementSignBymonth = ref("");
            const elementSignByYear = ref("");

            if (findElementByTime || findSignbyTime.value || findElementByDay || findSignByDay || findElementByMonth || findMonthSign.value || findSignByYear.value || findElementByyear.value) {

                elementSignBymonth.value = findElementByMonth.elementSign;
                elementSignByYear.value = findElementByyear.value.elementSign;


                const resutlPredictObject = {
                    elementNameTime: unknowTimeOfBirth.value === "true" ? "N/A" : findElementByTime.elementName,
                    elementSignTime: unknowTimeOfBirth.value === "true" ? "N/A" : findElementByTime.elementSign,
                    zodiacSignTime: unknowTimeOfBirth.value === "true" ? "N/A" : findSignbyTime.value.zodiacName,
                    rootZodiacTime: unknowTimeOfBirth.value === "true" ? "N/A" : findSignbyTime.value.rootZodiac,
                    elementNameDay: findElementByDay.elementName,
                    elementSignDay: findElementByDay.elementSign,
                    zodiacSignDay: findSignByDay.zodiacName,
                    rootZodiacDay: findSignByDay.rootZodiac,
                    elementNameMonth: findElementByMonth.elementName,
                    elementSignMonth: findElementByMonth.elementSign,
                    zodiacSignMonth: findMonthSign.value.zodiacName,
                    rootZodiacMonth: findMonthSign.value.rootZodiac,
                    elementNameYear: findElementByyear.value.elementName,
                    elementSignYear: findElementByyear.value.elementSign,
                    zodiacSignYear: findSignByYear.value.zodiacName,
                    rootZodiacYear: findSignByYear.value.rootZodiac,
                };



                dataResultPredict.value = [];
                // Pushing the new objects into dataResultPredictByTime
                dataResultPredict.value.push(resutlPredictObject);




                // //find Star death&Emptiness
                const findDEStar = dataDE.value.find((k) => k.elementName === findElementByDay.elementName && k.elementSign === findElementByDay.elementSign && k.zodiacName === findSignByDay.zodiacName);

                //find star nobleMan
                const findNBStar = dataNB.value.find((n) => n.elementName === findElementByDay.elementName && n.elementSign === findElementByDay.elementSign);

                //find peachBlossom as love star
                const findLoveStar = dataLoveStar.value.find((l) => l.zodiacName === findSignByDay.zodiacName);

                //find poolStar
                const findpoolStar = dataPoolStar.value.find((p) => p.elementName === findElementByDay.elementName && p.elementSign === findElementByDay.elementSign);

                //find ITStar
                const findITStar = dataITStar.value.find((i) => i.elementName === findElementByDay.elementName && i.elementSign === findElementByDay.elementSign);


                //find GDStar
                const findGDStar = dataGD.value.find((i) => i.elementName === findElementByDay.elementName && i.elementSign === findElementByDay.elementSign);

                //find solitaryStar
                const findSLStar = dataSLStar.value.find((l) => l.zodiacName === findSignByDay.zodiacName);

                //find SHStar
                const findSHStar = dataSHStar.value.find((s) => s.zodiacName === findSignByDay.zodiacName);


                if (findDEStar || findNBStar || findLoveStar || findpoolStar || findITStar || findGDStar || findSLStar || findSHStar) {
                    const resultPredicStarObj = {
                        zodiacNameDE: findDEStar.deStar,
                        zodiacNameNB: findNBStar.star,
                        zodiacNameLV: findLoveStar.star,
                        zodiacNameP: findpoolStar.star,
                        zodiacNameIT: findITStar.star,
                        zodiacNameGD: findGDStar.star,
                        zodiacNameSL: findSLStar.star,
                        zodiacNameSH: findSHStar.star

                    }

                    dataResultStarPredict.value = [];

                    dataResultStarPredict.value.push(resultPredicStarObj);
                }





                //find វ័យចរ
                const findAgeWalk = dataBazi.value.find((b) => b.elementName === findElementByMonth.elementName && b.elementSign === findElementByMonth.elementSign && b.zodiacName === findMonthSign.value.zodiacName);
                console.log("find Age Walk", findAgeWalk)
                if (findAgeWalk) {
                    if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "false") {

                        // For forward
                        const endIndexF = dataBazi.value.indexOf(findAgeWalk);


                        dataAgeWalk.value = [];

                        for (let i = 1; dataAgeWalk.value.length < 10; i++) {
                            // Ensure cycling forward through dataBazi
                            let index = (endIndexF + i) % dataBazi.value.length;

                            dataAgeWalk.value.push(dataBazi.value[index]);

                            // Break the loop if we have added 10 elements
                            if (dataAgeWalk.value.length >= 10) {
                                break;
                            }
                        }

                        console.log("data Age Walk male forward", dataAgeWalk.value);


                    } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "false") {

                        //for backward
                        const startIndexB = dataBazi.value.indexOf(findAgeWalk);


                        const backwardLength = 10;

                        // Calculate the start index for backward traversal
                        const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                        dataAgeWalk.value = [];


                        for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                            // Ensure cycling backward through dataBazi
                            let index = (i + dataBazi.value.length) % dataBazi.value.length;

                            if (index < 0) {
                                index += dataBazi.value.length; // Ensure index is non-negative
                            }

                            dataAgeWalk.value.push(dataBazi.value[index]);

                        }



                    } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "true") {
                        //for backward

                        const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                        console.log("start IndexB", startIndexB);

                        const backwardLength = 10;

                        // Calculate the start index for backward traversal
                        const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                        dataAgeWalk.value = [];


                        for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                            // Ensure cycling backward through dataBazi
                            let index = (i + dataBazi.value.length) % dataBazi.value.length;

                            if (index < 0) {
                                index += dataBazi.value.length; // Ensure index is non-negative
                            }

                            dataAgeWalk.value.push(dataBazi.value[index]);

                        }
                        console.log("age walk backward male", dataAgeWalk);
                    } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "true") {


                        // For forward
                        const endIndexF = dataBazi.value.indexOf(findAgeWalk);


                        dataAgeWalk.value = [];

                        for (let i = 1; dataAgeWalk.value.length < 10; i++) {
                            // Ensure cycling forward through dataBazi
                            let index = (endIndexF + i) % dataBazi.value.length;

                            dataAgeWalk.value.push(dataBazi.value[index]);

                            // Break the loop if we have added 10 elements
                            if (dataAgeWalk.value.length >= 10) {
                                break;
                            }
                        }

                        console.log("age walk forward male", dataAgeWalk);
                    } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === "false") {
                        //for backward

                        const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                        const backwardLength = 10;

                        dataAgeWalk.value = [];

                        // Loop to cycle backward and collect exactly 10 elements
                        for (let i = 1; dataAgeWalk.value.length < backwardLength; i++) {
                            // Calculate the index in a backward manner
                            let index = (startIndexB - i + dataBazi.value.length) % dataBazi.value.length;
                            dataAgeWalk.value.push(dataBazi.value[index]);
                        }

                        console.log("age walk backward female", dataAgeWalk);
                    } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "false") {


                        // For forward
                        const endIndexF = dataBazi.value.indexOf(findAgeWalk);


                        dataAgeWalk.value = [];

                        for (let i = 1; dataAgeWalk.value.length < 10; i++) {
                            // Ensure cycling forward through dataBazi
                            let index = (endIndexF + i) % dataBazi.value.length;

                            dataAgeWalk.value.push(dataBazi.value[index]);

                            // Break the loop if we have added 10 elements
                            if (dataAgeWalk.value.length >= 10) {
                                break;
                            }
                        }

                        console.log("age walk forward female", dataAgeWalk);
                    } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === "true") {


                        // For forward
                        const endIndexF = dataBazi.value.indexOf(findAgeWalk);


                        dataAgeWalk.value = [];

                        for (let i = 1; dataAgeWalk.value.length < 10; i++) {
                            // Ensure cycling forward through dataBazi
                            let index = (endIndexF + i) % dataBazi.value.length;

                            dataAgeWalk.value.push(dataBazi.value[index]);

                            // Break the loop if we have added 10 elements
                            if (dataAgeWalk.value.length >= 10) {
                                break;
                            }
                        }

                        console.log("age walk forward female", dataAgeWalk);
                    } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "true") {
                        //for backward

                        const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                        console.log("start IndexB", startIndexB);

                        const backwardLength = 10;

                        // Calculate the start index for backward traversal
                        const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                        dataAgeWalk.value = [];


                        for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                            // Ensure cycling backward through dataBazi
                            let index = (i + dataBazi.value.length) % dataBazi.value.length;

                            if (index < 0) {
                                index += dataBazi.value.length; // Ensure index is non-negative
                            }

                            dataAgeWalk.value.push(dataBazi.value[index]);
                            console.log("age walk backward female", dataAgeWalk);
                        }

                    }


                    const zodiacCodeByMonth = ref(null);
                    const remaininDaysOld = ref(null);
                    const remainingEndDay = ref(null);
                    const numberAgeWalk = ref(null);





                    const findMonthSignRangeSM = dataZodiac.value.find((z) => z.startMonth === findselectMonth.month_m4 && z.startDay.includes(Number(day.value)));

                    const findMontSignRangeEM = dataZodiac.value.find((z) => z.endMonth === findselectMonth.month_m4 && z.endDay.includes(Number(day.value)));


                    const rangeZodiacName = ref(null);
                    if (findMonthSignRangeSM != undefined && findMontSignRangeEM === undefined) {

                        rangeZodiacName.value = findMonthSignRangeSM.zodiacName;
                        zodiacCodeByMonth.value = findMonthSignRangeSM.zodiacCode;

                        const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));
                        const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                        if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "false") {


                            //forward
                            remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;
                            remainingEndDay.value = (findMonthSignRangeSM.endDay.length);






                            numberAgeWalk.value = Math.round((remaininDaysOld.value + remainingEndDay.value) / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;


                            }
                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "false") {

                            //backward
                            remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;

                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;

                            }
                        } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "true") {
                            //backward
                            remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;

                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }

                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "true") {

                            //forward


                            remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                            remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                            console.log("remaining EndDay", remainingEndDay)


                            console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                            numberAgeWalk.value = Math.round((remaininDaysOld.value + remainingEndDay.value) / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === "false") {

                            //backward
                            remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                            console.log(" remaining old days backward ", remaininDaysOld);
                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;

                                console.log('rangeAgeWalk', rangeAgeWalk)
                            }
                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "false") {
                            //forward
                            remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                            remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                            console.log("remaining EndDay", remainingEndDay)


                            console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                            numberAgeWalk.value = Math.round((remaininDaysOld.value + remainingEndDay.value) / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === "true") {
                            //forward
                            remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                            remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                            console.log("remaining EndDay", remainingEndDay)


                            console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                            numberAgeWalk.value = Math.round((remaininDaysOld.value + remainingEndDay.value) / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "true") {
                            //backward
                            remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                            console.log(" remaining old days backward ", remaininDaysOld);
                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        }



                    } else if (findMontSignRangeEM != undefined && findMonthSignRangeSM === undefined) {
                        rangeZodiacName.value = findMontSignRangeEM.zodiacName;
                        zodiacCodeByMonth.value = findMontSignRangeEM.zodiacCode;
                        if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "false") {
                            //forward

                            console.log("lengnt EM endDay", findMontSignRangeEM.endDay.length)
                            remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                            console.log("remaninf End Day EM", remainingEndDay)
                            numberAgeWalk.value = Math.round(remainingEndDay.value / 3);//ues math round to round down every time to signle digit

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                            console.log("condition EM")

                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "false") {


                            //backward
                            const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));

                            // Calculate the end of the month for the current date
                            const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                            // Calculate the difference in days
                            const differenceInDays = (endOfMonthOld.getDate() - currentDateOldMonth.getDate());

                            console.log("Difference in days:", differenceInDays)

                            remaininDaysOld.value = (differenceInDays + findMontSignRangeEM.startDay[0]);

                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === "true") {

                            //backward
                            const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));

                            // Calculate the end of the month for the current date
                            const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                            // Calculate the difference in days
                            const differenceInDays = (endOfMonthOld.getDate() - currentDateOldMonth.getDate());

                            console.log("Difference in days:", differenceInDays)

                            remaininDaysOld.value = (differenceInDays + findMontSignRangeEM.startDay[0]);
                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === "true") {
                            //forward
                            remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                            numberAgeWalk.value = Math.round(remainingEndDay.value / 3);//ues math round to round down every time to signle digit

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                            console.log("condition EM")
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === 'false') {
                            //backward
                            const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));

                            // Calculate the end of the month for the current date
                            const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                            // Calculate the difference in days
                            const differenceInDays = (endOfMonthOld.getDate() - currentDateOldMonth.getDate());

                            console.log("Difference in days:", differenceInDays)

                            remaininDaysOld.value = (differenceInDays + findMontSignRangeEM.startDay[0]);
                            console.log("remianing Days old", remaininDaysOld)

                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                            console.log("range Age Walk", rangeAgeWalk)
                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "false") {
                            //forward
                            remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                            numberAgeWalk.value = Math.round(remainingEndDay.value / 3);//ues math round to round down every time to signle digit

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                            console.log("condition EM")
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === "true") {
                            //forward
                            remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;

                            console.log(" remaining old days forward EM", remainingEndDay);
                            numberAgeWalk.value = Math.round(remainingEndDay.value / 3);//ues math round to round down every time to signle digit

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }

                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === "true") {


                            //backward
                            const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));

                            // Calculate the end of the month for the current date
                            const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                            // Calculate the difference in days
                            const differenceInDays = (endOfMonthOld.getDate() - currentDateOldMonth.getDate());

                            console.log("Difference in days:", differenceInDays)

                            remaininDaysOld.value = (differenceInDays + findMontSignRangeEM.startDay[0]);
                            numberAgeWalk.value = Math.round(remaininDaysOld.value / 3);

                            let start = numberAgeWalk.value;

                            rangeAgeWalk.value = [];
                            for (let i = 0; i < 10; i++) {
                                const end = start + 10;

                                rangeAgeWalk.value.push(`${start} - ${end}`);
                                start = end;
                            }
                        }


                    }


                }








                const getCurrentYear = httpsCallable(functions, 'getCurrentYear');
                // Call the Cloud Function and await the result
                getCurrentYear({})
                    .then(result => {
                        // Extract the current year from the response
                        const currentYear = result.data.dateTime;

                        //calculate user age
                        userAge.value = currentYear - Number(year.value);



                        const elementIndexByYearAgeWalk = (currentYear - 3 - 1) % 10 + 1;
                        const signIndexByYearAgeWalk = (currentYear - 3 - 1) % 12 + 1;


                        const sliceLengthSignYear = String(signIndexByYearAgeWalk).length >= 2 ? 2 : 1;
                        const sliceLengthEYear = String(elementIndexByYearAgeWalk).length >= 2 ? 2 : 1;
                        const findSignByYearAgeWalk = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLengthSignYear) === String(signIndexByYearAgeWalk));
                        const findElementByyearAgeWalk = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthEYear) === String(elementIndexByYearAgeWalk));


                        const findAgeWalk2 = dataBazi.value.find((b) => b.elementName === findElementByyearAgeWalk.elementName && b.elementSign === findElementByyearAgeWalk.elementSign && b.zodiacName === findSignByYearAgeWalk.zodiacName);
                        // Find the index of the found element
                        const endIndexF = dataBazi.value.indexOf(findAgeWalk2);
                        dataYear.value = [];

                        //Loop to populate dataYear.value
                        for (let i = endIndexF, count = 0; count < 10; i++, count++) {
                            // Ensure cycling forward through dataBazi
                            let index = i % dataBazi.value.length;
                            dataYear.value.push(dataBazi.value[index]);

                            // If reached the end of the array, reset the index
                            if (i === dataBazi.value.length - 1) {
                                i = -1; // Restart loop from the beginning
                            }
                        }

                        //find rangeNumber Year future
                        rangeYearFuture.value = [];


                        let start = parseInt(currentYear);
                        for (let i = 0; i < 10; i++) {
                            rangeYearFuture.value.push(start); // Push the current year into the array
                            start++; // Increment the year for the next iteration
                        }





                    })
                    .catch(error => {
                        console.error('Error fetching current year:', error);
                    });




            }
        }



        const dataRangeAgeWalk = computed(() => {
            const reversedData = rangeAgeWalk.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });



        const dataAgeW = computed(() => {
            const reversedData = dataAgeWalk.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });



        const dataYearFuture = computed(() => {
            const reversedData = dataYear.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        })


        const datarangeYearFuture = computed(() => {
            const reversedData = rangeYearFuture.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });




        const dataDE = ref([]);
        const fetchDeathandEmptinessStar = async () => {
            try {
                await getCollectionQuery("death&emptiness", [orderBy("starCode")], (data) => {
                    dataDE.value = data;
                }, null, true);
            } catch (err) {
                console.log("faled to fetch data", err);
            }
        }
        const dataNB = ref([]);
        const fetchNBStar = async () => {
            try {
                await getCollectionQuery("noblemanStar", [orderBy("starCode")], (data) => {
                    dataNB.value = data;

                }, null, true);
            } catch (err) {
                console.log("faled to fetch data", err);
            }
        }

        const dataLoveStar = ref([]);
        const fetchLoveStar = async () => {
            try {
                await getCollectionQuery("peachBlossomStar", [orderBy("starCode")], (data) => {
                    dataLoveStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const dataITStar = ref([]);
        const fetchITStar = async () => {
            try {
                await getCollectionQuery("intelligenceStar", [orderBy("starCode")], (data) => {
                    dataITStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const dataSHStar = ref([]);
        const fetchSHStar = async () => {
            try {
                await getCollectionQuery("skyHorseStar", [orderBy("starCode")], (data) => {
                    dataSHStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataSLStar = ref([]);
        const fetchSLStar = async () => {
            try {
                await getCollectionQuery("solitrayStar", [orderBy("starCode")], (data) => {
                    dataSLStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataGD = ref([]);
        const fetchGDStar = async () => {
            try {
                await getCollectionQuery("goldenCarriageStar", [orderBy("starCode")], (data) => {
                    dataGD.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataPoolStar = ref([]);
        const fetchpoolStar = async () => {
            try {
                await getCollectionQuery("poolStar", [orderBy("starCode")], (data) => {
                    dataPoolStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }




        const dataBazi = ref([]);
        const fetch60Bazi = async () => {
            try {
                await getCollectionQuery("60Bazi", [orderBy("baziCode")], (data) => {
                    dataBazi.value = data;
                }, null, true);




            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }




        const handlePrint = () => {
            window.print();
        }


        onMounted(async () => {
            fetchDeathandEmptinessStar();
            fetchNBStar();
            fetchLoveStar();
            fetchITStar();
            fetchSHStar();
            fetchSLStar();
            fetchGDStar();
            fetchpoolStar();
            fetch60Bazi();
            handleCalcualteBazi();





        })

        return {
            unknowTimeOfBirth,

            handlePrint,
            hour,
            minute,
            formatMonth,
            name,
            gender,
            twin,
            month,
            day,
            userAge,
            kouyNumber,
            year,
            datarangeYearFuture,
            dataYearFuture,
            dataAgeW,
            dataRangeAgeWalk,
            dataResultStarPredict,
            dataResultPredict,

        }

    }

}
</script>

<style scoped>
@media print {
    .hidden-print {
        display: none;
    }

    .print-header {
        font-size: 20px;
        line-height: 1.5rem
            /* 24px */
        ;
    }

    #printTable {
        visibility: visible;
        position: absolute;
        font-size: 1rem
            /* 16px */
        ;
        line-height: 1.5rem
            /* 24px */
        ;
        left: 0;
        top: 0;
    }
}
</style>