<template>
    <nav class="flex text-base items-center mb-4 font-notoKhmer hidden-print" aria-label="Breadcrumb">
                <div class="flex items-center space-x-1">
                    <router-link 
                        to="/admin"
                        class="flex space-x-1 items-center font-medium text-tertiary hover:text-primary">
                        <font-awesome-icon icon="fa-solid fa-house" size="sm"/>
                        <p>Home</p>
                    </router-link>     
                    <font-awesome-icon icon="fa-solid fa-angle-right" size="sm" />
                    <span class="font-medium">{{ path }}</span>
                </div>
        </nav>
</template>

<script>
    export default{
        props : ['path']
    }
</script>