<template>
    <div class="bg-secondary pt-10 pb-5 font-notoSans">
        <div class="w-[90%] lg:max-w-[1080px] mx-auto">
              <div class="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-10 sm:gap-20">
                    <router-link to="/">
                            <img class="w-[150px] mx-auto md:w-full h-full object-fill" src="../assets/logo.png" alt="logo">
                    </router-link>

                    <div class="w-full space-y-10 text-tertiary">
                           <div>
                                <p class="text-xl font-semibold mb-4">Contact us</p>
                                <div class="space-y-2">
                                    <!-- <div class="space-x-2">
                                        <span>Email:</span>
                                        <a :href="socialAccount[2]">{{ socialAccount[2] }}</a>
                                    </div> -->
                                    <div class="space-x-2">
                                            <span>Telephone:</span>
                                            <!-- <input type="tel" :value="phone[0]"> -->
                                            <a :href="`tel:${phone[0]}`" class="hover:underline decoration-tertiary">{{ phone[0] }}</a>
                                    </div>
                                    <div class="space-x-2">
                                            <span>Telegram:</span>
                                            <a :href="socialAccount[1]" class="hover:underline decoration-tertiary" target="_blank">{{ socialAccount[1] }}</a>
                                    </div>
                                    <div class="space-x-2">
                                            <span>Address:</span>
                                            <span>{{companyInfo.address}}</span>
                                    </div>
                                </div>
                           </div>

                            <div class="text-center sm:text-left">
                                <p class="text-xl font-semibold mb-4">Feng Shui Tool</p>
                                 <router-link to="/calculate-bazi" class="text-quaternary hover:underline">Calculate bazi</router-link>
                            </div>
                            
                    </div>
              </div>    
              
              <h2 class="text-center text-xl text-tertiary mt-10">Follow Us</h2>

              <div class="mb-8">
                    <div class="relative">
                            <hr class="h-[2px] bg-tertiary hidden sm:block">
                            <div class="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 px-4 py-2 flex space-x-4 max-w-fit rounded-full bg-secondary-light">
                                    <a :href="socialAccount[1]"  target="_blank">
                                        <font-awesome-icon icon="fa-brands fa-telegram" size="2xl"/>
                                    </a>
                                    <a :href="socialAccount[0]"  target="_blank">
                                        <font-awesome-icon icon="fa-brands fa-facebook" size="2xl"/>
                                    </a>
                                    <!-- <a :href="socialAccount[2]">
                                        <font-awesome-icon icon="fa-solid fa-envelope" size="2xl"/>
                                    </a> -->
                                    <a :href="socialAccount[3]"  target="_blank">
                                        <font-awesome-icon icon="fa-brands fa-youtube" size="2xl"/>
                                    </a>
                            </div>
                    </div>
              </div>

              <div class="flex flex-col gap-2 sm:flex-row justify-between items-center text-sm pt-10">
                        <span>
                                Copyright @2024
                        </span>
                        <span>
                                Developed by: MasterIT-System
                        </span>
                </div>
        </div>
    </div>
</template>

<script>
import { getCollectionQuery } from '@/composables/getCollection'
import { ref } from 'vue';
import { onMounted } from 'vue';
export default{
    setup(){
        const companyInfo = ref({});
        const phone = ref([]);
        const socialAccount = ref([]);

        const fetchCompanyInfo = async () => {
           await getCollectionQuery("companyInfo",[],(data) => {
                companyInfo.value = data[0]
                phone.value = companyInfo.value.phone;
                socialAccount.value = companyInfo.value.socialAccount;
            });
        }   

        onMounted(() => {
             fetchCompanyInfo()
        })

        return{
            companyInfo,
            phone,
            socialAccount
        }
    }
}
</script>

<style>

</style>