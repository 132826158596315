<template>
            <div class="relative">
                <div 
                    @click="() => isOpen = true" 
                    class="form-control bg-white w-fit cursor-pointer">
                    {{ hour }} : {{ minute }} 
                </div>

                <TransitionGroup tag="div" name="nested">
                    <div v-if="isOpen" class="absolute bg-white mt-4 h-[250px] border border-slate-300 rounded-md">
                        <transition>

                        <div>
                            <div class="relative">
                                <div class="p-2 text-center border-b border-quaternary-low-light">
                                       <p>Time</p>
                                </div>
                                <div class="flex">
                                    <span class="w-[90px] h-[90px]"><VueScrollPicker v-model="hour" :options="hours"/></span>
                                    <span class="w-[90px] h-[90px]"><VueScrollPicker v-model="minute" :options="minutes"/></span>
                                    
                                </div>
                            </div>
                               <div class="absolute bottom-2 right-0  w-full flex justify-between px-4 py-2">
                                    <div @click="handleCancel"  class="rounded-full py-1 cursor-pointer">Cancel</div>
                                    <div @click="setTime"  class="btn btn-primary rounded-full py-1 cursor-pointer">Apply</div>
                               </div>
                        </div>
                        </transition>
                    </div>
                </TransitionGroup>
            </div>
</template>

<script>
 import moment from 'moment'
import {onMounted, ref} from 'vue'
export default {
    emits : ['onEmitTime'],
    setup(_, {emit}){
        const isOpen = ref(false);
        const hour = ref(null);
        const minute = ref(null);
        const hours = ref([]);
        const minutes = ref([]);
        const period = ref(null);
              // Get current local date
              const currentDate = new Date();

        hour.value = currentDate.getHours();
        minute.value = currentDate.getMinutes();
    
        hour.value = moment(currentDate).format('HH');
        minute.value = moment(currentDate).format('mm');

        hours.value = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
        ];
        minutes.value = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '58',
            '59',
        ]
       
        const setTime = () => {
            const vHour = +hour.value;
            const vMinute = +minute.value;
            emit('onEmitTime', vHour, vMinute);
            isOpen.value = false;
        }

        const handleCancel = () => {
            // hour.value = moment().format('hh');
            // minute.value = moment().format('mm');
            // period.value = moment().format('A');
            isOpen.value = false
        }

        onMounted(()=>{
            setTime();
        })

        return{
            hours,
            minutes,
            hour,
            minute,
            period,
            isOpen,
            setTime,
            handleCancel
        }
    }
}
</script>