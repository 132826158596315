
import { projectFirestore } from "@/firebase/config";
import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";


const useCollection = (collectionName) => {
  let collectionRef = collection(projectFirestore , collectionName); //items

  const addDocs = async (formDoc) => {

    try{
      await addDoc(collectionRef, formDoc);  
      return true;
    }catch(err){
      console.log("faild to add data",err);
      return false;
    }
  
  };

  const setDocs = async (id, formDoc) => {

    try{
        await setDoc(doc(collectionRef, id), formDoc);
        return true;
    }catch(err){
      console.log("faild to add data",err);
      return false;
    }

     
  };

  const removeDoc = async (id) => {
    try {
     return await deleteDoc(doc(collectionRef, id));
     
    } catch (err) {
      console.log("Failed to delete data", err);
      return false;
    }
  };

  const updateDocs = async (id, formDoc) => {
    try {
      await updateDoc(doc(collectionRef, id), formDoc);
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error updating document:", error);
      return false; // Return false to indicate failure
    }
  };

  return { addDocs, setDocs, removeDoc, updateDocs };
};

export default useCollection;
