<template>
    <div class="my-8">
            <h2 class="uppercase text-center text-[24px] mb-4 font-medium text-tertiary">about us</h2>

            <hr class="h-[1px] border-none bg-quaternary-low-light">


            <div class="my-10">
                <div class="max-w-[90%] xl:max-w-[1080px] w-full mx-auto lg:grid  lg:grid-cols-2 gap-20">
                    <div>
                        <img class="h-full w-full object-cover rounded-2xl " src="https://images.unsplash.com/photo-1685677112447-4819163a823b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fGZlbmclMjBzaHVpfGVufDB8fDB8fHww" alt="">
                    </div>
                    <div class="mt-4 lg:mt-0 space-y-3">
                         <h4 class="text-[24px]">History</h4>
                         <p class="text-quaternary text-justify text-lg">{{companyInfo.history}}</p>    
                    </div>
                </div>

                <div class="max-w-[90%] xl:max-w-[900px] w-full mx-auto my-20">
                    <h4 class="text-[24px] mb-2" v-if="companyInfo.legacy != 'none'">Legacy</h4>
                    <p class="text-quaternary" v-if="companyInfo.legacy != 'none'">{{companyInfo.legacy}}</p>
                     
                     <!-- <div class="grid grid-cols-3 gap-5 my-4">
                            <img class="rounded-2xl object-cover shadow-sm h-[250px]" src="https://plus.unsplash.com/premium_photo-1710197034930-e55ebfa7f6ac?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                            <img class="rounded-2xl object-cover shadow-sm h-[250px]" src="https://plus.unsplash.com/premium_photo-1710197034930-e55ebfa7f6ac?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                            <img class="rounded-2xl object-cover shadow-sm h-[250px]" src="https://plus.unsplash.com/premium_photo-1710197034930-e55ebfa7f6ac?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                     </div> -->
                    <!-- <p class="text-quaternary">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, magnam. Eligendi adipisci odio fugiat laborum modi porro neque voluptatum, nulla officiis animi a pariatur quasi ad placeat numquam fugit ea natus, repudiandae autem facere possimus harum? Sint placeat reiciendis amet, ipsum qui vel, temporibus dignissimos quibusdam mollitia laborum nostrum neque fuga aperiam quaerat. Consequatur, at laborum dolorum voluptatum voluptates architecto, porro fuga odit atque perferendis quisquam ipsam aliquid corporis, nostrum magnam ut earum tempore? Et atque minus eveniet, molestias neque mollitia magni consectetur, error voluptates corporis quam tempore totam.</p> -->
                </div>
            </div>
    </div>
</template>

<script>
import { getCollectionQuery } from '@/composables/getCollection'
import { ref } from 'vue';
import { onMounted } from 'vue';
export default{
    setup(){
        const companyInfo = ref({});
        const fetchCompanyInfo = async () => {
           await getCollectionQuery("companyInfo",[],(data) => {
                companyInfo.value = data[0]
            });
        }   

        onMounted(() => {
             fetchCompanyInfo()
        })

        return{
            companyInfo
        }
    }
}
</script>

<style>

</style>