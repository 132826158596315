
import { createRouter, createWebHistory } from 'vue-router'



// =========> Client
import HomePage from '@/views/HomePage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import AboutPage from '@/views/AboutPage.vue';
import ContactPage from '@/views/ContactPage'
import ClientLayout from '@/views/ClientLayout.vue';
import AllCourseCategoryPage from '@/views/AllCourseCategoryPage.vue';
import ByCourseCategoryPage from '@/views/ByCourseCategoryPage.vue'
import ShowCourseInfoPage from '@/views/ShowCourseInfoPage.vue';
import CalculateBaziPage from '@/views/CalculateBaziPage.vue';
import ShowResultBaziPage from '@/views/ShowResultBaziPage.vue';




const routes = [


  {
    path: '/',
    component: ClientLayout,
    children:[
      {
        path: '',
        name: 'home-page',
        component: HomePage,
      },
      {
        path: '/about-page',
        name: 'about-page',
        component: AboutPage
      },
      {
        path: '/ContactPage',
        name: 'contact-page',
        component: ContactPage
      },
      {
        path: '/all-course-category/:allCourse',
        name: 'all-course-category-page',
        component: AllCourseCategoryPage
      },
      {
        path: '/course-category/:id',
        name: 'by-course-category-page',
        component: ByCourseCategoryPage
      },
      {
        path: '/course/:id/:courseID',
        name: 'show-course-info-page',
        component: ShowCourseInfoPage
      },
      {
        path: '/calculate-bazi',
        name: 'calculate-bazi-page',
        component:CalculateBaziPage
      },
      
    ]
  },


  
  {
    path: '/show-result-bazi/:name/:hour/:minute/:day/:month/:year/:gender/:twin/:unknowT',
    name: 'show-result-bazi-page',
    component: ShowResultBaziPage
  },



  {
    path: "/:pathMatch(.*)*",
    name: "not-found-page",
    component: NotFoundPage,
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
})

export default router

