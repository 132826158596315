<template>
  <div class=" animate-fade-up animate-duration-300 font-poppins">
    <BreadCrumbsVue path="Course Category" />

    <div class="flex justify-between items-center">
      <h1 class="font-semibold text-xl md:text-2xl">Course Category Page</h1>
      <button @click="mountComponent('CourseCategoryModalVue')" class="btn btn-primary text-lg rounded-full">
        + Add
      </button>
    </div>

    <div class="my-2">
      <div class="flex justify-between items-center mt-4 mb-2">
        <select v-model="pageSize"
          class="px-[20px] py-[8px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </select>

        <div class="relative">
          <input v-model="searchText" type="text" class="form-control min-w-[400px] py-[8px] rounded-xl"
            placeholder="Search by category name" />
          <span @click="handleSearchQueryCategory" class="btn-search">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" />
          </span>
        </div>
      </div>
      <div class="relative overflow-x-auto">
        <table class="w-full text-base border-primary text-left">
          <thead class="uppercase">
            <tr class="[&>*]:border [&>*]:p-2 [&>*]:border-primary bg-primary font-semibold text-white uppercase">
              <td class="whitespace-nowrap">Code</td>
              <td class="whitespace-nowrap">Course Category</td>
              <td class="whitespace-nowrap">Date</td>
              <td class="whitespace-nowrap">Status</td>
              <td class="whitespace-nowrap">Action</td>
            </tr>
          </thead>
          <tbody v-if="courseCategories?.length > 0">
            <tr v-auto-animate v-for="doc in courseCategories" :key="doc.id"
              class="[&>*]:border-2 [&>*]:p-2 [&>*]:border-primary text-tertiary">
              <td class="whitespace-nowrap">{{ doc.categoryCode }}</td>
              <td class="whitespace-nowrap">{{ doc.categoryName }}</td>
              <td class="whitespace-nowrap">{{ formatDate(doc.createdAt) }}</td>
              <td class="whitespace-nowrap cursor-pointer" @click="handleUpdateStatusPopup(doc)">
                <div :class="doc.status ? 'bg-green-600' : 'bg-red-600'" class="px-1.5 w-fit rounded-full text-white">
                  <span v-if="doc.status" class="flex space-x-1 items-center">
                    <font-awesome-icon icon="fa-solid fa-circle-check" />
                    <span>Active</span>
                  </span>
                  <span v-else class="flex space-x-1 items-center">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                    <span>Inactive</span>
                  </span>
                </div>
              </td>
              <td class="space-x-2 whitespace-nowrap">
                <button @click="handleEditCategory(doc)"
                  class="text-yellow-700 space-x-1 font-medium hover:underline whitespace-nowrap">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                  Edit
                </button>
                <button @click="handleDeleteCategory(doc)"
                  class="text-red-600 space-x-1 font-medium hover:underline whitespace-nowrap">
                  <font-awesome-icon icon="fa-solid fa-trash" />
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center bg-white [&>*]:border [&>*]:p-2 [&>*]:border-primary">
              <td colspan="7" class="px-6 py-4 text-center whitespace-nowrap">
                No data has been found!
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="documents?.length > 0 && searchText===''">
        <PaginationVue :pageSize="pageSize" :loadLastID="loadLastID" :isAddSuccess="isAddSuccess" :commitLoadTotalPage="commitLoadTotalPage"
          @onEmitDataFromPagination="handleListenToPagination" />
      </div>
    </div>
  </div>
  <component :is="currentComponent" :doc="courseCategory" :lastID="lastID" :updateStatusID="updateStatusID" @closeModal="unMountComponent"
    @handleDeleteSuccess="handleDeleteCategorySuccess" @onDeletelastID="handleDeletelastID" @handleUpdateSuccess="handleUpdateSuccess"
    @handleAddSuccess="handleAddCategorySuccess" />
</template>

<script>
import BreadCrumbsVue from "@/components/admin/ui/BreadCrumbsVue.vue";
import PaginationVue from "@/components/admin/PaginationVue.vue";
import CourseCategoryModalVue from "@/components/admin/modal/CourseCategoryModalVue.vue";
import DeleteCategoryConfirmationVue from "@/components/admin/modal/DeleteCategoryConfirmationVue.vue";
import { ref, watch } from "vue";
import getCollectionPaginate from "@/composables/getCollectionPaginate";
import { limit, orderBy, where } from "firebase/firestore";
import { push } from "notivue";
import moment from "moment";
import { getCollectionQuery } from "@/composables/getCollection";
import UpdateStatusComfirmationModalVue from '@/components/admin/modal/UpdateStatusComfirmationModal.vue';

export default {
  components: {
    BreadCrumbsVue,
    PaginationVue,
    CourseCategoryModalVue,
    DeleteCategoryConfirmationVue,
    UpdateStatusComfirmationModalVue
  },
  setup() {
    const courseCategories = ref([]);
    const courseCategory = ref("");
    const currentComponent = ref("");
    const pageSize = ref(20);

    const searchText = ref("");
    const lastID = ref(null);

    const { documents } = getCollectionPaginate("course-categories", [
      orderBy("createdAt"),
      limit(pageSize.value),
    ]);

    watch(documents, () => {
      courseCategories.value = documents.value || [];
    });

    const handleListenToPagination = (items) => {
      courseCategories.value = items;
    };


   
  
    const commitLoadTotalPage=ref(false);
    const handleDeleteCategorySuccess = () => {
      try {
        // if (deletID) {
        //   console.log("delete ID last",deletID)
        //   courseCategories.value = courseCategories.value.filter(
        //     (item) => item.id != deletID
        //   );
    
        //   if (courseCategories.value.length < 1) {
        //     isLastDocumentOfPage.value = true;
        //   }
        // }
        commitLoadTotalPage.value=true;

        push.success("Course Category has been deleted successfully!");
      } catch (err) {
        console.error("Error handling delete operation:", err);
      }
    };

    const loadLastID=ref(null);
    const handleDeletelastID=()=>{
      loadLastID.value=lastID.value;

    }


    const handleSearchQueryCategory = async () => {
      try {
        if (searchText.value) {
          await getCollectionQuery("course-categories",[where("categoryNameLowerCase","==",searchText.value.toLowerCase())],(data)=>{
          courseCategories.value=data;
        },null,false);
        }else{
          courseCategories.value = documents.value || [];
        }
      } catch (err) {
        console.log("faile to search users", err);
      }
    };




    watch(searchText, async(newV) => {
      if(!newV){
          courseCategories.value = documents.value || [];
      }
    });






    const mountComponent = (component) => {
      currentComponent.value = component;
    };

    const unMountComponent = async () => {
      currentComponent.value = "";
      courseCategory.value = "";
    };

    const isAddSuccess = ref(null);
    const handleAddCategorySuccess = async () => {
      isAddSuccess.value = true;
      push.success("Course category has been add successfully");
    };

    const handleUpdateSuccess = async () => {
      push.success("Course category has been update successfully");
    };

    const handleEditCategory = (doc) => {
      courseCategory.value = doc;
      mountComponent("CourseCategoryModalVue");
    };



    const handleDeleteCategory = async (doc) => {
      const dataAssociateCourse = ref([]);
      await getCollectionQuery(
        "courses",
        [where("categoryID", "==", doc.id)],
        (data) => {
          dataAssociateCourse.value = data;
          console.log(dataAssociateCourse.value)
        },null,
        false
      );

      if (dataAssociateCourse.value.length > 0) {
        push.error(`Sorry ,You can't delete course category !`);
      } else {

        // console.log("course categories",courseCategories)
        if (courseCategories.value.length <= 1) {
          lastID.value = doc.id;
        } else {
          courseCategory.value = doc;
          lastID.value = null;
        }
        mountComponent("DeleteCategoryConfirmationVue");
      }
    };


    const updateStatusID=ref(null);
    const handleUpdateStatusPopup=(doc)=>{
      updateStatusID.value=doc;
      mountComponent('UpdateStatusComfirmationModalVue')
    }


    //for format Date
    const formatDate = (date) => {
      if (date && date.toDate) {
        return moment(date.toDate()).format("DD/MM/YYYY h:mm A");
      } else {
        return "";
      }
    };

    return {
      loadLastID,
      commitLoadTotalPage,
      handleDeletelastID,
      updateStatusID,
      handleUpdateStatusPopup,
      isAddSuccess,
      lastID,
      handleListenToPagination,
      handleUpdateSuccess,
      handleAddCategorySuccess,
      handleDeleteCategorySuccess,
      currentComponent,
      courseCategories,
      courseCategory,
      pageSize,
    
      searchText,
      documents,
      formatDate,
      handleSearchQueryCategory,
      mountComponent,
      unMountComponent,
      handleDeleteCategory,
      handleEditCategory,
    };
  },
};
</script>

<style></style>
