<template>
    <div class="font-notoKhmer animate-fade-up animate-duration-300">

        <div class="my-2">
            <div class="flex justify-between items-center mt-4 mb-2">
                <div class="flex items-center space-x-4">
                    <select v-model="pageSize"
                        class="px-[20px] py-[8px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>



                    <!-- <div class="relative">
                        <select v-model="filterText"
                            class="px-[30px] py-[8px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">

                            <option value="" selected disabled>Filter By Status</option>
                            <option value="Checked">Checked</option>
                            <option value="Unchecked">Unchecked</option>

                        </select>

                        <span @click="handleClearField"
                            class="absolute top-1/2 right-6 transform -translate-y-1/2 text-red-600 cursor-pointer">
                            <font-awesome-icon size="sm" icon="fa-solid fa-circle-xmark" />
                        </span>
                    </div>
 -->

                </div>




                <div class="relative">
                    <input v-model="searchText" type="text" class="form-control min-w-[400px] py-[8px] rounded-xl"
                        placeholder="Search by name">
                    <span @click="handleSearchQuery" class="btn-search">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" />
                    </span>
                </div>
            </div>
            <div class="relative overflow-x-auto">
                <table class="w-full text-base border-primary text-left">
                    <thead class="uppercase">
                        <tr
                            class="[&>*]:border [&>*]:p-2 [&>*]:border-primary bg-primary font-semibold text-white uppercase">
                            <td class="whitespace-nowrap">Course</td>
                            <td class="whitespace-nowrap">Date</td>
                            <td class=" whitespace-nowrap">Name</td>
                            <td class=" whitespace-nowrap">Telegram</td>
                            <td class=" whitespace-nowrap">Phone</td>
                            <td class=" whitespace-nowrap">Status</td>
                            <td class=" whitespace-nowrap">Submit Type</td>
                            <td class="whitespace-nowrap">Comments</td>

                        </tr>
                    </thead>
                    <tbody v-if="userSubmitData.length > 0">
                        <tr v-for="s in userSubmitData" :key="s.id"
                            class="[&>*]:border-2 [&>*]:p-2  [&>*]:border-primary text-tertiary">
                            <td class="whitespace-nowrap">{{ formatCourseName(s.courseID) }}</td>
                            <td class="whitespace-nowrap text-center">{{ formatDate(s.createdAt) }}</td>
                            <td class="whitespace-nowrap">{{ s.name }}</td>
                            <td class="whitespace-nowrap">{{ s.telegram }}</td>
                            <td class="whitespace-nowrap">{{ s.phone }}</td>
                            <td class="whitespace-nowrap cursor-pointer" @click="handleUpdateStatusPopup(s)">
                                <div :class="s.status ? 'bg-green-600' : 'bg-red-600'"
                                    class="px-1.5 w-fit rounded-full text-white">
                                    <span v-if="s.status" class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" />
                                        <span>Checked</span>
                                    </span>
                                    <span v-else class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                                        <span>unChecked</span>
                                    </span>
                                </div>

                            </td>
                            <td class="whitespace-nowrap">{{ s.submitType }}</td>
                            <td class="whitespace-nowrap">{{ s.comments }}</td>
                            <!-- <td class="whitespace-nowrap">
                                <button @click="handleDelete(s)"
                                    class="text-red-600 space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                    Delete
                                </button>
                            </td>
                            -->


                        </tr>

                    </tbody>
                    <tbody v-else>
                        <tr class="text-center bg-white [&>*]:border [&>*]:p-2 [&>*]:border-primary">
                            <td colspan="8" class="px-6 py-4 text-center whitespace-nowrap">
                                No data has been found!
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="userSubmitData.length > 0 && searchText === ''" class="flex justify-between items-center">
                <div class="text-base px-1">
                    <span>Page {{ currentPage }} of {{ totalPage }}</span>
                </div>
                <div class="flex space-x-2 mt-4">
                    <button :disabled="currentPage === 1" class="btn-paginate" @click="loadPreviousPage">
                        <font-awesome-icon icon="fa-solid fa-chevron-left" />
                    </button>
                    <button class="btn-paginate-number">{{ currentPage }}</button>
                    <button :disabled="currentPage === totalPage" class="btn-paginate" @click="loadNextPage">
                        <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </button>
                </div>
            </div>
        </div>
    </div>

    <component :updateStatusID="updateStatusID" :is="currentComponent" @closeModal="unMountComponent" />

</template>

<script>
import { onMounted, ref, watch } from 'vue';
import UpdateStatusComfirmationModalVue from '@/components/admin/modal/UpdateStatusComfirmationModal.vue';

import getCollectionPaginate from '@/composables/getCollectionPaginate2';
import { orderBy, limit, where } from 'firebase/firestore';
import moment from 'moment';
import { getCollectionQuery } from '@/composables/getCollection';
// import moment from 'moment';
export default {
    components: {
        UpdateStatusComfirmationModalVue
    },
    setup() {
        const currentPage = ref(1);
        const totalPage = ref(1);
        const pageSize = ref(2);
        const searchText = ref('');
        const currentComponent = ref("");
        const queryDocs = ref([orderBy("createdAt", "desc"), limit(pageSize.value)])
        const userSubmitData = ref([]);
        const filterText = ref("");


        const { nextPage, previousPage, getCollectionLength } = getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
             userSubmitData.value =data;
        })


    


        const loadPreviousPage = async () => {
            try {
                const firstDocument = userSubmitData.value[0];
                if (firstDocument) {
                    await previousPage(firstDocument.id, pageSize.value);
                    currentPage.value -= 1;

                }
            } catch (err) {

                console.error("Error fetching previous page:", err);
            }
        };

        const loadNextPage = async () => {
            try {
                const lastDocument = userSubmitData.value[userSubmitData.value.length - 1]
                if (lastDocument) {
                   await nextPage(lastDocument.id, pageSize.value);
                    currentPage.value += 1;

                }
            } catch (err) {
                console.error("Error fetching next page:", err);
            }
        };



        watch(pageSize, async (newV) => {
            if (newV) {

              
                queryDocs.value = [limit(Number(newV)), orderBy("createdAt", "desc")]

                getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
                    userSubmitData.value=data;
                });

            
                const total = await getCollectionLength("userSubmissions", []);
                totalPage.value = Math.ceil(total / pageSize.value);
            }


        })

        // watch(filterText, async () => {
        //     if (filterText.value=== 'Checked') {
            
             
        //         queryDocs.value = [limit(Number(pageSize.value)), orderBy("createdAt", "desc"), where("status", "==", true)];
        //         getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
        //             userSubmitData.value=[];
        //             userSubmitData.value=data;
        //         });


             
        //         const total = await getCollectionLength("userSubmissions", [where("status", "==", true)]);
        //         totalPage.value = Math.ceil(total / pageSize.value);
        //         currentPage.value=1;
        //     } else if (filterText.value === 'Unchecked') {
            
        //         queryDocs.value = [limit(Number(pageSize.value)), orderBy("createdAt", "desc"), where("status", "==", false)];

        //         getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
        //             userSubmitData.value=[];
        //             userSubmitData.value=data;
        //         });

        //         const total = await getCollectionLength("userSubmissions", [where("status", "==", false)]);
        //         totalPage.value = Math.ceil(total / pageSize.value);
        //         currentPage.value=1;
        //     }else{
        //         queryDocs.value = [limit(Number(pageSize.value)), orderBy("createdAt", "desc")]

        //         getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
        //             userSubmitData.value=data;
        //         });

        //         const total = await getCollectionLength("userSubmissions", []);
        //         totalPage.value = Math.ceil(total / pageSize.value);
        //         currentPage.value=1;
        //     }
        // })


        const handleSearchQuery = async () => {
            try {
                if (searchText.value) {
                    await getCollectionQuery("userSubmissions", [where("nameLowerCase", "==", searchText.value.toLowerCase())], (data) => {
                        userSubmitData.value = data;
                    }, null, false);
                }
            } catch (err) {
                console.log("faile to search users", err);
            }
        };


        watch(searchText, async (newV) => {
            if (!newV) {
                queryDocs.value = [limit(Number(pageSize.value)), orderBy("createdAt", "desc")]

                getCollectionPaginate("userSubmissions", queryDocs.value,(data)=>{
                    userSubmitData.value=data;
                });

                const total = await getCollectionLength("userSubmissions", []);
                totalPage.value = Math.ceil(total / pageSize.value);

            }
        });



        const courseData = ref([]);
        const fetchCourses = async () => {
            try {
                await getCollectionQuery("courses", [], (data) => {
                    courseData.value = data;
                }, null, true);

            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const formatCourseName = (ID) => {
            const course = courseData.value.find((c) => c.id === ID);
            return course ? course.title : "";
        };



        const unMountComponent = async () => {
            currentComponent.value = '';


        }

        const mountComponent = (component) => {
            currentComponent.value = component;
        }
        const updateStatusID = ref(null);
        const handleUpdateStatusPopup = (doc) => {
            updateStatusID.value = doc;


            mountComponent("UpdateStatusComfirmationModalVue");
        }



        //for format Date
        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MMM/YYYY h:mm A");
            } else {
                return "";
            }
        };


        const handleClearField = () => {
            filterText.value = "";
        }

        onMounted(async () => {
            fetchCourses();
            const total = await getCollectionLength("userSubmissions", []);
            totalPage.value = Math.ceil(total / pageSize.value);
        })

        return {
            handleClearField,
            filterText,
            handleSearchQuery,
            formatCourseName,
            formatDate,
            userSubmitData,
            loadPreviousPage,
            loadNextPage,
            currentComponent,
            unMountComponent,
            updateStatusID,
            handleUpdateStatusPopup,
            pageSize,
            currentPage,
            totalPage,
            searchText,
        }
    }
}
</script>

<style></style>