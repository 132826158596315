import { ref } from "vue";
import { projectAuth } from "@/firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";

const error = ref(null);
const signin = async (email, password) => {
  error.value = null;

  try {
    await signInWithEmailAndPassword(projectAuth, email, password);
  } catch (err) {
    error.value = err.message;
    console.log("Error login",err);
  }
};

const useSignin = () => {
  return { error, signin };
};

export default useSignin;
