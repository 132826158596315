<template>
         <div class="space-y-3">
                <div v-for="c in list" :key="c.id" class="grid animate-fade-up animate-duration-300 grid-cols-3 h-[130px] gap-2 md:hidden border shadow-sm overflow-hidden rounded-lg">
                                    <router-link  :to="{ name: 'show-course-info-page', params: { id: c.categoryID , courseID:c.id } }" class="relative">
                                        <img class="w-full h-full absolute top-0 left-0 object-cover bg-center" :src="c.imageUrl" :alt="c.title">
                                        <div class="absolute w-full bottom-0 left-0 grid place-items-center h-[30px] bg-blood text-white">
                                                <span class="line-through text-left absolute left-1 bottom-1.5 font-thin text-xs">{{c.discount ? numeralFormat(c.discount ,'0,0[.]00 $') : '0 $'}}</span>
                                                <span class="text-base font-medium">{{numeralFormat(c.price , '0,0[.]00 $')}}</span>
                                        </div>
                                    </router-link>

                                    <div class="col-span-2 p-2 flex flex-col justify-between">
                                        <div>
                                            <h2 class="text-lg">{{ c.title }}</h2>
                                            <p  class="line-clamp-2 text-quaternary text-sm">{{ c.summarize }}</p>
                                        </div>

                                            <div class="flex justify-between items-center">
                                                    <div class="flex flex-col text-xs">
                                                        <span class="text-quaternary">Start Class</span>
                                                        <span class="text-tertiary">{{c.startDate ?  formatDate(c.startDate)  : "None"}}</span>
                                                    </div>

                                                    <div>
                                                        <button @click="mountComponent(c)" class="btn btn-primary py-1 rounded-full">Join</button>
                                                    </div>
                                            </div>
                                        </div>
                        
                </div>
                 <JoinCourseModal :isOpen="isOpenModal" :doc="course" @closeModal="isOpenModal = false" />
         </div>

               
</template>

<script>
import JoinCourseModal from '@/components/JoinCourseModal.vue'
import { ref, watchEffect } from 'vue'
import moment from 'moment'
export default {
    props:['allCourse', 'courseByCate'],
    components:{
        JoinCourseModal
    },
    setup(props){
        const isOpenModal = ref(false)
        const course = ref({})
        const list = ref([])
        const mountComponent = ( data ) => {
             course.value = {...data}
             isOpenModal.value = true;
        }

        const useStripHtml = (html) =>  {
            let regex = /(<([^>]+)>)/ig;
            return html.replace(regex, "");
        }

        const formatDate = (date) => {
                        if (date && date.toDate) {
                                return moment(date.toDate()).format("DD/MMM/YYYY ");
                        } else {
                                return "";
                        }
        };

        watchEffect(() => {
            if (props.allCourse) {
                    list.value = props.allCourse;
                    // console.log(list.value)

            } else if (props.courseByCate) {
                    list.value = props.courseByCate
            }
        });

        return{
            isOpenModal,
            course,
            list,
            mountComponent,
            useStripHtml,
            formatDate
        }
    }
}
</script>

<style>

</style>