<template>
    <router-link  v-for="c in moreCourses" :key="c.id"  :to="{ name: 'show-course-info-page', params: { id: c.categoryID , courseID:c.id } }">
        <div class="grid lg:grid-cols-1 bg-white sm:grid-cols-2 gap-5 p-2">
            <div class="hidden sm:block border shadow-sm overflow-hidden rounded-lg "> 
            <div class="relative">
                        <img class="w-full h-[150px] object-cover" :src="c.imageUrl" alt="">
                        <div class="absolute bottom-0 left-0 h-[60px] bg-blood/40 w-full">

                        </div>
                        <div class="leading-none flex items-center justify-center flex-col absolute left-0 bottom-0 h-[60px] w-[80px] bg-blood rounded-r-full">
                        
                            <span class="text-base line-through text-white pt-1"> {{ numeralFormat(c.discount ,'0,0[.]00 $') }}</span>
                            <span class="text-lg font-bold text-white pb-1">{{ numeralFormat(c.price , '0.0[.]00 $') }}</span>

                        </div>

                        <div class="leading-none px-8 flex items-center justify-center flex-col absolute right-0 bottom-0 h-[50px]">
                            <span class="text-xs font-thin text-white">Start Class</span>
                            <span class="text-base font-medium text-white">{{c.startDate ? formatDate(c.startDate) : "None" }}</span>
                        </div>
            </div>

            <div class="p-4">
                    <h2 class="text-lg line-clamp-1">{{ c.title }}</h2>
                    <p class="line-clamp-2 text-base text-quaternary" >
                       {{ c.summarize }}
                    </p>
            </div>
        </div>
        
    </div>


    <!--Mobile part-->
    <div class="grid grid-cols-3 h-[150px] gap-2 sm:hidden border shadow-sm overflow-hidden rounded-lg">
            <div class="relative">
                <img class="w-full h-full object-cover" :src="c.imageUrl" alt="">
                <div class="absolute w-full bottom-0 left-0 grid place-items-center h-[30px] bg-blood text-white">
                    <span class="line-through text-left absolute left-1 bottom-1.5 font-thin text-xs"> {{ numeralFormat(c.discount ,'0,0[.]00 $') }}</span>
                    <span class="text-base font-medium">{{ numeralFormat(c.price , '0.0[.]00 $') }}</span>
                </div>
            </div>

            <div class="col-span-2 p-2 flex flex-col justify-between">
                    <div>
                        <h2 class="text-lg">{{ c.title }}</h2>
                        <p  class="line-clamp-2 text-quaternary text-sm">{{ c.summarize }}</p>
                    </div>

                    <div class="flex justify-between items-center">
                            <div class="flex flex-col text-xs">
                                  <span class="text-quaternary">Start Class</span>
                                  <span class="text-tertiary">{{c.startDate ? formatDate(c.startDate) : "None"}}</span>
                            </div> 
                    </div>
            </div>
    </div>
    </router-link>
</template>

<script>
import { ref, watchEffect } from 'vue'
import moment from "moment";
export default {
    props:['moreCourse'],
    setup(props){
        const moreCourses=ref([]);

        watchEffect(()=>{
            if(props.moreCourse){
                moreCourses.value=props.moreCourse;
                // console.log("more Course",moreCourses)
            }
        })


        const useStripHtml = (html) =>  {
            let regex = /(<([^>]+)>)/ig;
            return html.replace(regex, "");
        }

        
               //for format Date
               const formatDate = (date) => {
                        if (date && date.toDate) {
                                return moment(date.toDate()).format("DD/MMM/YYYY ");
                        } else {
                                return "";
                        }
                };

        return{
            useStripHtml,
            formatDate,
            moreCourses
        }
        

    }

}
</script>

<style>

</style>