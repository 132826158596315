<template>
    <div class="flex flex-col min-h-screen h-screen">
  <div class="flex flex-grow bg-[#faf9f7] text-tertiary">
    <div class="relative z-10">
      <AdminSidebarVue
        :toggleSideBar="toggleSideBar"
      />
    </div>

      <div class="w-full h-full flex flex-col justify-between min-w-full lg:min-w-[999px]">
      <div class="z-20">
        <AdminNavbarVue @onToggleSideBar="handleToggleSideBar" />
      </div>

      <div class="h-full m-2 p-3 rounded-md bg-white font-notoKhmer">
        <router-view></router-view>
      </div>
      <div>
          <AdminFooterVue />
      </div>
    </div>

  </div>
</div>
<Notivue v-slot="item">
    <Notification :item="item" />
</Notivue>
</template>

<script>
import {ref} from 'vue';
import AdminNavbarVue from '@/components/admin/AdminNavbarVue.vue';
import AdminSidebarVue from '@/components/admin/AdminSidebarVue.vue';
import AdminFooterVue from '@/components/admin/AdminFooterVue.vue';
import { Notivue, Notification } from 'notivue'
export default {
components: {
  AdminNavbarVue,
  AdminSidebarVue,
  AdminFooterVue,
  Notivue,
  Notification
},

setup(){
  const toggleSideBar = ref(false);
  const handleToggleSideBar = () => {
    toggleSideBar.value = !toggleSideBar.value;
  };

  return {
      toggleSideBar,
      handleToggleSideBar
  }
}
};
</script>
