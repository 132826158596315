<template>
    <div class="animate-fade-up animate-duration-300 font-poppins">
        <BreadCrumbsVue path="Company Info" />

        <div class="flex justify-between items-center">
            <h1 class="font-semibold text-xl md:text-2xl">User Page</h1>
            <button @click="mountComponent('UserModalVueVue')" class="btn btn-primary text-lg rounded-full">
                + Add
            </button>
        </div>

        <div class="my-2">
            <div class="flex justify-between items-center mt-4 mb-2">
                <select v-model="pageSize"
                    class="px-[20px] py-[8px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
                    <option value="10">10</option>
                    <option value="10">20</option>
                    <option value="10">50</option>
                    <option value="10">100</option>
                </select>

                <div class="relative">
                    <input type="text" v-model="searchText" class="form-control min-w-[400px] py-[8px] rounded-xl"
                        placeholder="Search by username">
                    <span class="btn-search" @click="handleSearchQueryUser">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" />
                    </span>
                </div>
            </div>
            <div class="relative overflow-x-auto">
                <table class="w-full text-base border-primary text-left">
                    <thead class="uppercase">
                        <tr
                            class="[&>*]:border [&>*]:p-2 [&>*]:border-primary bg-primary b font-semibold text-white uppercase">
                            <td class=" whitespace-nowrap">Image</td>
                            <td class=" whitespace-nowrap">Username</td>
                            <td class="w-[20%] whitespace-nowrap">Email</td>
                            <td class="w-[9%] whitespace-nowrap">Role</td>
                            <td class="w-[20%] whitespace-nowrap">Bio</td>
                            <td class=" whitespace-nowrap">Status</td>
                            <td class=" whitespace-nowrap">Created At</td>
                            <td class=" whitespace-nowrap">Action</td>
                        </tr>
                    </thead>
                    <tbody v-if="usersData.length > 0">
                        <tr v-for="u in  usersData" :key="u.id"
                            class="[&>*]:border-2 [&>*]:p-2  [&>*]:border-primary text-tertiary">
                            <td class="whitespace-nowrap">
                                <img class="w-8 bg-cover" :src="u.imageURL" alt="">
                            </td>
                            <td class="whitespace-nowrap">{{ u.username }}</td>
                            <td class="whitespace-nowrap">{{ u.email }}</td>
                            <td class="whitespace-nowrap">{{ u.role }}</td>
                            <td class="whitespace-nowrap">{{ u.bio }}</td>

                            <td class="whitespace-nowrap cursor-pointer" @click="handleUpdateStatusUserPopUp(u)">
                                <div :class="u.status ? 'bg-green-600' : 'bg-red-600'"
                                    class="px-1.5 w-fit rounded-full text-white">
                                    <span v-if="u.status" class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" />
                                        <span>Active</span>
                                    </span>
                                    <span v-else class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                                        <span>Inactive</span>
                                    </span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap">{{ formatDate(u.createdAt) }}</td>
                            <td class="space-x-2 whitespace-nowrap">
                                <!-- <button @click.prevent="handleShowFormSetUserTask(user.id)"
                                    class=" space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-key" />
                                    <span>Permission</span>
                                </button> -->
                                <button @click="handleEditUserPopup(u)"
                                    class="text-yellow-700 space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                    Edit
                                </button>
                                <button @click="handleDeleteUserPopup(u)"
                                    :disabled="userAuthRole === 'Admin' && role === 'Admin'"
                                    :class="userAuthRole === 'Admin' && u.role === 'Admin' ? 'text-gray-300' : 'text-red-600'"
                                    class="space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                    Delete
                                </button>
                            </td>

                        </tr>

                    </tbody>
                    <tbody v-else>
                        <tr class="text-center bg-white ">
                            <td colspan="7" class="px-6 py-4 text-center whitespace-nowrap">
                                No data has been found!
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="usersData?.length > 0 && searchText === ''" class="flex justify-between items-center">
                <div class="text-lg px-1 flex space-x-2">
                    <span>Page {{ currentPage }} </span>
                    <span> of </span>
                    <span> {{ totalPages }} </span>
                </div>
                <div class="flex space-x-2 mt-4">
                    <button @click="loadPreviousPage" class="btn-paginate" :disabled="currentPage === 1">
                        <font-awesome-icon icon="fa-solid fa-chevron-left" />
                    </button>
                    <button class="btn-paginate-number">{{ currentPage }}</button>
                    <button @click="loadNextPage"
                        :disabled="totalPages === 0 || totalPages === 1 || currentPage === totalPages"
                        class="btn-paginate">
                        <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <component :is="currentComponent" @closeModal="unMountComponent" :docDelete="userDeleteData" :oldID="oldID"
        :lastImageURL="lastImageURL" :docUpdate="userEditData" :updateStatusID="updateStatusID"
        @handleSubmit="handleSubmit" @handleUpdate="handleUpdate" @handleLoadPreviousPage="handleLoadPreviousPage"
        @handleDelete="handleDelete">
    </component>
</template>

<script>
import BreadCrumbsVue from '@/components/admin/ui/BreadCrumbsVue.vue';
import UserModalVueVue from '@/components/admin/modal/UserModalVue.vue';
import { onMounted, ref, watch } from 'vue';
import { getCollectionQuery } from '@/composables/getCollection';
import getCollectionPaginate from "@/composables/getCollectionPaginate2"
import { push } from 'notivue';
import moment from "moment";
import { where, limit, orderBy, } from 'firebase/firestore'
import { projectAuth, onAuthStateChanged } from "@/firebase/config";

import DeleteUserConfirmationVue from '@/components/admin/modal/DeleteUserConfirmationVue.vue';

import UpdateStatusComfirmationModalVue from '@/components/admin/modal/UpdateStatusComfirmationModal.vue';




export default {
    components: {
        BreadCrumbsVue,
        UserModalVueVue, DeleteUserConfirmationVue,
        UpdateStatusComfirmationModalVue
    },
    setup() {

        const user = ref(null);
        const searchText = ref('');
        const currentPage = ref(1);
        const totalPages = ref(1);
        const pageSize = ref(10);
        const userAuth = ref(null);
        const userAuthRole = ref(null);
        const userAuthTask = ref({});
        const usersData = ref([]);
        const queryDocs = ref([]);
        const userDeleteData = ref({});
        const userEditData = ref({});
        const paginateData = ref([]);






        const currentComponent = ref("");
        const mountComponent = (component) => {
            currentComponent.value = component;
        }

        const unMountComponent = () => {
            currentComponent.value = '';
            user.value = null;
            userDeleteData.value = {};
            userEditData.value = {};

        }



        const fetchUserAuthentication = () => {
            onAuthStateChanged(projectAuth, async (_user) => {
                if (_user) {
                    userAuth.value = _user;
                    await getCollectionQuery("users", [where("email", "==", _user.email)], (data) => {
                        data.forEach((user) => {
                            userAuthRole.value = user.role;
                            userAuthTask.value = user.assignTask;
                        });
                    }, null, true);

                    console.log("userAuth Role", userAuthRole);
                }
            });
        }



        const fetchUserData = async () => {
            try {
                queryDocs.value = [
                    where("role", "in", ["Admin", "Client"]),
                    limit(pageSize.value),
                    orderBy("createdAt"),
                ];
                paginateData.value = getCollectionPaginate("users", queryDocs.value, async (data) => {
                    usersData.value = data;


                });

                const totalUsers = await paginateData.value.getCollectionLength("users", [where("role", "in", ["Admin", "Client"]),]);

                totalPages.value = Math.ceil(
                    totalUsers / pageSize.value
                );


            } catch (err) {
                console.log("Error Failed to fetch user data", err)
            }
        }








        const loadPreviousPage = async () => {
            try {
                const firstDocument = usersData.value[0];
                if (firstDocument) {
                    await paginateData.value.previousPage(firstDocument.id, pageSize.value);
                    currentPage.value -= 1;

                }
            } catch (err) {

                console.error("Error fetching previous page:", err);
            }
        };

        const loadNextPage = async () => {
            try {
                const lastDocument = usersData.value[usersData.value.length - 1]
                if (lastDocument) {
                    await paginateData.value.nextPage(lastDocument.id, pageSize.value);
                    currentPage.value += 1;

                }
            } catch (err) {
                console.error("Error fetching next page:", err);
            }
        };

        const handleDelete = async () => {
            const totalUsers = await paginateData.value.getCollectionLength("users", [where("role", "in", ["Admin", "Client"])]);
            totalPages.value = Math.ceil(
                totalUsers / pageSize.value
            );
            push.success('User has been deleted successfully!')
        }





        const handleSubmit = async () => {

            const totalUsers = await paginateData.value.getCollectionLength("users", [where("role", "in", ["Admin", "Client"])]);
            totalPages.value = Math.ceil(
                totalUsers / pageSize.value
            );
            push.success('User has been created successfully!');
        }

        const handleUpdate = async () => {
            const totalUsers = await paginateData.value.getCollectionLength("users", [where("role", "in", ["Admin", "Client"])]);
            totalPages.value = Math.ceil(
                totalUsers / pageSize.value
            );

            if (currentPage.value === 1) {
                await fetchUserData();
            }

            push.success('User has been updated successfully!')
        }


        const oldID = ref(null);
        const lastImageURL = ref(null);

        const handleDeleteUserPopup = async (doc) => {
            if (doc.status === true) {
                push.error(`Sorry, you can't delete user!`)
            } else {
                if (usersData.value.length <= 1) {
                    console.log("condition < 1 lenght")
                    oldID.value = doc.id;
                    lastImageURL.value = doc.imageURL
                } else {
                    console.log("condition has > 1 length")
                    userDeleteData.value = doc;
                    oldID.value = null;
                }
                mountComponent('DeleteUserConfirmationVue');
            }



        }




        const handleLoadPreviousPage = async () => {
            await fetchUserData();
            currentPage.value = 1;
            push.success("Users has been delete successfully!")
        }

        const handleEditUserPopup = (doc) => {
            userEditData.value = doc;
            mountComponent('UserModalVueVue')
        }


        const updateStatusID = ref(null);
        const handleUpdateStatusUserPopUp = (doc) => {

            if (userAuthRole.value === 'Admin' && doc.role === 'Admin') {
                return;
            } else {
                updateStatusID.value = doc;
                mountComponent('UpdateStatusComfirmationModalVue')
            }

        }


        watch(pageSize, async (newV) => {
            if (newV) {
                queryDocs.value = [
                    where("role", "in", ["Admin", "Client"]),
                    limit(Number(newV)),
                    orderBy("createdAt"),
                ];
                paginateData.value = getCollectionPaginate("users", queryDocs.value, async (data) => {
                    usersData.value = data;


                });

                const totalUsers = await paginateData.value.getCollectionLength("users", [where("role", "in", ["Admin", "Client"]),]);

                totalPages.value = Math.ceil(
                    totalUsers / pageSize.value
                );
            }
        })


        const handleSearchQueryUser = async () => {
            try {
                if (searchText.value) {
                    await getCollectionQuery("users", [where("usernameLowerCase", "==", searchText.value.toLowerCase()), where("role", "in", ["Admin", "Client"])], (data) => {
                        usersData.value = data;
                    }, null, false);
                } else {
                    fetchUserData();
                }
            } catch (err) {
                console.log("faile to search users", err);
            }
        };

        watch(searchText, async (newV) => {
            if (!newV) {
                await fetchUserData();
            }
        })




        //for format Date
        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MMM/YYYY h:mm A");
            } else {
                return "";
            }
        };


        onMounted(async () => {

            fetchUserAuthentication();
            fetchUserData();

        })

        return {
            lastImageURL,
            searchText,
            handleLoadPreviousPage,
            oldID,
            updateStatusID,
            handleUpdateStatusUserPopUp,
            userAuthRole,
            handleDelete,
            handleSearchQueryUser,
            handleUpdate,
            handleEditUserPopup,
            userEditData,
            userDeleteData,
            handleDeleteUserPopup,
            formatDate,
            loadPreviousPage,
            loadNextPage,
            usersData,

            currentPage,
            totalPages,
            pageSize,
            handleSubmit,
            currentComponent,
            mountComponent,
            unMountComponent
        }
    }
}
</script>

<style></style>