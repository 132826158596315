<template>
    <div class="grid gap-2 grid-cols-3">
        <div class=" relative z-30">
            <button type="button" @click="isShow = 1" class="form-control w-full bg-white">{{ year }}</button>
                <TransitionGroup tag="div" name="nested">
                    <div v-show="isShow == 1" :key="isShow">
                        <Transition>
                            <div v-if="yearsOption.length == 0 " class="absolute z-30 bg-white mt-2 min-w-[350px]  border border-quaternary-low-light rounded-lg">
                                <div class="p-2 text-center border-b border-quaternary-low-light">
                                       <p>Year</p>
                                </div>
                                <div class="grid gap-3 grid-cols-5 p-2">
                                    <div v-for="year in yearsRange" :key="year" 
                                        :class="activeYearRange.year_id == year.year_id && 'bg-secondary text-primary'" 
                                        class="cursor-pointer hover:bg-secondary hover:text-primary text-center rounded-lg font-medium"
                                        @click="handleAcitveYearRange(year)"
                                     >
                                        <p>{{ year.start }} <br/> {{ year.end }}</p>
                                    </div>
                                </div>
                                <div @click="handleCancelSelectYear" class="w-fit rounded-full cursor-pointer px-4 pt-2 pb-4">Cancel</div>
                            </div>
                        
                            <div v-else  class="absolute z-30 bg-white mt-2 max-w-fit min-w-[300px] border border-slate-300 p-4 rounded-md">
                                    
                                    <div class="relative mb-2">
                                        <div class="absolute top-0 left-0 w-fit cursor-pointer rounded-full" @click="clearYear">
                                            <font-awesome-icon icon="fa-solid fa-arrow-left" />
                                        </div>
                                        <div class="flex w-full space-x-2 justify-center">
                                            <button type="button" @click="loadPrevYear" :class="year_id == 1 && 'cursor-not-allowed'" :disabled="year_id == 1">
                                                <font-awesome-icon icon="fa-solid fa-angles-left" />
                                            </button>
                                            <span class="select-none">{{ activeYearRange.start }}-{{ activeYearRange.end }}</span>
                                            <button type="button" @click="loadNextYear" :class="year_id == 10 && 'cursor-not-allowed'" :disabled="year_id == 10">
                                                <font-awesome-icon icon="fa-solid fa-angles-right" />
        
                                            </button>
                                        </div>
                                    </div>

                                    <div class="grid gap-2 grid-cols-5 text-center">
                                            <span @click="handleActiveYear(y.year)"  v-for="y in yearsOption" :key="y">
                                                <p :class="year == y.year && 'bg-secondary text-primary rounded-md'">{{ y.year }}</p>
                                            </span>
                                    </div>

                                    <div class="flex justify-between mt-4">
                                        <div @click="handleCancelSelectYear" class="py-1 rounded-full cursor-pointer">Cancel</div>
                                        <div @click="handleSelectYear" class="btn btn-primary py-1 rounded-full cursor-pointer">Apply</div>
                                    </div>
                            </div>
                        </Transition>
                    </div>
                </TransitionGroup>
        </div>

        <div class="relative ">
            <button type="button" @click="isShow = 2" class="form-control bg-white w-full">{{ month.month_m4 }}</button>
            <TransitionGroup tag="div" name="nested" >
                <div v-show="isShow == 2" :key="isShow" class="mt-2 z-3 z-40 min-w-[200px] absolute">
                        <transition name="slide-fade">
                            <div class=" bg-white min-w-full  lg:min-w-[300px] max-w-fit border border-quaternary-low-light rounded-lg">
                                <div class="p-2 text-center border-b border-quaternary-low-light">
                                       <p>Month</p>
                                </div>
                                <div class="grid grid-cols-4 gap-3 p-2">
                                    <div v-for="m in monthoptions" :key="m.month_id">
                                        <span :class="m.month_id == month.month_id && 'bg-secondary text-primary'"
                                                class="cursor-pointer p-1 text-center rounded-md hover:bg-secondary hover:text-primary"
                                                @click="month = m"
                                        >
                                            {{ m.month_m3 }}
                                        </span>
                                    </div>    
                                </div>
                                <div class="flex justify-between space-x-2 p-2">
                                    <button type="button" @click="handleCancelSelectMonth" class="py-1 rounded-full">Cancel</button>  
                                    <button type="button" class="btn btn-primary py-1 rounded-full" @click="handleSelectMonth">Apply</button>  
                                </div>
                            </div> 
                        </transition>
                </div>
            </TransitionGroup>
            
        </div>

        <div class="relative">
            <button type="button" @click="isShow = 3" class="form-control bg-white w-full">{{ day }}</button>
            <TransitionGroup tag="div" name="nested" >
                <div v-show="isShow == 3" :key="isShow" class="mt-2 z-30 min-w-[250px] absolute right-0 md:left-0">
                        <transition name="slide-fade">
                            <div class=" bg-white  border border-quaternary-low-light rounded-lg">
                                <div class="p-2 text-center border-b border-quaternary-low-light">
                                       <p>Day</p>
                                </div>
                                <div class="w-full">
                                    <VueScrollPicker v-model="day" :options="dayoptions"/>
                                </div>
                                <div class="flex justify-between p-4">
                                    <button @click="handleCancelSelectDay" type="button"  class="py-1 rounded-full">Cancel</button>  
                                    <button @click="handleSelectDay" type="button" class="btn btn-primary py-1 rounded-full">Apply</button>  
                                </div>
                            </div> 
                        </transition>
                </div>
            </TransitionGroup>
            
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {onMounted, ref, watch } from 'vue';
export default {
    emits:['onEmitYear', 'onEmitMonth', 'onEmitDay'],
    setup(_, {emit}){
        
        // variable for year component 
        const isShow = ref(0);
        const yearsOption = ref([]);
        const activeYearRange = ref(null);
        const year_id = ref('');
        const year = ref(null);
        const currentYear = moment().year();
   
        let yearsRange = ref([
            { year_id: 1,  start: 1900, end: 1920},
            { year_id: 2,  start: 1921, end: 1940 },
            { year_id: 3,  start: 1941, end: 1960 },
            { year_id: 4,  start: 1961, end: 1980 },
            { year_id: 5,  start: 1981, end: 2000 },
            { year_id: 6,  start: 2001, end: 2020 },
            { year_id: 7,  start: 2021, end: 2040 },
            { year_id: 8,  start: 2041, end: 2060 },
            { year_id: 9,  start: 2061, end: 2080 },
            { year_id: 10, start: 2081, end: 2100 }
        ]); 

        // variable for month component
        const monthoptions = ref([]);
        const currentNoMonth = ref(null);
        const currentMonth = ref(null);
        const month = ref(null);
         
        
       
        monthoptions.value = [
            { month_m3: 'Jan', month_m4 : 'January', month_id: 0 },
            { month_m3: 'Feb', month_m4 : 'February', month_id: 1 },
            { month_m3: 'Mar', month_m4 : 'March', month_id: 2 },
            { month_m3: 'Apr', month_m4 : 'April', month_id: 3 },
            { month_m3: 'May', month_m4 : 'May', month_id: 4 },
            { month_m3: 'Jun', month_m4 : 'June', month_id: 5 },
            { month_m3: 'Jul', month_m4 : 'July', month_id: 6 },
            { month_m3: 'Aug', month_m4 : 'Augest', month_id: 7 },
            { month_m3: 'Sep', month_m4 : 'September', month_id: 8 },
            { month_m3: 'Oct', month_m4 : 'October', month_id: 9 },
            { month_m3: 'Nov', month_m4 : 'November', month_id: 10 },
            { month_m3: 'Dec', month_m4 : 'December', month_id: 11 }
        ];
        currentNoMonth.value = moment().month();
        currentMonth.value = monthoptions.value.find(m => m.month_id == +currentNoMonth.value)
        month.value = currentMonth.value;


        // variable for day
        const dayoptions = ref([]);
        const day = ref(null);
       day.value = moment().date();
          

        // function for year 
        const handleAcitveYearRange = (data) => {
            activeYearRange.value = data;
            yearsOption.value = [];
            let j = 0;
            for(let i = activeYearRange.value.start; i <= activeYearRange.value.end; i++){
                yearsOption.value.push({id: j, year: i})
                j++;
            }
        }

        const loadNextYear = () => {
                year_id.value = activeYearRange.value.year_id + 1;
                const data = yearsRange.value.find(i => i.year_id === year_id.value)
                handleAcitveYearRange(data)
        }

        const loadPrevYear = () => {
                year_id.value = activeYearRange.value.year_id - 1;
                const data = yearsRange.value.find(i => i.year_id === year_id.value)
                handleAcitveYearRange(data)   
        }

        const handleActiveYear = (data) => {
            year.value = data
        }

        const clearYear = () => {
            yearsOption.value = [];
        }

        const handleSelectYear = () => {
            emit('onEmitYear', year.value);
            isShow.value = 0;
        }

        const handleCancelSelectYear = () => {
            isShow.value = 0;
            year.value = currentYear;
            const data = yearsRange.value.find(y => (currentYear >= y.start && currentYear <= y.end));
            handleAcitveYearRange(data);
        }
        
        watch(() => {
            activeYearRange.value =  yearsRange.value.find(y => (currentYear >= y.start && currentYear <= y.end))
        })

        onMounted(() => {
            const data = yearsRange.value.find(y => (currentYear >= y.start && currentYear <= y.end));
            handleAcitveYearRange(data);
            year.value = currentYear;
        })


        // function for month
        const handleSelectMonth = () => {
            emit('onEmitMonth', month.value.month_id);
            isShow.value = 0;
        }
        const handleCancelSelectMonth = () => {
            currentNoMonth.value = moment().month();
            currentMonth.value = monthoptions.value.find(m => m.month_id == +currentNoMonth.value)
            month.value = currentMonth.value;
            isShow.value = 0;
        }

        // function for day
        function getDaysInMonth(m, y) {
            return new Date(y, m + 1, 0).getDate();
        }

        function populateDayOptions(selectedMonth, selectedYear) {
          let daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
            for (let i = 1; i <= daysInMonth; i++) {
                dayoptions.value.push(i);
            }
            return dayoptions.value;
        }

            // Populate day options for the current month and year
        populateDayOptions(month.value.month_id, year.value);

        const handleSelectDay = () => {
            emit('onEmitDay', day.value);
            isShow.value = 0;
        }
        const handleCancelSelectDay = () => {
            day.value = moment().date();
            isShow.value = 0;
        }


        // Watch for changes in year and month
        watch([year, month], ([newYear, newMonth]) => {
                if (newYear && newMonth !== undefined) {
                    dayoptions.value = [];
                    populateDayOptions(newMonth.month_id, newYear);
                }
        });

        onMounted(()=>{
            handleSelectDay();
            handleSelectMonth();
            handleSelectYear();
            
        })
        return{
            year,
            isShow,
            year_id,
            yearsRange,
            yearsOption,
            activeYearRange,
            month,
            monthoptions,
            currentMonth,
            day,
            dayoptions,
            loadNextYear,
            loadPrevYear,
            handleActiveYear,
            clearYear,
            handleSelectYear,
            handleAcitveYearRange,
            handleCancelSelectYear,
            handleSelectMonth,
            handleCancelSelectMonth,
            handleSelectDay,
            handleCancelSelectDay
        }
    }
}
</script>
