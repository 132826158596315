import { createRouter, createWebHistory } from 'vue-router';
import { projectAuth } from '../firebase/config';




// =========> Admin 
import testPage from  '../views/tests/testPage.vue'
import LoginPage from '@/views/LoginPage.vue';
import AdminLayout from '@/views/admin/AdminLayout.vue';
import AdminDashboard from '@/views/admin/AdminDashboard.vue';
import AdminCourse from '@/views/admin/AdminCourse.vue';
import AdminCourseCategory from '@/views/admin/AdminCourseCategory.vue';
import AdminCampanyInfo from '@/views/admin/AdminCampanyInfo.vue';
import AdminUser from '@/views/admin/AdminUser.vue';
import AdminUserSubmission from '@/views/admin/AdminUserSubmission.vue';
import AdminUserSubmissionReport from '@/views/admin/AdminUserSubmissionReport.vue';


const loginValid = async (from, to, next) => {
    if (projectAuth.currentUser) {

      next();
    } else {
      next('/login');
    }
  };
const routes = [

    {
      path: '/testPage',
      name: 'testPage',
      component: testPage
    },
  
    {
      path: '/login',
      name: 'login-page',
      component: LoginPage
    },
    
    {
       path: '/',
       component: AdminLayout,
       beforeEnter:loginValid,
       children:[
          {
             path: '/adminDashboard',
             name: 'admin-dashboard',
             component: AdminDashboard,
             
          },
          {
            path: '/admin/course-category',
            name: 'admin-course-category',
            component: AdminCourseCategory
          },
          {
            path: '/admin/course',
            name: 'admin-course',
            component: AdminCourse
          },
          {
            path: '/admin/company-info',
            name: 'admin-company-info',
            component: AdminCampanyInfo
          },
          {
            path: '/admin/user',
            name: 'admin-user',
            component: AdminUser
          },
          {
            path: '/admin/user-submission',
            name: 'admin-user-submission',
            component: AdminUserSubmission
          },
          {
            path: '/admin/user-submission-report',
            name: 'admin-user-submission-report',
            component: AdminUserSubmissionReport
          }
       ]
    },
  
   
  ]


const routerAdmin = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      return new Promise((resolve) => {
        resolve({ left: 0, top: 0 })
      })
    }
  })

  export default routerAdmin
