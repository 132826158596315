<template>
    <div class="flex flex-col min-h-screen">
        <div class="mb-[70px] md:mb-[0px]">
            <NavbarVue/>
        </div>
        <div class="font-notoKhmer">
            <router-view v-slot="{Component}">
                <transition name="fade" mode="out-in">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </div>

        <div class="mt-auto">
            <FooterVue/>
        </div>
    </div>
</template>

<script>
import NavbarVue from '@/components/NavbarVue.vue';
import FooterVue from '@/components/FooterVue.vue';
export default{
  components:{
     NavbarVue,
     FooterVue
  },
  setup(){

  }
}
</script>