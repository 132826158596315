<template>
    <div class="bg-white md:h-[110px] md:bg-secondary font-notoSans">
            <div class="hidden md:flex w-[90%] lg:max-w-[1080px] mx-auto h-full items-center justify-between">
                    <router-link to="/" class="w-[150px]">
                        <img class="w-full h-full" src="../assets/logo.png" alt="">
                    </router-link>
                    <nav class="text-quaternary font-notoSans text-[18px]">
                            <ul class="flex list-none font-medium sm:space-x-8 md:space-x-10 lg:space-x-14 xl:space-x-20">
                                <li class="relative">
                                    <router-link to="/" 
                                        :class="$route.name == 'home-page' && 'text-tertiary'"
                                        class="hover:text-tertiary text-quaternary transition">Home</router-link>
                                    <div :class="$route.name == 'home-page' && 'navbar-active'"></div>
                                </li>
                                <li class="relative group">
                                    <div 
                                      class="hover:text-tertiary transition"
                                      :class="($route.name == 'all-course-category-page' || $route.name == 'by-course-category-page') && 'text-tertiary'"
                                      >Courses</div>
                                      
                                      <div  
                                        :class="($route.name == 'all-course-category-page' || $route.name == 'by-course-category-page') && 'navbar-active'"
                                        
                                    >
                                    </div>
                                    <div class="min-w-[80px] h-20 bg-transparent absolute top-7"></div>
                                    <div class="hidden z-50 divide-y-[1px] divide-white group-hover:flex flex-col absolute rounded-b-lg top-10 min-w-fit shadow overflow-hidden bg-secondary text-black">
                                            
                                        <router-link :to="{ name: 'all-course-category-page', params: { allCourse: 'allCourse' } }"  class="px-4 cursor-pointer py-2 text-nowrap text-base text-quaternary hover:bg-white hover:text-tertiary" >All Course Category</router-link>
                                
                                        <router-link v-for="c in  courseCategory" :key="c.id"  :to="{ name: 'by-course-category-page', params: { id: c.id } }"  class="px-4 cursor-pointer py-2 text-nowrap text-base text-quaternary hover:bg-white hover:text-tertiary" >{{ c.categoryName }}</router-link>
                                                                   
                                    </div>
                                </li>
                                <li class="relative">
                                    <router-link to="/about-page" 
                                    :class="$route.name == 'about-page' && 'text-tertiary'"
                                    class="hover:text-tertiary transition text-quaternary" >About</router-link>
                                    <div :class="$route.name == 'about-page' && 'navbar-active'"></div>   
                                </li>
                                <li class="relative">
                                    <router-link to="/ContactPage" 
                                    :class="$route.name == 'contact-page' && 'text-tertiary'"
                                    class="hover:text-tertiary transition text-quaternary">Contact</router-link>
                                    <div :class="$route.name == 'contact-page' && 'navbar-active'"></div>
                                </li>

                                <li class="relative group">
                                    <div class="hover:text-tertiary transition"></div>
                                    <div 
                                      class="hover:text-tertiary transition"
                                      :class="$route.name == 'calculate-bazi-page' && 'text-tertiary'"
                                      >Feng Shui Tool</div>
                                      
                                      <div  
                                       :class="$route.name == 'calculate-bazi-page' && 'navbar-active'"></div>
                                    <div class="min-w-[80px] h-20 bg-transparent absolute top-7"></div>
                                    <div class="hidden group-hover:flex flex-col absolute rounded-b-lg top-10 min-w-fit shadow overflow-hidden bg-secondary text-black">      
                                        <router-link to="/calculate-bazi"  class="px-4 divide-y-[1px] divide-white cursor-pointer py-2 text-base text-quaternary hover:bg-white hover:text-tertiary text-nowrap" >Calculate Bazi</router-link>  
                                    </div>
                                </li>
                            </ul>
                    </nav>
            </div>

            <div class="md:hidden bg-white  shadow-sm fixed top-0 w-full left-0 z-50">
                <div class="relative max-w-full flex px-8 h-[70px] justify-between items-center">
                        <div @click="toggleSidebar" class="text-primary cursor-pointer">
                            <span v-if="isOpenSidebar">
                                <font-awesome-icon icon="fa-solid fa-xmark" size="xl" />
                            </span>
                            <span v-else>
                                <font-awesome-icon icon="fa-solid fa-bars" size="xl"/>
                            </span>
                        </div>
                        <img class="w-[100px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" src="../assets/logo.png">
                </div>  

                <ul :class="isOpenSidebar && 'w-[250px]'" class="z-50 w-0 list-none overflow-hidden shadow-md flex flex-col transition-all absolute top-[70px] -left-0 space-y-4 py-4 text-[18px] h-screen bg-secondary">
                    <li class="relative font-semibold pl-[30px]">
                         <span @click="closeMenu">
                            <router-link  to="/" :class="$route.name == 'home-page' && 'text-tertiary'" class="hover:text-tertiary text-primary transition">Home</router-link>
                         </span>
                    </li>
                    <li class="relative font-semibold pl-[30px] group">
                        <span @click="closeMenu">
                            <router-link to="/all-course-category/allCourse" :class="$route.name == 'all-course-category-page' && 'text-tertiary'" class="hover:text-tertiary text-primary transition">Courses</router-link>                                
                        </span>
                    </li>
                    <li class="relative font-semibold pl-[30px]">
                        <span @click="closeMenu">
                            <router-link to="/about-page" :class="$route.name == 'about-page' && 'text-tertiary'" class="hover:text-tertiary text-primary transition">About</router-link>
                        </span>
                        
                    </li>
                    <li class="relative font-semibold pl-[30px]">
                        <span @click="closeMenu">
                        <router-link to="/ContactPage" :class="$route.name == 'contact-page' && 'text-tertiary'" class="hover:text-tertiary text-primary transition">Contact</router-link>
                        </span>
                    </li>

                    <li class="relative font-semibold pl-[30px] group">
                            <div class="hover:text-tertiary text-primary transition">Feng Shui Tool</div>
                            <div class="min-w-[80px] h-20 bg-transparent absolute top-7"></div>
                            <div class="hidden group-hover:flex flex-col absolute rounded-b-lg py-1.5 top-10 min-w-fit shadow overflow-hidden bg-secondary text-black">      
                                <span @click="closeMenu">
                                    <router-link to="/calculate-bazi"  class="px-4 divide-y-[1px] divide-white cursor-pointer text-base text-quaternary hover:bg-white hover:text-tertiary text-nowrap" >Calculate Bazi</router-link>  
                                </span>
                            </div>
                    </li>
                </ul>
            </div>
    </div>
</template>

<script>
import { getCollectionQuery } from '@/composables/getCollection';
import { onMounted, ref } from 'vue'
import { orderBy, where } from 'firebase/firestore';
export default {
    setup(){
        const isOpenSidebar = ref(false);
        const toggleSidebar = () => {
            isOpenSidebar.value = !isOpenSidebar.value;
        }

        const closeMenu = () => {
             isOpenSidebar.value = false;
        }
        

        const courseCategory=ref([]);
        const fetchCategory=async()=>{
            try{
                await getCollectionQuery("course-categories",[where("status","==",true),orderBy("createdAt")],(data)=>{
                    courseCategory.value=data;
                },null,true);
            }catch(err){
                console.log("failed to fetch data",err);
            }
        }

        onMounted(()=>{
            fetchCategory();
        })

        return{
            courseCategory,
            isOpenSidebar,
            toggleSidebar,
            closeMenu
        }
    }
}
</script>

<style>

</style>