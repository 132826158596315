<template>
    <div class="animate-fade-up animate-duration-300 font-poppins">
        <BreadCrumbsVue path="Course" />

        <div class="flex justify-between items-center">
            <h1 class="font-semibold text-xl md:text-2xl">Course Page</h1>
            <button @click="mountComponent('CourseModalVue')" class="btn btn-primary text-lg rounded-full">
                + Add
            </button>
        </div>

        <div class="my-2">
            <div class="flex justify-between items-center mt-4 mb-2">
                <select v-model="pageSize"
                    class="px-[20px] py-[8px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
                    <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
                </select>

                <div class="relative">
                    <input v-model="searchText" type="text" class="form-control min-w-[400px] py-[8px] rounded-xl"
                        placeholder="Search by title">
                    <span @click="handleSearchQueryCourse" class="btn-search">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass" size="lg" />
                    </span>
                </div>
            </div>
            <div class="relative overflow-x-auto">
                <table class="w-full text-base border-primary text-left">
                    <thead class="uppercase">
                        <tr
                            class="[&>*]:border [&>*]:p-2 [&>*]:border-primary bg-primary b font-semibold text-white uppercase">

                            <td class=" whitespace-nowrap">Title</td>
                            <!-- <td class=" whitespace-nowrap">Category</td> -->
                            <td class=" whitespace-nowrap">Price</td>
                            <td class=" whitespace-nowrap">Discount</td>
                            <!-- <td class=" whitespace-nowrap">Teacher name</td> -->
                            <td class=" whitespace-nowrap">Start Date</td>
                            <td class=" whitespace-nowrap">End Date</td>
                            <!-- <td class=" whitespace-nowrap">Description</td> -->
                            <td class=" whitespace-nowrap">Status</td>
                            <!-- <td class=" whitespace-nowrap">Created Date</td> -->
                            <td class=" whitespace-nowrap">Action</td>
                        </tr>
                    </thead>
                    <tbody v-if="courses?.length > 0">
                        <tr v-for="(doc, i) in courses" :key="i"
                            class="[&>*]:border-2 [&>*]:p-2  [&>*]:border-primary text-tertiary">

                            <td @click="handleShowCourseInfo(doc)" class="whitespace-nowrap cursor-pointer">{{ doc.title
                                }}</td>
                            <!-- <td class="whitespace-nowrap">{{ formatCategoryName(doc.categoryID) }}</td> -->
                            <td @click="handleShowCourseInfo(doc)"
                                class="whitespace-nowrap cursor-pointer text-red-600 font-semibold">${{ doc.price }}
                            </td>
                            <td @click="handleShowCourseInfo(doc)"
                                class="whitespace-nowrap cursor-pointer text-red-600 font-semibold">{{ doc.discount }}%
                            </td>
                            <td @click="handleShowCourseInfo(doc)" class="whitespace-nowrap cursor-pointer">{{
                formatDate(doc.startDate) }}</td>
                            <td @click="handleShowCourseInfo(doc)" class="whitespace-nowrap cursor-pointer">{{
                formatDate(doc.endDate) }}</td>
                            <!-- <td class="whitespace-nowrap">{{ doc.teacherName }}</td> -->
                            <!-- <td class="whitespace-nowrap">{{ doc.description }}</td> -->
                            <td class="whitespace-nowrap cursor-pointer" @click="handleUpdateStatusPopup(doc)">
                                <div :class="doc.status ? 'bg-green-600' : 'bg-red-600'"
                                    class="px-1.5 w-fit rounded-full text-white">
                                    <span v-if="doc.status" class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-check" />
                                        <span>Active</span>
                                    </span>
                                    <span v-else class="flex space-x-1 items-center">
                                        <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                                        <span>Inactive</span>
                                    </span>
                                </div>
                            </td>
                            <!-- <td class="whitespace-nowrap">{{ formatDate(doc.createdAt) }}</td> -->
                            <td class="space-x-4 whitespace-nowrap">
                                <button @click="handleEditCourse(doc)"
                                    class="text-yellow-700 space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                    Edit
                                </button>
                                <button @click="handleDeleteCourse(doc)"
                                    class="text-red-600 space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon icon="fa-solid fa-trash" />
                                    Delete
                                </button>

                                <button @click="handleUpdateOutStanding(doc)"
                                    class="text-blue-600 space-x-1 font-medium hover:underline whitespace-nowrap">
                                    <font-awesome-icon 
                                        icon="fa-regular fa-square" v-if="doc.isOutStanding===false " class=" " />
                                    <font-awesome-icon v-else-if="doc.isOutStanding === true"
                                        icon="fa fa-check-square" class="" />
                                        Outstanding
                                      
                                    
                                </button>


                            </td>

                        </tr>

                    </tbody>
                    <tbody v-else>
                        <tr class="text-center bg-white [&>*]:border [&>*]:p-2 [&>*]:border-primary">
                            <td colspan="11" class="px-6 py-4 text-center whitespace-nowrap">
                                No data has been found!
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="documents?.length > 0 && searchText === ''">
                <PaginationVue :pageSize="pageSize" :loadLastID="loadLastID" :loadLastImageURl="loadLastImageURl"
                    :isAddSuccess="isAddSuccess" :commitLoadTotalPage="commitLoadTotalPage"
                    @onEmitDataFromPagination="handleListenToPagination" />
            </div>
        </div>
    </div>
    <component :updateStatusID="updateStatusID" :updateOutStanding="outStandingData" :isAddSuccess="isAddSuccess" :is="currentComponent" :lastID="lastID"  :doc="course"
        @handleDeleteSuccess="handleDeleteCourseSuccess" @onDeletelastID="handleDeletelastID"
        @handleUpdateSuccess="handleUpdateSuccess" @handleAddSuccess="handleAddCourseSuccess"
        @closeModal="unMountComponent" />

</template>

<script>
import BreadCrumbsVue from '@/components/admin/ui/BreadCrumbsVue.vue';
import CourseModalVue from '@/components/admin/modal/CourseModalVue.vue';
import DeleteCourseComfirmationVue from '@/components/admin/modal/DeleteCourseComfirmationVue.vue';
import ShowCourseInfoModalVue from '@/components/admin/modal/ShowCourseInfoModalVue.vue';
import PaginationVue from "@/components/admin/PaginationVue.vue";
import UpdateStatusOutStanding from "@/components/admin/modal/UpdateStatusOutStanding.vue"

import getCollectionPaginate from "@/composables/getCollectionPaginate";
import moment from 'moment';
import { ref, watch } from 'vue';
import { push } from 'notivue';
import UpdateStatusComfirmationModalVue from '@/components/admin/modal/UpdateStatusComfirmationModal.vue';
import { getCollectionQuery } from '@/composables/getCollection';
import { orderBy, where, limit } from 'firebase/firestore';

export default {
    components: {
        BreadCrumbsVue,
        CourseModalVue,
        DeleteCourseComfirmationVue,
        ShowCourseInfoModalVue,
        UpdateStatusComfirmationModalVue,
        PaginationVue, 
        UpdateStatusOutStanding
    },
    setup() {
        const courses = ref([]);
        const course = ref(null);
        const currentComponent = ref("");
        const currentPage = ref(1);
        const totalPage = ref(1);
        const pageSize = ref(20);
        const searchText = ref('');
        const imageURL = ref(null);


        const lastID = ref(null);

        const { documents } = getCollectionPaginate("courses", [
            orderBy("createdAt"),
            limit(pageSize.value),
        ]);

        watch(documents, () => {
            courses.value = documents.value || [];
        });

        const handleListenToPagination = (items) => {
            courses.value = items;
        };



        const isAddSuccess = ref(null);
        const handleAddCourseSuccess = async () => {
            isAddSuccess.value = true;
            push.success("Course has been add successfully");
        };

        const handleUpdateSuccess = async () => {
            push.success("Course  has been update successfully");
        };

        const commitLoadTotalPage = ref(false);
        const handleDeleteCourseSuccess = () => {
            try {

                commitLoadTotalPage.value = true;
                push.success("Course has been deleted successfully !");
            } catch (err) {
                console.error("Error handling delete operation:", err);
            }
        };

        const loadLastID = ref(null);
        const loadLastImageURl = ref(null);
        const handleDeletelastID = () => {
            loadLastID.value = lastID.value;
            loadLastImageURl.value = imageURL.value;
     

        }




        const handleSearchQueryCourse = async () => {
            try {
                if (searchText.value) {
                    await getCollectionQuery("courses", [where("titleLowerCase", "==", searchText.value.toLowerCase())], (data) => {
                        courses.value = data;
                    },null, false);
                } else {
                    courses.value = documents.value || [];
                }
            } catch (err) {
                console.log("faile to search users", err);
            }
        };




        watch(searchText, async (newV) => {
            if (!newV) {
                courses.value = documents.value || [];
            }
        });


        const mountComponent = (component) => {
            currentComponent.value = component;
        }

        const unMountComponent = async () => {
            currentComponent.value = '';
            course.value = null;

        }




        //for format Date
        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MM/YYYY");
            } else {
                return "";
            }
        };




        const outStandingData=ref({});
        const handleUpdateOutStanding=(doc)=>{
            outStandingData.value=doc;
            mountComponent('UpdateStatusOutStanding')
        }




        const handleDeleteCourse = (doc) => {
            if(doc.status===true){
                push.error(`Sorry, you can't delete ${doc.title} course`);
            }else{
                 if (courses.value.length <= 1) {
                console.log("condition courses <=1", courses);
                lastID.value = doc.id;
                imageURL.value = doc.imageUrl;
                mountComponent('DeleteCourseComfirmationVue');

            } else {
                mountComponent('DeleteCourseComfirmationVue');

                course.value = doc;
                imageURL.value = null

                lastID.value = null;
            }

            }
           
           
        }


        const handleEditCourse = (doc) => {
            course.value = doc;
            mountComponent('CourseModalVue');

        }

        const handleShowCourseInfo = (doc) => {
            course.value = doc;
            mountComponent("ShowCourseInfoModalVue");
        }

        const updateStatusID = ref(null);
        const handleUpdateStatusPopup = (doc) => {
            updateStatusID.value = doc;
            mountComponent("UpdateStatusComfirmationModalVue");
        }




        return {
            handleUpdateOutStanding,
            outStandingData,

            loadLastImageURl,
            documents,
            handleAddCourseSuccess,
            isAddSuccess,
            handleUpdateSuccess,
            handleDeletelastID,
            loadLastID,
            handleSearchQueryCourse,
            commitLoadTotalPage,
            handleDeleteCourseSuccess,
            handleListenToPagination,
            lastID,
            updateStatusID,
            handleUpdateStatusPopup,
            currentComponent,
            courses,
            course,
            pageSize,
            currentPage,
            totalPage,
            searchText,
            mountComponent,
            unMountComponent,
            formatDate,
            handleDeleteCourse,
            handleEditCourse,
            handleShowCourseInfo
        }
    }
}
</script>

<style></style>