import { projectFirestore } from "@/firebase/config";
import {
  collection,
  getCountFromServer,
  onSnapshot,
  query,
  limit,
  startAfter,
  getDoc,
  doc,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import { ref, watchEffect } from "vue";

const getCollectionPaginate = (pathCollection, queryDocs) => {
  const error = ref(null);
  const documents = ref([]);

  let querySnapshot;
  let previousPage;
  let nextPage;
  let unsubscribe = () => {};
  const collectionRef = collection(projectFirestore, pathCollection);

  
  // ============= query data =============
  querySnapshot = query(collectionRef, ...queryDocs);

  // ============= Function previousPage =============
  previousPage = async (page, newLimited) => {
    try {
      let firstVisible = await getDoc(
        doc(projectFirestore, pathCollection, page)
      );
      console.log("first Vis",firstVisible)
  

      querySnapshot = query(
        collectionRef,
        ...queryDocs,
        endBefore(firstVisible),
        limitToLast(newLimited)
      );
      getData();
      return documents;
    } catch (err) {
      return err.message;
    }
  };


  
  // ============= Function nextPage =============
  nextPage = async (page, newLimited) => {
    try {
      let firstVisible = await getDoc(
        doc(projectFirestore, pathCollection, page)
      );
      querySnapshot = query(
        collectionRef,
        ...queryDocs,
        startAfter(firstVisible),
        limit(newLimited)
      );
      getData();
      return documents.value;
    } catch (err) {
      return err.message;
    }
  };

  //get collection length from server
  const getCollectionLength = async (collectioName, condition) => {
    const colRef = collection(projectFirestore, collectioName);
    const q = query(colRef, ...condition);
    const res = await getCountFromServer(q);
    return res.data().count;
  };

  //query snapshot all documents in collection
  const getData = () => {
    unsubscribe = onSnapshot(
      querySnapshot,
      { includeMetadataChanges: true },
      
      (snapshot) => {
     
        const data = [];
        snapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
          
        });
       

        documents.value = data;
        error.value = null;
      },
      (err) => {
        error.value = err.message;
        documents.value = null;
      }
    );
  };
 

  getData();

  // Automatically unsubscribe when component is unmounted
  watchEffect((onInvalidate) => {
    onInvalidate(() => unsubscribe());
  });

  return {

  
    documents,
    getData,
    error,
    previousPage,
    nextPage,
    getCollectionLength,
  };
};

export default getCollectionPaginate;
