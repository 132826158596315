<template>
    <div class="hidden-print font-noto text-base md:text-lg select-none relative h-full shadow-sm transition-all duration-150 ease-linear"
        :class="toggle ? 'w-[60px] duration-300 bg-primary': 'bg-white w-[300px]'"   
    >
        <div v-if="!toggleSideBar">
            <AdminDesktopSidebarVue />
        </div>

        <div v-else>
            <AdminDesktopSmallSidebarVue />
        </div>
    </div>


    <!-- =============== For Mobile Menu =================== -->

    <!-- <div class="hidden-print font-system text-base absolute top-[72px] left-0 h-full overflow-hidden bg-white shadow-md transition-all duration-150 ease-linear block xl:hidden" 
        :class="toggle? 'w-[300px]': 'w-0'"
    >
       <AdminTabletSidebarVue />
    </div> -->
</template>

<script>
    import {ref, watchEffect} from 'vue';
    import AdminDesktopSidebarVue from './AdminDesktopSidebarVue.vue';
    import AdminDesktopSmallSidebarVue from './AdminDesktopSmallSidebarVue.vue';
    // import AdminTabletSidebarVue from './AdminTabletSidebarVue.vue';
    export default{
        props: ["toggleSideBar"],
        components: {
                AdminDesktopSidebarVue,
                AdminDesktopSmallSidebarVue,
                // AdminTabletSidebarVue
        },
        setup(props){
            const toggle = ref(null);
            watchEffect(() => {
                toggle.value = props.toggleSideBar;
            })
            return {
                toggle
            }
        }
    }
</script>

<style scoped>
   @media print {
    .hidden-print {
        display: none;
    }

    .print-header {
        font-size: 20px;
        line-height: 1.5rem
            /* 24px */
        ;
    }

    #printTable {
        visibility: visible;
        position: absolute;
        font-size: 1rem
            /* 16px */
        ;
        line-height: 1.5rem
            /* 24px */
        ;
        left: 0;
        top: 0;
    }
}
</style>