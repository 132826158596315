<template>
  <div class="flex justify-between items-center">
    <div class="text-base px-1">
      <span>Page {{ currentPage }} of {{ totalPage }}</span>
    </div>
    <div class="flex space-x-2 mt-4">
      <button @click="loadPreviousPage" :disabled="currentPage === 1" class="btn-paginate">
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </button>
      <button class="btn-paginate-number">{{ currentPage }}</button>
      <button @click="loadNextPage" :disabled="currentPage === totalPage" class="btn-paginate">
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
import getCollectionPaginate from "@/composables/getCollectionPaginate";
import { limit, orderBy } from "firebase/firestore";
import { onMounted, ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router";
import useCollection from '@/composables/useDocument';
import { push } from 'notivue';
import useStorage from '@/composables/useStorage';
export default {
  props: ["pageSize", "isAddSuccess", "loadLastID", "loadLastImageURl", "commitLoadTotalPage"],
  emits: ["onEmitDataFromPagination"],
  setup(props, { emit }) {
    const pageSize = ref(props.pageSize);
    const currentPage = ref(1);
    const totalPage = ref(0);
    const collectionLength = ref(0);
    const items = ref([]);

    let path = "";
    let query = [];

    const route = useRoute();

    switch (route.path) {
      case "/admin/course-category":
        path = "course-categories";
        break;
      case "/admin/course":
        path = "courses";
        break;
    }

    if (path === "course-categories") {
      query = [limit(Number(pageSize.value)), orderBy("createdAt")]
    } else if (path === "courses") {
      query = [limit(Number(pageSize.value)), orderBy("createdAt")]
    }

    const { documents, previousPage, nextPage, getCollectionLength } =
      getCollectionPaginate(path, query);

    onMounted(() => {
      getTotalPage();
    });

    watch(documents, async() => {
      
      if (documents.value?.length > 0) {
        items.value = documents.value || [];
        emit("onEmitDataFromPagination", items.value);

      }
     await getTotalPage();
    });

    watch(items, async () => {
      await getTotalPage();
      totalPage.value = Math.ceil(
        collectionLength.value / pageSize.value
      );
      emit("onEmitDataFromPagination", items.value);
    });

    watch(pageSize, async (newV) => {
      if (newV) {
        if (path === "course-categories") {
          query = [limit(Number(newV)), orderBy("createdAt")]

          const { documents } = getCollectionPaginate(path, [
            orderBy("createdAt"),
            limit(newV),
          ]);

          watch(documents, () => {
            items.value = documents.value;
          });
          totalPage.value = Math.ceil(
            collectionLength.value / newV
          );
        } else if (path === "courses") {
          query = [limit(Number(newV)), orderBy("createdAt")]

          const { documents } = getCollectionPaginate(path, [
            orderBy("createdAt"),
            limit(newV),
          ]);

          watch(documents, () => {
            items.value = documents.value;
          });
          totalPage.value = Math.ceil(
            collectionLength.value / newV
          );
        }

      }
    })



    const lastDocumentID = ref(null);
    const lastImageURL=ref(null);

    const commitLoadTotalPage = ref(null);
    const isAddSuccess = ref(null);
    watchEffect( () => {
      if (props.pageSize) {
        pageSize.value = props.pageSize;
      }

      if (props.isAddSuccess) {
        isAddSuccess.value = props.isAddSuccess;


      }
      if (props.loadLastID) {
        lastDocumentID.value = props.loadLastID;
      }

      if(props.loadLastImageURl){
        lastImageURL.value=props.loadLastImageURl;
  

      }

      if (props.commitLoadTotalPage) {
        commitLoadTotalPage.value = props.commitLoadTotalPage;

      }


    });

    watch(isAddSuccess, async () => {
      await getTotalPage();

    })

   const { removeImage } = useStorage();
    watch(lastImageURL,async()=>{
      console.log("last Image URL",lastImageURL)
      if (lastImageURL.value) {
            const fileName=ref(null);
            const cleanURL = lastImageURL.value.split('?')[0];
              const decodedURL = decodeURIComponent(cleanURL);
              fileName.value = decodedURL.split('/').pop();
              const storagePath = `courses/${fileName.value}`;
              await removeImage(storagePath);
          
          }
    })

 
    watch(lastDocumentID, async () => {
      if (currentPage.value != 1) {
        await loadPreviousPage();
        const { removeDoc } = useCollection(path);
        await removeDoc(lastDocumentID.value); 
        await getTotalPage();
        if (path === 'course-categories') {
          push.success("Course Categories has been delete successfully")
        }else if(path === 'courses'){
          push.success("Course  has been delete successfully")
        }
      } else {
        const { removeDoc } = useCollection(path);
        await removeDoc(lastDocumentID.value);
        await getTotalPage();
        if (path === 'course-categories') {
    
          push.success("Course Categories has been delete successfully")
        }else if(path === 'courses'){

    
          push.success("Course  has been delete successfully")
        }
      }


    })

    watch(currentPage, async (newV) => {
      if (newV === 1) {
        await getTotalPage();
      }
    })

    watch(commitLoadTotalPage, async () => {
      await getTotalPage();
    })


    const loadPreviousPage = async () => {
      try {
        const firstDocument = items.value[0];
     

        if (firstDocument) {

          items.value = await previousPage(firstDocument.id, pageSize.value);
          currentPage.value--;

        }
      } catch (err) {
        console.error("Error fetching previous page:", err);
      }
    };

    async function loadNextPage() {
      try {
        const lastDocument = items.value[items.value.length - 1];

        if (lastDocument) {

          items.value = await nextPage(lastDocument.id, pageSize.value);
          currentPage.value++;
        }
      } catch (err) {
        console.error("Error fetching next page:", err);
      }
    }

    async function getTotalPage() {
      collectionLength.value = await getCollectionLength(path, []);
      totalPage.value = Math.ceil(collectionLength.value / pageSize.value);

    }

    return { loadPreviousPage, loadNextPage, currentPage, totalPage };
  },
};
</script>

<style></style>
