<template>
        <div class="bg-secondary-light text-tertiary py-4">
                <div class="max-w-[90%] xl:max-w-[1000px] mx-auto">
                        <h2 class="text-sm lg:text-[18px] font-medium space-x-1 md:py-4">
                                <router-link class="text-quaternary"
                                        :to="{ name: 'all-course-category-page', params: { allCourse: 'allCourse' } }">Course</router-link>
                                <span class="text-quaternary">/</span>
                                <router-link to="" class="text-quaternary cursor-default">{{ formatCategoryName(cateID)
                                        }}</router-link>
                                <span class="text-quaternary">/</span>
                                <span class="text-tertiary">{{ courseDetailData.title }}</span>
                        </h2>

                        <div class="xl:max-w-[900px] mx-auto my-4">

                                <h3 class="py-4 text-[18px] text-center md:text-left md:text-[24px] font-semibold">
                                        {{ courseDetailData.title }}</h3>

                                <div class="grid grid-cols-1 md:grid-cols-3 gap-5 ">
                                        <div class="sm:col-span-2 overflow-hidden rounded-2xl">
                                                <img class=" h-[400px] w-full "
                                                        :src="courseDetailData.imageUrl" alt="">
                                        </div>
                                        <div
                                                class="rounded-2xl grid gap-4 place-content-start grid-cols-2 md:grid-cols-1 overflow-hidden p-8 bg-white">
                                                <div class="mb-3">
                                                        <p class="text-base md:text-lg text-quaternary">Teacher</p>
                                                        <h3 class="text-lg md:text-[24px] font-medium ">{{
                                        courseDetailData.teacherName }}</h3>
                                                </div>
                                                <div class="mb-3">
                                                        <p class="text-base md:text-lg text-quaternary">Start Class</p>
                                                        <h3 class="text-lg md:text-[24px]  font-medium">{{courseDetailData.startDate ?
                                        formatDate(courseDetailData.startDate)  : ""}}</h3>
                                                </div>
                                                <div>
                                                        <p class="text-base md:text-lg text-quaternary">End Class</p>
                                                        <h3 class="text-lg md:text-[24px]  font-medium">{{courseDetailData.endDate ? 
                                        formatDate(courseDetailData.endDate) : ""}}</h3>
                                                </div>
                                                <div class="mb-3">
                                                        <p class="text-base md:text-lg text-quaternary">Status</p>
                                                        <h3 class="text-lg md:text-[24px]  font-medium">{{
                                        courseDetailData.status ? 'Active' : 'Inactive' }}</h3>
                                                </div>
                                                <div class="mb-3">
                                                        <p class="text-base md:text-lg text-quaternary">Category</p>
                                                        <h3 class="text-lg md:text-[24px]  font-medium">{{
                                        formatCategoryName(cateID) }}</h3>
                                                </div>
                                        </div>
                                </div>

                                <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 my-5">
                                        <div class="lg:col-span-2">
                                                <div class="text-base lg:text-lg w-full" v-html="courseDetailData?.description"></div>
                                                
                                                <div class="mt-8 space-y-2 text-quaternary">
                                                        <p>Join our course here</p>
                                                        <button @click="handleOpenModal" class="btn btn-primary rounded-full py-2 px-[40px] cursor-pointer">Join</button>
                                                        <router-link to="/ContactPage" class=" text-quaternary block hover:underline hover:decoration-quaternary">or meet our service for more information</router-link>
                                                </div>
                                        </div>

                                        <div class="rounded-2xl overflow-hidden">
                                                <div
                                                        class="text-center py-3 font-medium text-base md:text-lg bg-secondary">
                                                        More Course
                                                </div>

                                                <div class="">
                                                        <div>
                                                                <SmallCardVue :moreCourse="moreCourse">
                                                                </SmallCardVue>
                                                        </div>
                                                </div>
                                        </div>
                                </div>

                                <div class="my-20">
                                        <h4 class="text-[16px] sm:text-[24px]">You can view more Category</h4>

                                        <div class="flex flex-wrap gap-4 my-4">
                                                <button v-for="c in filteredCategories" :key="c.id"
                                                        @click="showCourseByCate(c.id)" class="btn-outline">{{
                                                        c.categoryName }}</button>

                                        </div>
                                </div>
                        </div>
                        <JoinCourseModal :isOpen="isOpenModal" :doc="courseDetailData" @closeModal="() => {isOpenModal = false}" />
                </div>
        </div>


</template>

<script>
// import CardVue from '@/components/CardVue.vue';
import SmallCardVue from '@/components/SmallCardVue.vue';
import JoinCourseModal from '@/components/JoinCourseModal.vue'
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch, } from 'vue';
import { getCollectionQuery } from '@/composables/getCollection';
import { limit, orderBy, where } from 'firebase/firestore';
import moment from "moment";
export default {
        components: {
                SmallCardVue,
                JoinCourseModal
        },
        setup() {

                const route = useRoute();
                const router = useRouter();
                const courseCategories = ref([]);
                const cateID = ref();
                const courseID = ref(null);
                const courseDetailData = ref({});
                const moreCourse = ref([]);
                const isOpenModal = ref(false);



                //watch for change when route cateID and route Course ID change
                watch(
                        () => ({ courseId: route.params.courseID, categoryId: route.params.id }),
                        async (newValues) => {
                                const { courseId: newCourseId, categoryId: newCategoryId } = newValues;
                                //fetch course detail
                                await getCollectionQuery("courses", [], (data) => {
                                        courseDetailData.value = data;
                                }, newCourseId);



                                //fetch more courses by category
                                await getCollectionQuery("courses", [where("categoryID", "==", newCategoryId),where('status',"==",true), orderBy("createdAt"), limit(4)], (data) => {
                                        const filter = data.filter(c => c.id !== newCourseId && c.status === true);

                                        moreCourse.value = filter;

                                }, null, true);
                        }
                );








                onMounted(async () => {
                        courseID.value = route.params.courseID;
                        cateID.value = route.params.id;

                        //fetch course detail
                        await getCollectionQuery("courses", [], (data) => {
                                courseDetailData.value = data;
                                console.log(courseDetailData)
                        }, courseID.value);

                        //fetch more coures by category
                        await getCollectionQuery("courses", [where("categoryID", "==", cateID.value),where('status',"==",true), orderBy("createdAt"), limit(4)], (data) => {
                                const filter = data.filter(c => c.id !== courseID.value);
                                moreCourse.value = filter
                        }, null, true);
                })




                const showCourseByCate = async (catID) => {
                        const path = 'by-course-category-page';
                        const params = { id: catID };
                        router.push({ name: path, params });
                }

                const handleOpenModal = () => {
                        isOpenModal.value = true;
                }



                const fetchCategory = async () => {
                        try {
                                await getCollectionQuery("course-categories", [where("status", "==", true), orderBy("createdAt")], (data) => {
                                        courseCategories.value = data;

                                }, null, true);
                        } catch (err) {
                                console.log("failed to fetch data", err);
                        }
                }

                const filteredCategories = computed(() => {
                        return courseCategories.value.filter(c => c.id !== cateID.value);
                });





                const formatCategoryName = (ID) => {
                        const category = courseCategories.value.find((c) => c.id === ID);
                        return category ? category.categoryName : "";
                };

                //for format Date
                const formatDate = (date) => {
                        if (date && date.toDate) {
                                return moment(date.toDate()).format("DD/MMM/YYYY ");
                        } else {
                                return "";
                        }
                };


                const useStripHtml = (html) => {
                        if (typeof html !== 'string') {
                                return ''; // or any other default value
                        }
                        let regex = /(<([^>]+)>)/ig;
                        return html.replace(regex, "");
                }


                onMounted(() => {
                        fetchCategory();
                })

                return {

                        useStripHtml,
                        isOpenModal,
                        handleOpenModal,
                        showCourseByCate,
                        filteredCategories,
                        formatDate,
                        moreCourse,
                        courseDetailData,
                        formatCategoryName,
                        cateID
                }
        }
}
</script>

<style></style>