<template>
    <div class="w-full  h-[72px] flex items-center justify-between p-5 bg-primary font-poppins select-none hidden-print">
      <div @click="handleEmitEvent" class="text-white cursor-pointer focus:outline-none flex space-x-2 items-center">
  
        <font-awesome-icon icon="fa fa-list" size="lg" />
  
        <!-- <div
          class="xl:hidden text-base h-[52px] lg:h-[72px] flex flex-col justify-center items-center space-y-3 text-quaternary px-3 font-semibold sm:text-xl tracking-wider uppercase">
          Feng Shui
        </div> -->
      </div>
  
      <div class="flex items-center space-x-2 font-system">
        
        <router-link
          to="/admin/user-submission"
          class="relative text-base font-semibold bg-secondary py-1 px-4 text-primary cursor-pointer focus:outline-none">
          <font-awesome-icon icon="fa-solid fa-bell" />
          <span class="animate-ping bg-red-600 text-sm text-white rounded-full absolute w-1 h-1 top-2 right-4"></span>
        </router-link>
        <button
          class="text-base font-semibold  bg-secondary py-1 px-4 text-primary cursor-pointer focus:outline-none">
       {{ userName }}
        </button>
  
  
      </div>
    </div>
  </template>
  
  <script>
  import { projectAuth, onAuthStateChanged } from "@/firebase/config";
  import { onMounted, ref } from 'vue';
  import { getCollectionQuery } from "@/composables/getCollection";
  import { where } from "firebase/firestore";
  export default {
    emits: ["onToggleSideBar"],
    setup(_, { emit }) {
  
      const userName = ref("");
      const handleEmitEvent = () => {
        emit("onToggleSideBar", false);
      };
  
  
      const user = ref(null);
      const fetchUser = () => {
        onAuthStateChanged(projectAuth, async (_user) => {
          if (_user) {
            user.value = _user;
  
            await getCollectionQuery("users", [where("email", "==", _user.email)], (data) => {
              data.forEach((user) => {
                userName.value = user.username;
              });
            },null, true);
          } else {
            user.value = null;
  
          }
        });
      }
  
  
      onMounted(() => {
        fetchUser();
      })
  
  
      return {
        handleEmitEvent,
         userName
      }
    }
  }
  </script>
  
  <style scoped>
  @media print {
    .hidden-print {
      display: none;
    }
  
    .print-header {
      font-size: 20px;
      line-height: 1.5rem /* 24px */;
    }
  
    #printTable {
      visibility: visible;
      position: absolute;
      font-size: 1rem /* 16px */;
      line-height: 1.5rem /* 24px */;
      left: 0;
      top: 0;
    }
  }
  
  </style>
  
  
  