<template>
    <div class="text-tertiary font-poppins">
        <div
            class="bg-secondary h-[72px] whitespace-nowrap flex flex-col justify-center items-center space-y-3 px-3 font-semibold  text-primary text-xl tracking-wider uppercase">
            <div class="w-[150px]">
                 <img src="../../assets/logo.png" class="w-full h-full object-cover object-center" />
            </div>
        </div>

        <div class=" space-y-1 mb-1">
            <router-link to="/adminDashboard" class="sidebar-element" :class="$route.name == 'admin-dashboard' && 'active'">
                <font-awesome-icon icon="fa fa-dashboard" size="sm" />
                <p class="whitespace-nowrap">Dashboard</p>
            </router-link>
        </div>

        <div class=" space-y-1 mb-1">
            <router-link to="/admin/user-submission" class="sidebar-element" :class="$route.name == 'admin-user-submission' && 'active'">
                <font-awesome-icon icon="fa fa-user" size="sm" />
                <p class="whitespace-nowrap">User Submission</p>
            </router-link>
        </div>
        



        <!-- =====> Course  Group <===== -->
        <div class="group   transition [overflow-anchor:none] w-full mb-1" data-te-collapse-init data-te-collapse-collapsed
            data-te-target="#exchangeCollapse" aria-expanded="false" aria-controls="exchangeCollapse">
            <div
                class="flex justify-between items-center group-element active group-[&:not([data-te-collapse-collapsed])]:active-group-element">
                <div class="flex items-center space-x-5">
                    <font-awesome-icon icon="fa-solid fa-tags" />
                    <p class="select-none whitespace-nowrap">Course</p>
                </div>

                <font-awesome-icon icon="fa fa-chevron-right"
                    class="transition-all duration-75 ease-in-out group-[&:not([data-te-collapse-collapsed])]:rotate-90"
                    size="sm" />
            </div>
        </div>

        <div class="!visible hidden" id="exchangeCollapse" data-te-collapse-item aria-labelledby="exchangeCollapse">
            <div class="h-full space-y-1"
                >
                <router-link
                    to="/admin/course-category" 
                    :class="$route.name == 'admin-course-category' && 'active'"
                    class="flex items-center space-x-3 sidebar-element-dropdown">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p class="whitespace-nowrap">Course Category</p>

                </router-link>
                <router-link 
                    to="/admin/course"
                    :class="$route.name == 'admin-course' && 'active'"
                    class=" flex items-center space-x-3 sidebar-element-dropdown">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p class="whitespace-nowrap">Course</p>

                </router-link>
                
            </div>
            
        </div>


        <div class=" space-y-1 mb-1" v-if="userAuthRole==='SuperAdmin'">
            <router-link to="/admin/user" :class="$route.name == 'admin-user' && 'active'" class="sidebar-element">
                <font-awesome-icon icon="fa-solid fa-users" />
                <p class="whitespace-nowrap">User</p>
            </router-link>
        </div>
        <div class=" space-y-1 mb-1">
            <router-link to="/admin/company-info" :class="$route.name == 'admin-company-info' && 'active'" class="sidebar-element">
                <font-awesome-icon icon="fa-solid fa-circle-info" />
                <p class="whitespace-nowrap">Company Info</p>
            </router-link>
        </div>


         <!-- =====> Report  Group <===== -->
         <div class="group   transition [overflow-anchor:none] w-full mb-1" data-te-collapse-init data-te-collapse-collapsed
            data-te-target="#reportCollapse" aria-expanded="false" aria-controls="reportCollapse">
            <div
                class="flex justify-between items-center group-element active group-[&:not([data-te-collapse-collapsed])]:active-group-element">
                <div class="flex items-center space-x-5">
                    <font-awesome-icon icon="fa-solid fa-chart-column" />
                    <p class="select-none whitespace-nowrap">Reports</p>
                </div>

                <font-awesome-icon icon="fa fa-chevron-right"
                    class="transition-all duration-75 ease-in-out group-[&:not([data-te-collapse-collapsed])]:rotate-90"
                    size="sm" />
            </div>
        </div>

        <div class="!visible hidden" id="reportCollapse" data-te-collapse-item aria-labelledby="reportCollapse">
            <div class="h-full space-y-1"
                >
                <router-link
                    to="/admin/user-submission-report" 
                    :class="$route.name == 'admin-user-submission-report' && 'active'"
                    class="flex items-center space-x-3 sidebar-element-dropdown">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p class="whitespace-nowrap">User Submission</p>
                </router-link>
            </div>
            
        </div>

        <div class=" space-y-1 mb-1  ">

            <div class="sidebar-element " @click="handleLogout ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
                </svg>
                <p class="whitespace-nowrap">Log Out</p>
            </div>
        </div>
    </div>
</template>


<script>
import { onMounted, ref } from "vue";
import { Collapse, initTE } from "tw-elements";
import { projectAuth,onAuthStateChanged} from "@/firebase/config";
import { useRouter } from 'vue-router';
import { getCollectionQuery } from '@/composables/getCollection';
import { where } from 'firebase/firestore';

export default {
    setup() {

        const router = useRouter();
        const userAuthRole = ref(null);
        
        const fetchUserAuthentication = () => {
            onAuthStateChanged(projectAuth, async (_user) => {
                if (_user) {

                    await getCollectionQuery("users", [where("email", "==", _user.email)], (data) => {
                        data.forEach((user) => {
                            userAuthRole.value = user.role;
                           
                        });
                    },null, true);
                }
            });
        }
      
        onMounted(() => {
            fetchUserAuthentication();
            initTE({ Collapse });
            
        })

        const handleLogout = async () => {
            try {

                await projectAuth.signOut();
                router.push({ name: "login-page" });
            } catch (err) {
                console.log("faiel to logout", err);
            }
        }

        return {
            userAuthRole,
            handleLogout
            
        }
    }
}
</script>