<template>
    <TransitionRoot appear :show="true" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-40 font-">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="w-full font-notoKhmer max-w-3xl transform overflow-hidden rounded-xl bg-white text-tertiary p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle as="h3"
                                class="text-2xl font-semibold leading-6 text-tertiary flex justify-between items-center">
                                <span>Course Details</span>
                                <!-- <span v-if="id != null" class="text-2xl">កែប្រែប្រភេទរូបិយប័ណ្ណ</span>  -->
                                <span @click="closeModal" class="text-red-600 cursor-pointer">
                                    <font-awesome-icon size="lg" icon="fa-solid fa-circle-xmark" />
                                </span>
                            </DialogTitle>
                                
                            <hr class="my-4 h-[2px] bg-quaternary-low-light">

                            <div class="mt-8">
                                <img class="min-w-[720px] max-w-[720px] max-h-[400px] min-h-[400px] block bg-center object-cover mx-auto" :src="doc.imageUrl" :alt="doc.title" />
                                
                                <div class="mt-4 border-2 border-quaternary-low-light rounded-lg p-2">
                                     <div class="flex space-x-2">
                                           <p>Title:</p>
                                           <p class="break-before-left">{{ doc.title }}</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>Category : </p>
                                           <p>{{ formatCategoryName(doc.categoryID) }}</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>Teacher : </p>
                                           <p>{{ doc.teacherName}}</p>
                                     </div>
                                     <div class="flex flex-col space-y-2">
                                           <p>Description : </p>
                                           <p v-html="doc.description"></p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>Summarize : </p>
                                           <p >{{ doc.summarize }}</p>
                                     </div>
                                </div>

                                <div class="mt-4 border-2 border-quaternary-low-light rounded-lg p-2">
                                     <div class="flex space-x-2">
                                           <p>Price:</p>
                                           <p class="break-before-left">{{ doc.price }}$</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>Discount : </p>
                                           <p>{{ doc.discount }}%</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>status : </p>
                                           <div :class="doc.status ? 'text-green-600' : 'text-red-600'"
                                                class="px-1.5 w-fit">
                                                    <span v-if="doc.status">Active</span>
                                                    <span v-else>Inactive</span>
                                            </div>
                                     </div>
                                     
                                </div>
                                <div class="mt-4 border-2 border-quaternary-low-light rounded-lg p-2">
                                     <div class="flex space-x-2">
                                           <p>Start Date:</p>
                                           <p class="break-before-left">{{ formatDate(doc.startDate) }}</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>End Date : </p>
                                           <p>{{ formatDate(doc.endDate) }}</p>
                                     </div>
                                     <div class="flex space-x-2">
                                           <p>Created At : </p>
                                           <p>{{ formatDate(doc.createdAt)}}</p>
                                     </div>
                                     
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
   
</template>

<script>
import { getCollectionQuery } from '@/composables/getCollection';
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue';
import { orderBy, where } from 'firebase/firestore';
import moment from 'moment';
import { onMounted, ref } from 'vue';
export default {
    emits: ['closeModal'],
    props:['doc'],
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    },

    setup(props,{ emit }) {
        const courseCategories = ref([]);
        const closeModal = () => {
            emit('closeModal', false);
        }

        const fetchCourseCategories = async () => { 
            await  getCollectionQuery("course-categories",[where("status", "==", true),orderBy("createdAt", "desc")],(data) => {
                 courseCategories.value = data;
            },null,true);
        }

        const formatCategoryName = (ID) => {
            const category = courseCategories.value.find((c) => c.id === ID);
            return category ? category.categoryName : "";
        };

        //for format Date
        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MMM/YYYY ");
            } else {
                return moment(date).format("DD/MMM/YYYY");
            }
        };

        

        onMounted(() => {
             fetchCourseCategories();
        })

        return {
          closeModal,
          formatCategoryName,
          formatDate
        }
    }
}
</script>