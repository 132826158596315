<template>
    <div class=" bg-secondary-light font-notoSans">
        <div class="max-w-[90%] xl:max-w-[1000px] w-full mx-auto">
            <div class="grid grid-cols-1 lg:grid-cols-5 gap-10 py-8 space-x-10">
                <div class="space-y-4 col-span-2">
                    <h2 class="text-[24px] text-primary font-semibold">Bazi Destiny</h2>
                    <div  class="text-quaternary space-y-6">
                        <div class="mb-3">
                            <label for="" class="text-quaternary ml-4 block">Name <span
                                    class="text-blood ">*</span></label>
                            <input v-model="name" required type="text" class="form-control w-fit md:w-full"
                                placeholder="Enter name">
                                <span class="text-red-500 text-sm sm:text-base ml-1 block sm:inline" v-if="errorMessageName">{{ errorMessageName }}</span> 
                        </div>

                        <div class="mb-3">
                            <label for="" class=" ml-4">Gender <span class="text-blood ">*</span></label>
                            <SelectVue :options="['Female', 'Male']" @onEmitOption="(option) => gender = option" :isCleargender="isCleargender" />
                            <span class="text-red-500 text-sm ml-1" v-if="errorMessageGender">{{ errorMessageGender }}</span>
                        </div>

                        <div class="mb-3 flex items-center">
                            <input v-model="twin" id="twin" type="checkbox" class="input-checkbox cursor-pointer" />
                            <label for="twin" class=" ml-4 select-none">Are you a 2th Twin Child?</label>
                        </div>

                        <div class="mb-3">
                            <label>Date of Birth</label>
                            <div>
                                <DatePicker @onEmitYear="(y) => year = y" @onEmitMonth="(m) => month = m"
                                    @onEmitDay="(d) => day = d" />
                            </div>
                        </div>

                        <div class="flex items-center">
                            <input v-model="unknowTimeOfBirth" id="time" type="checkbox"
                                class="input-checkbox cursor-pointer">
                            <label for="time" class="ml-4 select-none">Unknow Time of Birth?</label>
                        </div>

                        <TransitionGroup tag="div" name="nested">
                            <div v-if="!unknowTimeOfBirth" class="mb-3">
                                <label class=" ml-4 block">Time <span class="text-blood ">*</span></label>
                                <ScrollTimePicker @onEmitTime="handleSetTime" />
                            </div>
                        </TransitionGroup>

                        <router-link v-if="name && gender" @click="handleCalcualteBazi"  class=" btn btn-primary rounded-full px-[30px] inline-block"
                            :to="{ path: `/show-result-bazi/${name}/${hour}/${minute}/${day}/${month}/${year}/${gender}/${twin}/${unknowTimeOfBirth}`, }"
                            target='_blank'>


                            Calculate
                        </router-link>

                            <button @click="handleCalcualteBazi" v-if="name==='' || gender===''" class=" btn btn-primary rounded-full px-[30px] inline-block">
                                   Calculate
                            </button>
                         
            
                    </div>
                </div>
                <div class="hidden lg:inline-block lg:col-span-3">
                    <img class="h-[500px] w-full object-cover rounded-2xl"
                        src="https://images.unsplash.com/photo-1685677112447-4819163a823b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fGZlbmclMjBzaHVpfGVufDB8fDB8fHww"
                        alt="">
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import SelectVue from '@/components/ui/SelectVue.vue'
import ScrollTimePicker from '@/components/ui/ScrollTimePicker.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import { ref, watch, } from 'vue'
// import { useRouter } from 'vue-router'
import useCollection from '@/composables/useDocument'
import { serverTimestamp } from 'firebase/firestore'


export default {
    components: {
        SelectVue,
        ScrollTimePicker,
        DatePicker
    },
    emits:["clearGender"],
    setup() {

        const gender = ref('');
        const year = ref('');
        const month = ref('');
        const day = ref('');
        const hour = ref(null);
        const minute = ref(null);
        const unknowTimeOfBirth = ref(false);
        const name = ref('');
        const twin = ref(false);
        const errorMessageGender = ref(null);
        const timestamp = ref(null);
        timestamp.value = serverTimestamp();
        const errorMessageName=ref(null);
        const isCleargender=ref(false);


        // const router = useRouter();


        const handleSetTime = (h, m) => {
            hour.value = h;
            minute.value = m;
        }





        watch(gender, (newV) => {
            if (newV) {
                console.log("gender", newV)
                errorMessageGender.value = null;
            }
        })

        watch(name, (newV) => {
            if (newV) {
               
                errorMessageName.value = null;
            }
        })
        watch(twin, () => {

            console.log("twin", twin)

        })

        watch(unknowTimeOfBirth, () => {
            console.log("unknow T", unknowTimeOfBirth)
        })




        const handleCalcualteBazi = async () => {
            if(!name.value){
                errorMessageName.value="Name field is requiere !"
            }else if (!gender.value) {
                errorMessageGender.value = "Gender field is requiere !"
           
            } else {
                errorMessageGender.value = null;
                errorMessageName.value=null;
                //for store user prediction data
                const dataUser = {
                    userID: null,
                    name: name.value,
                    day: Number(day.value),
                    hour: Number(hour.value),
                    minutes: Number(minute.value),
                    month: Number(month.value),
                    year: Number(year.value),
                    gender: gender.value,
                    twin: twin.value,
                    unknownTime: unknowTimeOfBirth.value,
                    createdAt: timestamp.value

                }

                const { addDocs } = useCollection("userPrediction");
                const success = await addDocs(dataUser);
                if (success) {
                    console.log("data save user predict success")
                    name.value="";
                    gender.value="";
                    isCleargender.value=true;
                
                }


               

           
            }


        }






        return {
            isCleargender,
            errorMessageName,
            errorMessageGender,
            twin,
            hour,
            minute,
            name,
            gender,
            year,
            month,
            day,
            unknowTimeOfBirth,
            handleCalcualteBazi,
            handleSetTime,
        }
    }
}
</script>

<style></style>