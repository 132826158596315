<template>
    <div>
        <div class="bg-secondary-light md:py-20">
            <div
                class="hidden max-w-[90%] xl:max-w-[1080px] w-full mx-auto md:grid grid-cols-1 md:grid-cols-2 gap-20 items-center">
                <div class="space-y-2 md:space-y-4 xl:space-y-8 hidden sm:block">
                    <h1 class="text-[50px] xl:text-[90px] uppercase text-primary font-bold xl:leading-[6rem]">
                        feng shui <br> astrology <br> center
                    </h1>
                    <h3 class="text-[20px] lg:text-[28px] font-thin">Figure out your destiny with us</h3>
                    <button type="button">
                        <router-link to="/calculate-bazi" class="btn btn-primary rounded-full py-4 space-x-2">
                            <span>Calculate Bazi</span>
                            <span><font-awesome-icon icon="fa-solid fa-arrow-right" size="sm" /></span>
                        </router-link>
                    </button>
                </div>

                <img class="h-full w-full object-cover rounded-2xl"
                    src="https://images.unsplash.com/photo-1685677112447-4819163a823b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fGZlbmclMjBzaHVpfGVufDB8fDB8fHww"
                    alt="">

            </div>

            <div class="md:hidden">
                <div class=" relative h-[400px] md:h-[600px]">
                    <img class="h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1685677112447-4819163a823b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjN8fGZlbmclMjBzaHVpfGVufDB8fDB8fHww"
                        alt="">
                    <div
                        class="grid place-items-center absolute bottom-0 left-0 w-full h-[100px] z-10 bg-white bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-40">
                        <button type="button">
                            <router-link to="/calculate-bazi" class="btn btn-primary rounded-full space-x-2">
                                <span>Calculate Bazi</span>
                                <span><font-awesome-icon icon="fa-solid fa-arrow-right" size="sm" /></span>
                            </router-link>
                        </button>
                    </div>
                </div>

                <div class="py-4">
                    <h1 class="text-[40px] text-center uppercase text-primary font-bold leading-[3rem]">
                        feng shui <br> astrology <br> center
                    </h1>
                    <h3 class="text-lg my-4 text-center font-light">Figure out your destiny with us</h3>
                </div>
            </div>
        </div>

        <div class="mt-10 mb-40">
            <div class="xl:max-w-[1000px] max-w-[90%] mx-auto">
                <h2 class="text-[36px] font-bold">Outstanding Services and Courses</h2>

                <p class="text-quaternary text-lg text-justify mt-4">
                    Explore Our Standing Courses
                </p>


            </div>

            <div class="xl:max-w-[1000px] max-w-[90%] mx-auto my-4 space-y-4">


                <div class="hidden md:grid grid-cols-1 md:grid-cols-3 gap-5">
                    <div class="col-span-2 border shadow-sm overflow-hidden rounded-lg"  v-if="dateOutStanding[0] !=null">
                        <div class="relative ">
                            <img class="w-full h-[300px] object-cover" :src="dateOutStanding[0]?.imageUrl" alt="">
                            <div class="absolute bottom-0 left-0 h-[60px] bg-blood/60 w-full">

                            </div>
                            <div
                                class="leading-none flex items-center justify-center flex-col absolute left-0 bottom-0 h-[60px] w-[120px] bg-blood rounded-r-full">

                                <span class="text-base line-through text-white pt-1"> {{dateOutStanding[0]?.discount ? 
                    numeralFormat(dateOutStanding[0]?.discount, '0,0[.]00 $') : "0 $" }}</span>
                                <span class="text-[24px] font-medium text-white pb-1">{{
                    numeralFormat(dateOutStanding[0]?.price, '0,0[.]00 $') }}</span>

                            </div>

                            <div
                                class="leading-none px-8 flex items-center justify-center flex-col absolute right-0 bottom-0 h-[60px]">
                                <span class="text-base font-thin text-white">Start Class</span>
                                <span class="text-lg font-medium text-white">{{dateOutStanding[0]?.startDate ? 
                    formatDate(dateOutStanding[0]?.startDate)
               : "None" }}</span>
                            </div>
                        </div>

                        <div class="p-4">
                            <h2 class="text-[24px] line-clamp-1">{{ dateOutStanding[0]?.title }}</h2>
                            <p  class="line-clamp-1 text-quaternary">{{ dateOutStanding[0]?.summarize }}</p>

                            <div class="flex justify-end items-center mt-6"  v-if="dateOutStanding[0] !=null">
                                <div class="flex  space-x-4">
                                    <button @click="mountComponent(dateOutStanding[0])"
                                        class="btn btn-primary py-2 rounded-full px-[40px]">JOIN</button>
                                    <router-link
                                        :to="{ name: 'show-course-info-page', params: { id: dateOutStanding[0]?.categoryID || '', courseID: dateOutStanding[0]?.id || '' } }"
                                        class="btn rounded-full py-2 px-[40px]">View</router-link>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class=""  v-if="dateOutStanding[1] !=null">
                        <div class="bg-white">
                            <div class="hidden md:block border shadow-sm overflow-hidden rounded-lg">
                                <div class="relative">
                                    <img class="w-full h-[300px] object-cover" :src="dateOutStanding[1]?.imageUrl"
                                        alt="">

                                    <div class="absolute bottom-0 left-0 h-[60px] bg-blood/60 w-full"></div>
                                    <div
                                        class="leading-none flex items-center justify-center flex-col absolute left-0 bottom-0 h-[60px] w-[120px] bg-blood rounded-r-full">
                                        <span class="text-base line-through text-white pt-1">{{dateOutStanding[1]?.discount ? 
                    numeralFormat(dateOutStanding[1]?.discount, '0,0[.]00 $') : "0 $" }}</span>
                                        <span class="text-[24px] font-medium text-white pb-1">{{
                    numeralFormat(dateOutStanding[1]?.price, '0,0[.]00 $') }}</span>
                                    </div>

                                    <div
                                        class="leading-none px-8 flex items-center justify-center flex-col absolute right-0 bottom-0 h-[60px]">
                                        <span class="text-base font-thin text-white">Start Class</span>
                                        <span class="text-lg font-medium text-white">{{dateOutStanding[1]?.startDate ?
                    formatDate(dateOutStanding[1]?.startDate)
               :"None" }}</span>
                                    </div>
                                </div>

                                <div class="p-4">
                                    <h2 class="text-[24px] line-clamp-1"> {{ dateOutStanding[1]?.title }}</h2>
                                    <p  class="line-clamp-2 text-quaternary">{{ dateOutStanding[1]?.summarize }}</p>
                                    <div class="flex justify-between items-center mt-6" v-if="dateOutStanding.length > 0">

                                        <button @click="mountComponent(dateOutStanding[1])"
                                            class="btn btn-primary py-2 rounded-full px-[40px]">
                                            JOIN
                                        </button>
                                        <router-link
                                            :to="{ name: 'show-course-info-page', params: { id: dateOutStanding[1]?.categoryID || '', courseID: dateOutStanding[1]?.id || '' } }"
                                            class="btn rounded-full py-2 px-[40px]">View
                                        </router-link>
                                    </div>


                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>


                <JoinCourseModal :isOpen="isOpenModal" :doc="course" @closeModal="isOpenModal = false" />



                <!--Dekstop and tablet-->
                <div class="hidden md:block" >

                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5" v-if="  dataOutStadingsmall.length > 0">
                        <div class="bg-white" v-for="c in   dataOutStadingsmall" :key="c.id">
                            <div class="hidden md:block border shadow-sm overflow-hidden rounded-lg">
                                <div class="relative">
                                    <img class="w-full h-[300px] object-cover" :src="c.imageUrl" alt="" />
                                    <div class="absolute bottom-0 left-0 h-[60px] bg-blood/60 w-full"></div>
                                    <div
                                        class="leading-none flex items-center justify-center flex-col absolute left-0 bottom-0 h-[60px] w-[120px] bg-blood rounded-r-full">
                                        <span class="text-base line-through text-white pt-1">{{ c.discount ? numeralFormat(c.discount ,'0,0[.]00 $') : "0 $"  }}</span>
                                        <span class="text-[24px] font-medium text-white pb-1">{{ numeralFormat(c.price ,'0,0[.]00 $') }}</span>
                                    </div>

                                    <div
                                        class="leading-none px-8 flex items-center justify-center flex-col absolute right-0 bottom-0 h-[60px]">
                                        <span class="text-base font-thin text-white">Start Class</span>
                                        <span class="text-lg font-medium text-white">{{c.startDate ? formatDate(c.startDate) : "None"}}</span>
                                    </div>
                                </div>

                                <div class="p-4">
                                    <h2 class="text-[24px] line-clamp-1">{{ c.title }}</h2>
                                    <p class="line-clamp-2 text-quaternary" >
                                       {{c.summarize  }}
                                    </p>
                                    <div class="flex justify-between items-center mt-6" v-if="dateOutStanding.length > 0">
                                        <!-- <div class="flex space-x-4">
                                                        
                                                </div> -->
                                        <button @click="mountComponent(c)"
                                            class="btn btn-primary py-2 rounded-full px-[40px]">
                                            JOIN
                                        </button>
                                        <router-link
                                            :to="{ name: 'show-course-info-page', params: { id: c.categoryID , courseID:c.id } }"
                                            class="btn rounded-full py-2 px-[40px]">View
                                        </router-link>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>



                <!--Mobile-->
                <div class="block md:hidden">
                    <MobileCardVue :allCourse="allCourses" />
                </div>




                <div class="my-4 flex justify-end">
                    <router-link :to="{ name: 'all-course-category-page', params: { allCourse: 'allCourse' } }">
                        <button class="rounded-full md:rounded-md btn px-[40px] uppercase space-x-2">
                            <span>See more</span>
                            <span><font-awesome-icon icon="fa-solid fa-arrow-right" size="sm" /></span>
                        </button>
                    </router-link>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MobileCardVue from '@/components/MobileCardVue.vue'
import { getCollectionQuery } from '@/composables/getCollection';
import { onMounted, ref } from 'vue';
import { limit, orderBy, where } from 'firebase/firestore';
import JoinCourseModal from '@/components/JoinCourseModal.vue'
import moment from 'moment';
export default {
    components: {
     
        MobileCardVue,
        JoinCourseModal
    },

    setup() {
        const isOpenModal = ref(false)
        const course = ref({});
        const mountComponent = (data) => {
            course.value = { ...data }
            isOpenModal.value = true;
        }

        const allCourses = ref([]);
        const fetchAllCourses = async () => {
            try {
                await getCollectionQuery("courses", [where("status", "==", true), where("isOutStanding", "==", true), orderBy("dateOutStanding", "desc"),limit(8)], (data) => {
                    allCourses.value = data;
                    console.log(allCourses.value)
                }, null, true);
            } catch (err) {
                console.log("failed to fetch all course", err)
            }
        }


        const dateOutStanding = ref([]);
        const dataOutStadingsmall=ref([]);
        const fetchBigCard = async () => {
            await getCollectionQuery("courses", [where("status", "==", true), where("isOutStanding", "==", true), orderBy("dateOutStanding", "desc"), limit(8)], (data) => {
                dateOutStanding.value = data;
                console.log("before remove",dateOutStanding);
                // Remove the first two items
        const remainingData = dateOutStanding.value.slice(2);
        dataOutStadingsmall.value=remainingData;

        console.log("after remove ",remainingData)

        
            }, null, true);

        }

        const useStripHtml = (html) => {
            if (typeof html !== 'string') {
                return ''; // or any other default value
            }
            let regex = /(<([^>]+)>)/ig;
            return html.replace(regex, "");
        }



        //for format Date
        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MMM/YYYY ");
            } else {
                return "";
            }
        };
        onMounted(() => {
            fetchBigCard();
            fetchAllCourses();
        })

        return {
            dataOutStadingsmall,
            formatDate,
            useStripHtml,
            isOpenModal,
            course,
            mountComponent,
            dateOutStanding,
            allCourses
        }

    }
}
</script>