<template>
    <div class="grid place-items-center relative max-w-fit min-w-[100px] h-[50px]">
        <div class="absolute w-full h-full border-2 border-primary rounded-l-md border-r-0">
        </div>
        <div class="w-[40px] h-[40px] border-2 border-primary rounded-lg rotate-45 absolute top-[5px] -right-[18px] border-b-0 border-l-0">
        </div>
        <p class="px-4 text-[18px] text-primary font-medium">
            <slot />
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>