<template>
      <div class="flex justify-center items-center h-[72px] text-white font-semibold tracking-wider uppercase"></div>
  
      <div>
        <div class="text-white font-medium space-y-1">
          <!-- === Dashboard ====== -->
          <router-link
            to="/adminDashboard" class="sidebar-element-icon flex justify-center" :class="$route.name == 'admin-dashboard' && 'active'">
            <font-awesome-icon icon="fa fa-dashboard" size="sm" />
          </router-link>

          <!-- === Dashboard ====== -->
          <router-link
            to="/admin/user-submission" class="sidebar-element-icon flex justify-center" :class="$route.name == 'admin-user-submission' && 'active'">
            <font-awesome-icon icon="fa fa-user" size="sm" />
          </router-link>
  
         
          <!-- ==== Exchange Money ======== -->
  
          <div class="relative group sidebar-element-icon cursor-pointer"
            >
            <font-awesome-icon icon="fa-solid fa-tags" />
            <div class="custom-tooltip text-white">
              <h1 class="text-base">Course</h1>
              <div class="text-sm w-full">
                  <router-link 
                    to="/admin/course-category" 
                    :class="$route.name == 'admin-course-category' && 'active'"  
                    class="tooltip-element">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p>Course Category</p>
                  </router-link>
                  <router-link 
                    to="/admin/course" 
                    class="tooltip-element"
                    :class="$route.name == 'admin-course' && 'active'"
                  >
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p>Course</p>
                  </router-link>
              </div>
            </div>
          </div>

          <router-link v-if="userAuthRole==='SuperAdmin'"
            to="/admin/user" class="sidebar-element-icon flex justify-center"
            :class="$route.name == 'admin-user' && 'active'"
            >
            <font-awesome-icon icon="fa-solid fa-users" />
          </router-link>

          <router-link
            to="/admin/company-info" class="sidebar-element-icon flex justify-center"
            :class="$route.name == 'admin-company-info' && 'active'"
            >
            <font-awesome-icon icon="fa-solid fa-circle-info" />
          </router-link>

          <div class="relative group sidebar-element-icon cursor-pointer"
            >
            <font-awesome-icon icon="fa-solid fa-chart-column" />
            <div class="custom-tooltip text-white">
              <h1 class="text-base">Reports</h1>
              <div class="text-sm w-full">
                  <router-link 
                    to="/admin/user-submission-report" 
                    :class="$route.name == 'admin-user-submission-report' && 'active'"  
                    class="tooltip-element">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                    <p>User submission</p>
                  </router-link>
              </div>
            </div>
          </div>
        
          <a
            class="sidebar-element-icon cursor-pointer flex justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
              </svg>
          </a>
          
        </div>
    </div>
  </template>
  
  <script>
  import { projectAuth,onAuthStateChanged} from "@/firebase/config";

import { getCollectionQuery } from '@/composables/getCollection';
import { where } from 'firebase/firestore';
import { onMounted, ref } from 'vue';
  export default {
    setup() {


      const userAuthRole = ref(null);
        
        const fetchUserAuthentication = () => {
            onAuthStateChanged(projectAuth, async (_user) => {
                if (_user) {

                    await getCollectionQuery("users", [where("email", "==", _user.email)], (data) => {
                        data.forEach((user) => {
                            userAuthRole.value = user.role;
                         
                           
                        });
                    },null, true);
                }
            });
        }

        onMounted(()=>{
          fetchUserAuthentication();
        })

        return{
          userAuthRole

        }
     
      
    },
  };
  </script>