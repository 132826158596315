<template>
    <TransitionRoot as="template" :show="true">
        <Dialog as="div" class="relative z-40" @close="handleClose">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35 bg-opacity-80 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto cursor-move">
                <div class="flex mt-[32px] justify-center overflow-x-auto">
                    <TransitionChild as="template" enter="ease-out duration-100"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform transition-all min-w-fit m-5 cursor-default">
                            <div class="bg-white font-notoKhmer shadow rounded-lg border border-neutral-300">
                                <div
                                    class="flex flex-shrink-0 items-center justify-between p-5 border-b border-neutral-300">
                                    <h5 class="w-full flex items-center space-x-1 text-lg font-bold capitalize">
                                        <span>Confirm to Delete</span>
                                    </h5>

                                    <div @click="handleClose"
                                        class="flex items-center justify-center w-7 h-7 rounded-full hover:bg-red-600 hover:text-white cursor-pointer">
                                        <font-awesome-icon icon="fa fa-x" size="sm" />
                                    </div>
                                </div>

                                <div
                                    class="flex flex-col sm:items-center justify-center px-4 my-2 font-noto font-medium">
                                    <p class="text-base">
                                        Are you sure you want to delete this user?
                                    </p>

                                    <p class=" text-base font-noto">Once you delete, it will be deleted permanenttly</p>
                                </div>

                                <div class="flex justify-end items-center p-3 space-x-2">

                                    <button @click="handleClose" type="button"
                                        class="btn bg-gray-300 px-8 py-1.5 rounded-lg font-noto">
                                        Close
                                    </button>

                                    <button type="submit"
                                        class="btn text-white bg-red-600 px-8 py-1.5 rounded-lg  font-noto"
                                        @click="handleDelete()">
                                        <span class="mr-1" v-if="isLoading === true">
                                            <svg aria-hidden="true" role="status"
                                                class="inline w-4 h-4 text-gray-500 animate-spin" viewBox="0 0 100 101"
                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="#E5E7EB"></path>
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        Delete
                                    </button>

                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { push } from 'notivue';

import useStorage from '@/composables/useStorage';
import { onMounted, ref } from "vue";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/config";

export default {
    components: {
        Dialog,
        DialogPanel,
        TransitionChild,
        TransitionRoot,
    },
    props: ["docDelete", "oldID","lastImageURL"],
    emits: ["closeModal", "handleDelete", "handleLoadPreviousPage"],
    setup(props, { emit }) {

        const isLoading = ref(false);
        const fileName = ref(null);
        const userID = ref(null);
        const imageURL = ref(null);

        const lastImageURL=ref(null);
        const handleClose = () => {
            emit("closeModal");
        };



        const lastID = ref(null);
        onMounted(() => {
            if (props.docDelete) {
                userID.value = props.docDelete?.id;
                imageURL.value = props.docDelete.imageURL;
            }


            if (props.oldID) {
                lastID.value = props.oldID;
            }

            if(props.lastImageURL){
                lastImageURL.value=props.lastImageURL;
            }
        })


        const { removeImage } = useStorage();
        const handleDelete = async () => {
            try {

                if (lastID.value) {
                    isLoading.value = true;
                    console.log("condition last ID has")

                    const uid = lastID.value;

                    const removeUserFunction = httpsCallable(functions, 'deleteUserV2');
                    const removeUserResponse = await removeUserFunction({ uid });


                    if (lastImageURL.value) {

                        const cleanURL = lastImageURL.value.split('?')[0];
                        const decodedURL = decodeURIComponent(cleanURL);
                        fileName.value = decodedURL.split('/').pop();
                        const storagePath = `users/${fileName.value}`;
                        if (storagePath) {
                            await removeImage(storagePath);
                        }

                    }


                    if (removeUserResponse.data.success) {

                        clearData();
                        handleClose();
                        emit("handleDelete");

                    }
                    emit("handleLoadPreviousPage");
                    handleClose();
                } else {
                    console.log("condition last ID don't has")
                    isLoading.value = true;

                    const uid = userID.value;

                    const removeUserFunction = httpsCallable(functions, 'deleteUserV2');
                    const removeUserResponse = await removeUserFunction({ uid });


                    if (imageURL.value) {

                        const cleanURL = imageURL.value.split('?')[0];
                        const decodedURL = decodeURIComponent(cleanURL);
                        fileName.value = decodedURL.split('/').pop();
                        const storagePath = `users/${fileName.value}`;
                        if (storagePath) {
                            await removeImage(storagePath);
                        }

                    }


                    if (removeUserResponse.data.success) {

                        clearData();
                        handleClose();
                        emit("handleDelete");

                    }
                }


            } catch (err) {
                push.error("Something went wrong! please try again!")
            }
        }


        const clearData = () => {
            imageURL.value = null;
            fileName.value = null;
            userID.value = null;
            lastID.value = null;

        }

        return { handleDelete, handleClose, isLoading };
    },
};
</script>