<template>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                <div class="bg-white" v-for="c in list" :key="c.id">
                        <div class="hidden md:block border shadow-sm overflow-hidden rounded-lg">
                                <div class="relative">
                                        <img class="w-full h-[300px] object-cover" :src="c.imageUrl" alt="" />
                                        <div class="absolute bottom-0 left-0 h-[60px] bg-blood/60 w-full"></div>
                                        <div
                                                class="leading-none flex items-center justify-center flex-col absolute left-0 bottom-0 h-[60px] w-[120px] bg-blood rounded-r-full">
                                                <span class="text-base line-through text-white pt-1">{{c.discount ? numeralFormat(c.discount ,'0,0[.]00 $') : "0 $" }}</span>
                                                <span class="text-[24px] font-bold text-white pb-1">{{ numeralFormat(c.price ,'0,0[.]00 $')}}</span>
                                        </div>

                                        <div
                                                class="leading-none px-8 flex items-center justify-center flex-col absolute right-0 bottom-0 h-[50px]">
                                                <span class="text-base text-white">Start Class</span>
                                                <span class="text-lg font-medium text-white">{{c.startDate ? formatDate(c.startDate) : "None"}}</span>
                                        </div>
                                </div>

                                <div class="p-4">
                                        <h2 class="text-[24px] line-clamp-1">{{ c.title }}</h2>
                                        <p  class="line-clamp-2 text-quaternary">{{ c.summarize }}</p>
                                        <div class="flex justify-between items-center mt-6">
                                                <!-- <div class="flex space-x-4">
                                                        
                                                </div> -->
                                                <button @click="mountComponent(c)" class="btn btn-primary py-2 rounded-full px-[40px]">
                                                        JOIN
                                                </button>
                                                <router-link  :to="{ name: 'show-course-info-page', params: { id: c.categoryID , courseID:c.id } }"
                                                        class="btn rounded-full py-2 px-[40px]">View
                                                </router-link>
                                        </div>

                                        
                                </div>
                        </div>
                </div>
                <JoinCourseModal :isOpen="isOpenModal" :doc="course" @closeModal="isOpenModal = false" />
        </div>
     
</template>


<script>
import { ref, watchEffect } from "vue";
import moment from "moment";
import JoinCourseModal from "./JoinCourseModal.vue";

export default {
        props: ["allCourse", "courseByCate"],
        components:{
                JoinCourseModal
        },
        setup(props) {
                const list = ref([]);
                const isOpenModal = ref(false);
                const course = ref({});
                const mountComponent = (data) => {
                        course.value = {...data}
                        isOpenModal.value = true;
                }

                watchEffect(() => {
                        if (props.allCourse) {

                                list.value = props.allCourse;
                           

                        } else if (props.courseByCate) {
                                list.value = props.courseByCate
                        }
                });

                const useStripHtml = (html) =>  {
                        let regex = /(<([^>]+)>)/ig;
                        return html.replace(regex, "");
                }


                //for format Date
                const formatDate = (date) => {
                        if (date && date.toDate) {
                                return moment(date.toDate()).format("DD/MMM/YYYY ");
                        } else {
                                return "";
                        }
                };

                return {
                      
                        formatDate,
                        list,
                        isOpenModal,
                        course,
                        mountComponent,
                        useStripHtml
                };
        },
};
</script>

<style></style>