<template>

    <div class="relative text-lg w-48 bg-white">
      <div
        class="flex items-center cursor-pointer justify-between form-control"
          @click="() => isOptionsExpanded = !isOptionsExpanded"
      >
        <span v-if="selectedOption == null">Choose</span>
        <span v-else>{{ selectedOption }}</span>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
          :class="isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      <transition
          enter-active-class="transform transition duration-500 ease-custom"
          enter-class="-translate-y-1/2 scale-y-0 opacity-0"
          enter-to-class="translate-y-0 scale-y-100 opacity-100"
          leave-active-class="transform transition duration-300 ease-custom"
          leave-class="translate-y-0 scale-y-100 opacity-100"
          leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
        >
        <ul
          v-show="isOptionsExpanded"
          class="absolute z-40 left-0 list-none right-0 mb-4 bg-white divide-y rounded-b-md shadow-lg overflow-hidden"
        >
          <li
            v-for="(option, index) in options"
              :key="index"
              class="relative cursor-pointer ml-0 px-3 py-[10px] transition-colors duration-300 hover:bg-secondary"
              @mousedown.prevent="setOption(option, index)"
          >
            <div v-if="index == activeOption" class="w-[6px] absolute top-50 left-2 h-[50%] bg-primary rounded-md"></div>
            <span class="ml-4 text-[16px] md:text-[18px]"> {{ option }}</span>
          </li>
        </ul>
      </transition>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
export default {
 props:['options',"isCleargender"],
 emits:['onEmitOption'],
 setup(props, {emit}) {
    const isOptionsExpanded = ref(false)
    const selectedOption = ref(null)
    const activeOption = ref(null);

    watchEffect(()=>{
      if(props.isCleargender){
        selectedOption.value=null;
      }
    })

    const setOption = (option, index) => {
      selectedOption.value = option;
      isOptionsExpanded.value = false;
      activeOption.value = index;
      emit("onEmitOption", selectedOption.value);
    }
    return {
        isOptionsExpanded,
        selectedOption,
        setOption,
        activeOption
    }
  }
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(.61,-0.53,.43,1.43);
}
</style>
