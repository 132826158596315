<template>
    <TransitionRoot appear :show="true" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-40 font-">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto font-poppins">
                <div class="flex min-h-full items-start justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="w-full font-notoKhmer max-w-xl transform overflow-hidden rounded-2xl bg-white text-tertiary p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle as="h3"
                                class="text-2xl font-semibold leading-6 text-tertiary flex justify-between items-center">
                                <span v-if="!doc">Create new course category</span>
                                <span v-else>Update course category</span>
                                <!-- <span v-if="id != null" class="text-2xl">កែប្រែប្រភេទរូបិយប័ណ្ណ</span>  -->
                                <span @click="closeModal" class="text-red-600 cursor-pointer">
                                    <font-awesome-icon size="lg" icon="fa-solid fa-circle-xmark" />
                                </span>
                            </DialogTitle>
                            <div class="mt-8">
                                <form @submit.prevent="handleCourseCategory" class="my-4">
                                    <div class="mb-3">
                                        <label for="category-name" class="text-lg text-quaternary">Category name <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="categoryName"
                                            class="form-control w-full placeholder:text-quaternary-light"
                                            id="category-name" type="text" placeholder="Category name" required>
                                    </div>

                                    <div class="flex items-center justify-start space-x-2 text-base lg:text-lg">
                                        <Switch v-model="status" :class="status ? 'bg-primary' : 'bg-gray-200'"
                                            class="relative inline-flex h-6 w-11 items-center rounded-full">
                                            <span :class="status ? 'translate-x-6' : 'translate-x-1'"
                                                class="inline-block h-4 w-4 transform rounded-full bg-white transition" />
                                        </Switch>

                                        <span>{{ status ? 'Active' : 'Inactive' }}</span>

                                    </div>
                                    <div class="flex items-center justify-end">
                                        <button type="submit" class="btn btn-primary rounded-full">{{ doc ? 'Update' :
                                            'Add' }}</button>
                                    </div>
                                </form>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Switch
} from '@headlessui/vue';
import { onMounted, ref } from 'vue';
import { push } from 'notivue';
import useCollection from '@/composables/useDocument';
import { serverTimestamp } from 'firebase/firestore';
import { generateDocCode } from '@/composables/generateDocumentCode';
export default {
    emits: ['closeModal','handleAddSuccess','handleUpdateSuccess'],
    props: ['doc'],
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        Switch
    },

    setup(props, { emit }) {

        const categoryName = ref("");
        const status = ref(true);
        const timeStamp = ref("");
        timeStamp.value = serverTimestamp();



        const closeModal = () => {
            emit('closeModal', false);
        }


        const { addDocs, updateDocs } = useCollection("course-categories");
        const handleCourseCategory = async () => {


            if (props.doc) {
                try {
                    const dataUpdate = {
                        categoryName: categoryName.value,
                        categoryNameLowerCase: categoryName.value.toLowerCase(),
                        status: status.value,
                       
                    }
                    const success = await updateDocs(props.doc.id, dataUpdate);
                    if (success) {
                        emit('handleUpdateSuccess')
                        closeModal();
                    }
                } catch (err) {
                    push.error("Something went wrong! please try again!")
                }
            } else {
                try {
                    const code = await generateDocCode("course-categories", "C-", "categoryCode", 3);
                    const dataAdd = {
                        categoryCode: code,
                        categoryName: categoryName.value,
                        categoryNameLowerCase: categoryName.value.toLowerCase(),
                        status: status.value,
                        createdAt: timeStamp.value
                    }
                    const success = await addDocs(dataAdd);
                    if (success) {
                       emit('handleAddSuccess');
                        closeModal();
                    }
                } catch (err) {
                    push.error("Something went wrong! please try again!")
                }
            }
        }

        onMounted(() => {
            if (props.doc) {
                categoryName.value = props.doc?.categoryName;
                status.value = props.doc?.status;
            }
        })

        return {
            categoryName,
            status,
            handleCourseCategory,
            closeModal
        }
    }
}
</script>