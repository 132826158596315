
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject,getMetadata } from 'firebase/storage';
import { projectStorage } from '@/firebase/config';

const useStorage = () => {
  const uploadImage = async (storagePath, imageFile) => {
    const imageRef = storageRef(projectStorage, storagePath);

    try {
      // Upload the file
      await uploadBytes(imageRef, imageFile);

      // Get the download URL of the uploaded image
      const imageURL = await getDownloadURL(imageRef);

      return imageURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error; 
    }
  };
  const removeImage = async (storagePath) => {
    const imageRef = storageRef(projectStorage, storagePath);

    try {
      // Get metadata of the file
      const metadata = await getMetadata(imageRef);
      
      // Check if the file exists
      if (metadata.size !== 0) {
        // If the file exists, delete it
        await deleteObject(imageRef);
        console.log('Image deleted successfully.');
      } else {
        // If the file doesn't exist, log a message
        console.log('Image does not exist in storage.');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
    }
  };

  return { uploadImage, removeImage };
};

export default useStorage;
