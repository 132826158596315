<template>
  <div class="animate-fade-up animate-duration-300 text-tertiary">
    <BreadCrumbsVue path="User submission"/>

    <div>
        <h1 class="font-semibold mb-4 text-xl md:text-2xl">User Submission Page</h1>

        <TabGroup as="div">
                <TabList as="div" class="grid grid-cols-3 text-lg place-items-center w-full max-w-2xl">
                
                    <Tab :class="isTabActiveIndex == 1? 'text-primary font-semibold  border-primary' : 'bg-tranparent border-gray-400'" as="div" class="w-full transition-all outline-none active:scale-90 flex space-x-2 border-b-4 text-xl justify-center cursor-pointer text-black focus:outline-none" @click="handleActiveTab(1)">
                        <span>All submission</span>
                    </Tab>
                    <Tab :class="isTabActiveIndex == 2? 'text-primary  font-semibold border-primary' : 'bg-tranparent border-gray-400'" as="div" class="w-full transition-all outline-none active:scale-90 flex space-x-2 border-b-4 text-xl justify-center cursor-pointer text-black focus:outline-none" @click="handleActiveTab(2)">
                       
                         <span>Via Join Course</span>
                    </Tab>
                    
                   
                    <Tab :class="isTabActiveIndex == 3 ? 'text-primary font-semibold  border-primary' : 'bg-tranparent border-gray-400'" as="div" class="w-full transition-all outline-none active:scale-90 flex space-x-2 border-b-4 text-xl justify-center cursor-pointer text-black focus:outline-none" @click="handleActiveTab(3)">
                       
                        <span>Via Inbox</span>
                    </Tab>
                </TabList>

                <TabPanels as="div" class="mt-5">
                    <TabPanel as="div" class="animate-fade-up animate-once animate-duration-[400ms]">
                        <AllUserSubmissionVue/>
                    </TabPanel>
               
                    <TabPanel as="div" class="animate-fade-up animate-once animate-duration-[400ms]">
                        <UserSubmissionViaJoinCourseVue />
                    </TabPanel>
                    <TabPanel as="div" class="animate-fade-up animate-once animate-duration-[400ms]">
                        <UserSubmissionViaInboxVue/>
                    </TabPanel>
                </TabPanels>
        </TabGroup>

    </div>
  </div>
</template>

<script>
import BreadCrumbsVue from '@/components/admin/ui/BreadCrumbsVue.vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { ref } from 'vue';
import AllUserSubmissionVue from '@/components/admin/AllUserSubmissionVue.vue';
import UserSubmissionViaInboxVue from '@/components/admin/UserSubmissionViaInbox.vue';
import UserSubmissionViaJoinCourseVue from '@/components/admin/UserSubmissionViaJoinCourse.vue';
export default {
  components:{
    BreadCrumbsVue,
    AllUserSubmissionVue,
    UserSubmissionViaInboxVue,
    UserSubmissionViaJoinCourseVue,
    TabGroup,
    TabList,
    Tab,
    TabPanel,
    TabPanels
  },
  setup(){
      const isTabActiveIndex = ref(1);
      const handleActiveTab = (tabIndex) => {
          isTabActiveIndex.value = tabIndex;
      }

     
       
      return{
        isTabActiveIndex,
        handleActiveTab
      }

  }
}
</script>

<style>

</style>