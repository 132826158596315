<template>
    <div class="animate-fade-up animate-duration-300 font-poppins">
        <BreadCrumbsVue path="Company Info" />

        <div class="space-y-4">
            <h1 class="font-semibold text-xl md:text-2xl">Company Info</h1>

            <form @submit.prevent="handleAddandUpdateCompanyInfo" class="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div>
                    <label for="name" class="text-lg text-quaternary ">Name<span class="text-red-600">*</span></label>
                    <input v-model="name" class="form-control w-full cursor-pointer" id="name" type="text"
                        placeholder="Company name" required>
                </div>
                <div>
                    <label for="phone" class="text-lg text-quaternary ">Phone<span class="text-red-600">*</span></label>



                    <div class="space-y-3">
                        <input v-model="phone" @keydown.space.prevent="addPhone"
                            class="form-control w-full cursor-pointer" id="phone" type="text" placeholder="Phone">
                        <div class="space-y-2">

                            <span v-for="(displayedPhone, index) in displayPhones" :key="index"
                                class="rounded bg-gray-200 text-gray-700 px-2 py-1 inline-block mr-2">{{ displayedPhone
                                }}
                                <button type="button" @click="removePhone(index)"
                                    class="text-red-600 ml-1">x</button></span>
                        </div>
                    </div>

                </div>

                <div>
                    <label for="address" class="text-lg text-quaternary ">Address<span
                            class="text-red-600">*</span></label>
                    <textarea v-model="address" class="form-control w-full cursor-pointer" id="address" type="text"
                        placeholder="Type your address here..." required></textarea>
                </div>
                <div>
                    <label for="legacy" class="text-lg text-quaternary ">Legacy<span
                            class="text-red-600">*</span></label>
                    <textarea v-model="legacy" class="form-control w-full cursor-pointer" id="legacy" type="text"
                        placeholder="Write your legacy here..." required></textarea>
                </div>
                <div>
                    <label for="account" class="text-lg text-quaternary ">Social Account<span
                            class="text-red-600">*(FB,Telegram,Email,Youtube)</span></label>

                    <div class="space-y-3">
                        <input v-model="socialAccount" @keydown.space.prevent="addSocialAccount"
                            class="form-control w-full cursor-pointer" id="account" type="text"
                            placeholder="Social account">
                        <div class="space-y-2">

                            <span v-for="(s, index) in displaySocialAccount" :key="index"
                                class="rounded bg-gray-200 text-gray-700 px-2 py-1 inline-block mr-2">{{ s
                                }}
                                <button type="button" @click="removeSocialAccount(index)"
                                    class="text-red-600 ml-1">x</button></span>
                        </div>
                    </div>
                </div>
                <div>
                    <label for="mapURL" class="text-lg text-quaternary ">Map URL<span
                            class="text-red-600"></span></label>
                    <input v-model="mapURL" class="form-control w-full cursor-pointer" id="mapURL" type="text"
                        placeholder="Paste your map url here..." >
                </div>

                <div class="col-span-1 md:col-span-2">
                    <label for="history" class="text-lg text-quaternary ">History<span
                            class="text-red-600">*</span></label>
                    <textarea v-model="history" class="form-control w-full cursor-pointer" id="history" type="text"
                        placeholder="Write your history here..." required></textarea>
                </div>
                <div class="col-span-1 md:col-span-2 flex justify-end">
                    <button type="submit" class="btn btn-primary rounded-full">{{ id ? 'Update' : 'Add' }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import BreadCrumbsVue from '@/components/admin/ui/BreadCrumbsVue.vue';
import useCollection from '@/composables/useDocument';
// import CompanyInfoModal from '@/components/admin/modal/CompanyInfoModal.vue';
import { onMounted, ref, watch } from 'vue';
import { projectAuth, onAuthStateChanged } from '@/firebase/config';
import { push } from 'notivue'
import { where, serverTimestamp } from 'firebase/firestore';
import { getCollectionQuery } from '@/composables/getCollection';

export default {
    components: {
        BreadCrumbsVue,
        // CompanyInfoModal
    },
    setup() {

        const name = ref("");
        const phone = ref("");
        const arrayPhone = ref([]);
        const displayPhones = ref("");
        const displaySocialAccount = ref("");
        const arraySocialAccount = ref([])
        const address = ref("");
        const legacy = ref("");
        const socialAccount = ref("");
        const mapURL = ref("");
        const history = ref("");
        const currentComponent = ref("");
        const id = ref(null);
        const timestamp = ref(null);
        timestamp.value = serverTimestamp();

        const userID = ref(null);
        const fetchUserAuthentication = () => {
            onAuthStateChanged(projectAuth, async (_user) => {
                if (_user) {
                    userID.value = _user.uid;
                    await getCollectionQuery("companyInfo", [where("userID", "==", userID.value)], (data) => {
                        data.forEach((doc) => {
                            id.value = doc.id
                            name.value = doc.name,
                                arrayPhone.value = doc.phone,
                                arraySocialAccount.value = doc.socialAccount,
                                address.value = doc.address,
                                legacy.value = doc.legacy,
                                history.value = doc.history,
                                mapURL.value = doc.mapURL
                        });
                    },null, true);
                }
            });
        }



        const mountComponent = (component) => {
            currentComponent.value = component;
        }

        const unMountComponent = () => {
            currentComponent.value = '';
        }


        const addPhone = () => {
            if (phone.value.trim() !== '') {
                arrayPhone.value.push(phone.value.trim());
                phone.value = '';
            }
        };

        watch(arrayPhone, (newV) => {
            if (newV) {
                // Join all the phone numbers with a space
                displayPhones.value = newV
                console.log("array phone", newV);
            }
        }, { deep: true });

        const removePhone = (index) => {
            arrayPhone.value.splice(index, 1);
        };


        const addSocialAccount = () => {
            if (socialAccount.value.trim() !== '') {
                if (arraySocialAccount.value.length < 4) {
                    arraySocialAccount.value.push(socialAccount.value.trim());
                    socialAccount.value = '';
                } else {
                    push.error(`Sorry, you can't add more social accounts!`);
                }
            }
        }

        watch(arraySocialAccount, (newV) => {
            if (newV.length <= 4) {
                displaySocialAccount.value = newV;
            } else {
                push.error(`Sorry, you can't add more social accounts!`);

            }
        }, { deep: true });

        const removeSocialAccount = (index) => {
            arraySocialAccount.value.splice(index, 1);
        };






        function handleClearForm() {
         
            name.value = "";
            address.value = "";
            history.value = "";
            mapURL.value = "";
            legacy.value = "";
            history.value = ""
            id.value = null;
            displayPhones.value = "";
            arrayPhone.value = [];
            arraySocialAccount.value = [];

        }
        const handleAddandUpdateCompanyInfo = async () => {
            try {
                if (!id.value) {
                    const data = {

                        userID: userID.value,
                        name: name.value,
                        phone: arrayPhone.value,
                        address: address.value,
                        legacy: legacy.value,
                        mapURL: mapURL.value,
                        history: history.value,
                        socialAccount: arraySocialAccount.value,
                        createdAt: timestamp.value

                    }
                    const { addDocs } = useCollection("companyInfo");
                    const success = await addDocs(data);
                    if (success) {
                        push.success("Data company info has been added successfully!");
                        handleClearForm();
                    }
                } else {

                    const data = {
                        name: name.value,
                        phone: arrayPhone.value,
                        address: address.value,
                        legacy: legacy.value,
                        mapURL: mapURL.value,
                        history: history.value,
                        socialAccount: arraySocialAccount.value,
                        createdAt: timestamp.value

                    }
                    const { updateDocs } = useCollection("companyInfo");
                    const success = await updateDocs(id.value, data);
                    if (success) {
                        push.success("Data company info has been updated successfully!");
                        handleClearForm();
                        fetchUserAuthentication();
                    }
                }

            } catch (err) {
                console.log("failed to add or update data", err);
            }
        }


        onMounted(() => {
            fetchUserAuthentication();
        })


        return {
            id,
            displaySocialAccount,
            addSocialAccount,
            removeSocialAccount,
            handleAddandUpdateCompanyInfo,
            removePhone,
            displayPhones,
            arrayPhone,
            addPhone,
            name, phone,
            address,
            legacy,
            socialAccount,
            mapURL,
            history,
            currentComponent,
            mountComponent,
            unMountComponent
        }
    }
}
</script>

<style></style>