import {
  getDocs,
  query,
  collection,
  where,
  orderBy as firestoreOrderBy,
  limit,
} from "firebase/firestore";
import { projectFirestore } from "@/firebase/config";

//   /**
//    * Function to generate a unique code for a specified collection.
//    * @param {string} collectionName - The name of the target collection.
//    * @param {string} codePrefix - The prefix for the generated code (e.g., "PRO").
//    * @param {string} orderByField - The field by which to order the documents.
//    */
export const generateDocCode = async (
  collectionName,
  codePrefix,
  orderByField,
  paddingLength=3
) => {
  try {
    const targetCollection = collection(projectFirestore, collectionName);

    // Query to get the last code in the specified collection
    const lastCodeQuery = query(
      targetCollection,
      firestoreOrderBy(orderByField, "desc"), // Dynamic orderBy
      limit(1)
    );

    const querySnapshot = await getDocs(lastCodeQuery);

    let lastCodeNumber = 0;

    // Check if the collection is not empty and extract the number from the last code

    if (!querySnapshot.empty) {
      const lastCode = querySnapshot.docs[0].data()[orderByField];
      lastCodeNumber = parseInt(lastCode.replace(codePrefix, ''), 10); // Parse directly as an integer
    
    }

 
  

    // Increment the code number and pad it with zeros
    const newCodeNumber = lastCodeNumber + 1;
    const paddedCodeNumber = newCodeNumber
      .toString()
      .padStart(paddingLength, "0");

    // Generate the new code
    const code = `${codePrefix}${paddedCodeNumber}`;

    // Query to check if the code already exists in the specified collection
    const codeQuery = query(targetCollection, where(orderByField, "==", code));
    const codeQuerySnapshot = await getDocs(codeQuery);

    // If the code already exists, recursively call the function to generate a new one
    if (!codeQuerySnapshot.empty) {
      return generateDocCode(
        collectionName,
        codePrefix,
        orderByField
      );
    }

    // If the code doesn't exist, return the generated code
    return code;
  } catch (error) {
    console.error("Error generating code:", error);
    return null;
  }
};
