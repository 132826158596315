<template>
    <div>
        <div class="max-w-[90%] xl:max-w-[1000px] mx-auto">

            <!-- === For Tablet and Desktop -->
            <div class="hidden md:block">
                <div class="my-8">
                    <h2 class="uppercase text-center text-[24px] mb-4 font-medium text-tertiary">Our Courses</h2>
                    <p class="text-quaternary text-center">
                       We provide the best course for you everywhere , everytime .
                    </p>
                </div>

                <div v-for="category in courseCategories" :key="category.id">

                    <ArrowShapeVue>{{ category.categoryName }}</ArrowShapeVue>
                    <div>
                        <div class="mt-5">
                            <div v-if="category.allCourse?.length != 0">
                                <CardVue :allCourse="category.allCourse"  />
                            </div>

                            <div v-else>
                                 <p class="my-2 mx-4">I apologize, but there are currently no courses offered in this category.</p>
                            </div>
                        </div>

                        <div v-show="category.allCourse?.length != 0" class="my-4 flex justify-end">
                            <router-link  :to="{ name: 'by-course-category-page', params: { id: category.id } }">
                                       <button class="rounded-full md:rounded-md btn  lg:px-[30px] uppercase space-x-2">
                                <span>See more</span>
                                <span><font-awesome-icon icon="fa-solid fa-arrow-right" size="sm" /></span>
                            </button>
                            </router-link>
                     
                        </div>
                    </div>
                </div>

            </div>


            <!-- === For Mobile ==== -->

            <div class="block md:hidden pb-10">
                <div class="my-8">
                    <h2 class="uppercase text-center text-[24px] mb-4 font-medium text-tertiary">Our Courses</h2>
                    <p class="text-quaternary text-center">
                        We provide the best course for you everywhere , everytime .
                    </p>
                </div>

                <div class="flex space-x-4 my-4 min-w-full overflow-x-scroll py-2 px-1">

                    <button @click="handleActiveCategory(category.id)" 
                        v-for="(category,i) in courseCategories " :key="i" 
                        class="btn-outline-cate text-nowrap capitalize" :class="activeCategoryIndex == category.id && 'outline-primary text-primary'">
                        {{ category.categoryName }}
                    </button>

                </div>

                <div class="grid grid-cols-1 gap-5">
                    <div v-if="courseByCategory.length > 0" >
                        <MobileCardVue :allCourse="courseByCategory" />
                    </div>

                    <div v-else class="animate-fade-up animate-duration-300">    
                        <p class="text-center py-2">No course has been found!</p>
                    </div>
                    
                </div>



                <div v-if="courseByCategory.length > 0" class="flex justify-center space-x-4 my-4">
                    <button @click="loadPreviousPage" 
                            :disabled="currentPage == 1"  
                            :class="currentPage == 1 && 'cursor-not-allowed'"
                            class="btn flex items-center space-x-1">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                            </svg>

                        </span>
                        <span>Prevoius</span>
                    </button>
                    <button class="btn rounded-full">
                        {{currentPage}}
                    </button>
                    <button 
                        @click="loadNextPage" 
                        :disabled="currentPage == totalPage"
                        :class="currentPage == totalPage && 'cursor-not-allowed'"
                        class="btn flex items-center space-x-1">
                        <span>Next</span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                            </svg>
                        </span>
                    </button>
                </div>

                <div class="my-20">
                    <h4 class="text-base sm:text-[24px]">You can view more Category</h4>

                    <div class="flex flex-wrap gap-4 my-4">
                        <button @click="showCourseByCate(c.id)" v-for="c in courseCategoriesOption" :key="c.id" 
                            class="btn-outline capitalize">
                            {{c.categoryName}}                          
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import CardVue from '@/components/CardVue.vue'
import ArrowShapeVue from '@/components/ArrowShapeVue.vue'
import { ref, onMounted, watch, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
//import getCollectionPaginate from '@/composables/getCollectionPaginate';
import {  orderBy, where,limit, documentId } from 'firebase/firestore';
import { getCollectionQuery } from '@/composables/getCollection';
import MobileCardVue from '@/components/MobileCardVue.vue';
import getCollectionPaginate from '@/composables/getCollectionPaginate2';
export default {
    components: {
        CardVue,
        ArrowShapeVue,
        MobileCardVue,
    },


    setup() {
        const route = useRoute();
        const router = useRouter();
        const allCourse = ref(route.params.allCourse);
        const courseCategories = ref([]);
        const courseCategoriesOption = ref([]);
        const activeCategoryIndex = ref('');
        const courseByCategory = ref([]);
        const paginateData = ref([]);
        const currentPage = ref(1);
        const totalPage = ref(null);
        const pageSize = ref(10);
       
        const fetchCategories = async () => {
            try {
                await getCollectionQuery("course-categories", [orderBy("createdAt"), where("status", "==", true)], (data) => {
                    courseCategories.value = data;
                    handleActiveCategory(courseCategories.value[0].id);
                    data.map(async (c) => {

                        await getCollectionQuery("courses", [where("categoryID", "==", c.id),limit(6),orderBy("createdAt"),where("status","==",true)], (data) => {
                            let temp = courseCategories.value;
                            temp.forEach((cate, index) => {
                                if (cate.id === c.id) {
                                    courseCategories.value[index].allCourse = data;
                                }
                            })  
                        },null, true);
                    })
                    
                    
                
                },null, true);
                
            
            } catch (err) {
                console.log("failed to fetch data", err)
            }
        }

        const handleActiveCategory = (id) => {
             activeCategoryIndex.value = id;
        }

        const showCourseByCate = async (catID) => {
            const path = 'by-course-category-page'; 
            const params = { id: catID }; 
            router.push({name:path , params });
        }

        watch(activeCategoryIndex, async (newV) => {
            if(newV){
                paginateData.value =  getCollectionPaginate("courses",[where("categoryID", "==", newV),where("status","==",true), orderBy("createdAt"), limit(pageSize.value)],(data) => {
                    courseByCategory.value = data;
                    console.log(courseByCategory.value)
                })

                
                const totalCourses = await paginateData.value.getCollectionLength("courses", [where("categoryID", "==", newV)]);

                totalPage.value = Math.ceil(
                    totalCourses / pageSize.value
                );
            }
        })

        watch(activeCategoryIndex, async (newV) => {
            if(newV){
                await getCollectionQuery("course-categories",[where(documentId(), "!=", newV)], (data) => {
                    courseCategoriesOption.value = data;
                });
            }
        })

        const loadPreviousPage = async () => {
            try {
                const firstDocument = courseByCategory.value[0];
                if (firstDocument) {
                    await paginateData.value.previousPage(firstDocument.id, 2);
                    currentPage.value -= 1;
                    
                }
            } catch (err) {

                console.error("Error fetching previous page:", err);
            }
        };

        const loadNextPage = async () => {
            try {
                const lastDocument = courseByCategory.value[courseByCategory.value.length - 1]
                if (lastDocument) {
                    await paginateData.value.nextPage(lastDocument.id, 2);
                    currentPage.value += 1;
                }
            } catch (err) {
                console.error("Error fetching next page:", err);
            }
        };


        // console.log(courseCategories)
        onMounted(() => {
            fetchCategories()
           
        })

        return {
            courseCategories,
            allCourse,
            activeCategoryIndex,
            courseByCategory,
            courseCategoriesOption,
            currentPage,
            totalPage,
            handleActiveCategory,
            loadNextPage,
            loadPreviousPage,
            showCourseByCate

        }
    }
}
</script>

<style></style>