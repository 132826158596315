<template>
    <div class="my-8">
        <h2 class="uppercase text-center text-[24px] mb-8 font-medium text-tertiary">contact us</h2>

        <div class="bg-primary text-white">
            <div class="max-w-[90%] xl:max-w-[900px] w-full mx-auto">
                    <div class="py-10 space-y-2">
                          <h2 class="uppercase text-[24px] font-medium">get in touch</h2>
                          <p>Want to get in touch? We would love to hear from you. Here how you can reach us...</p>
                          
                          <div>
                                <span class="font-semibold">Address: </span>
                                <span>{{companyInfo?.address}}</span>
                          </div>
                          <div>
                                <span class="font-semibold">Email: </span>
                                <a :href="`mailto:${companyInfo?.socialAccount?.[2]}`" class="hover:underline text-white">{{ companyInfo?.socialAccount?.[2]}}</a>
                          </div>


                          <div class="py-4 h-[500px] relative">
                                    <div class="hidden md:block px-20 h-[400px] mx-auto rounded-2xl overflow-hidden">
                                            <img class="w-full h-full object-cover bg-center rounded-2xl" src="https://images.unsplash.com/photo-1544717297-fa95b6ee9643?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                                    </div>

                                    <div class="absolute top-5 md:top-1/2  left-0 w-full">
                                            <div class="grid  grid-cols-1 gap-5 md:grid-cols-2 text-tertiary">
                                                   <div class="bg-white h-fit md:h-[250px] p-4 rounded-2xl">
                                                        <div class="text-quaternary w-12 h-12 grid place-items-center p-2 mx-auto border border-quaternary-low-light m-2 rounded-full">
                                                            <font-awesome-icon icon="fa-solid fa-phone" size="lg" />
                                                        </div>
                                                        <div class="space-y-4">
                                                            <h2 class="text-lg md:text-[28px] text-primary font-semibold text-center">Call us</h2>
                                                            <p class="text-center text-base md:text-lg">This is the rule to contact us</p>
                                                            <div class="space-y-2 text-center">
                                                                <a :href="`tel:${p}`" v-for="(p, i) in companyInfo?.phone" :key="i" class="text-base md:text-lg font-bold">{{ p }}</a>
                                                            </div>
                                                        </div>
                                                   </div>
                                                   <div class="bg-white h-fit md:h-[250px] p-4 rounded-2xl">
                                                         <div class="text-quaternary w-12 h-12 grid place-items-center p-2 mx-auto border border-quaternary-low-light m-2 rounded-full">
                                                            <font-awesome-icon icon="fa-solid fa-bezier-curve" size="lg" />
                                                         </div>
                                                         <div class="space-y-4">
                                                            <h2 class="text-lg md:text-[28px] text-primary font-semibold text-center">Social Media</h2>
                                                
                                                            <div class="">
                                                                <p class="ml-[50px] text-left flex flex-col gap-2 text-base md:text-lg font-semibold">
                                                                    <a  :href="companyInfo.socialAccount?.[1]" class="space-x-4">
                                                                            <font-awesome-icon icon="fa-brands fa-telegram" size="lg"/>
                                                                            <span>{{ companyInfo?.phone?.[0] }}</span>
                                                                    </a>

                                                                    <a :href="companyInfo.socialAccount?.[0]" class="space-x-4">
                                                                            <font-awesome-icon icon="fa-brands fa-facebook" size="lg"/>
                                                                            <span>Feng Shui</span>
                                                                    </a>
                                                                    <!-- <a href="" class="space-x-4">
                                                                        <font-awesome-icon icon="fa-brands fa-facebook-messenger" size="lg"/>
                                                                        <span>Master IT System Battambang</span>
                                                                    </a> -->
                                                                </p>
                                                            </div>
                                                        </div>
                                                   </div>
                                            </div>
                                    </div>  
                          </div>
                    </div>
            </div>
        </div>

        <div class="bg-secondary-light text-tertiary py-4">
            <div class="max-w-[90%] xl:max-w-[900px] w-full mx-auto">
                    <h2 class="text-base md:text-lg font-semibold">Where to find us</h2>
                    <div class="w-full my-4">
                        <iframe :src="companyInfo?.mapURL" class="w-full" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
            </div>
        </div>

        <div class="bg-white text-tertiary py-6">
            <div class="max-w-[90%] md:max-w-[600px] w-full mx-auto">
                    <h2 class="text-lg my-4 text-center md:text-2xl text-primary font-semibold">You can ask question here!</h2>
                    <form @submit.prevent="handleSubmitInbox">
                        <div class="mb-3">
                            <label for="" class="text-quaternary ml-4 block">Name <span class="text-blood ">*</span></label>
                            <input required v-model="name" type="text" class="form-control w-full" placeholder="Enter name">
                        </div>     
                        <div class="mb-3 grid grid-cols-1 md:grid-cols-2 gap-5">
                            <div>
                                <label for="" class="text-quaternary ml-4 block">Phone number <span class="text-blood ">*</span></label>
                                <input v-model="phoneNumber" required type="text" class="form-control w-full" placeholder="Phone number">
                            </div>  
                            <div>
                                <label for="" class="text-quaternary ml-4 block">Telegram</label>
                                <input v-model="telegram" type="text" class="form-control w-full" placeholder="Telegram">
                            </div>  
                        </div>
                        <div class="mb-3">
                            <label for="" class="text-quaternary ml-4 block">Province <span class="text-blood ">*</span></label>
                            <input v-model="province" required type="text" class="form-control w-full" placeholder="Your province">
                        </div>  
        
                        <div class="mb-3">
                            <label for="" class="text-quaternary ml-4 block">Comment <span class="text-blood ">*</span></label>
                            <textarea v-model="comment" required class="form-control w-full" rows="5" placeholder="Your comment"></textarea>
                        </div>  
        
                        <div>
                              <button type="submit" class="btn btn-primary rounded-full px-10">Send</button>
                        </div>
                    </form>
            </div>
        </div>

        <Notivue v-slot="item">
            <Notification :item="item" />
        </Notivue>
    </div>

   


</template>

<script>
import useCollection from '@/composables/useDocument';
import { onMounted, ref } from 'vue';
import { serverTimestamp } from 'firebase/firestore';
import { Notivue, Notification, push } from 'notivue'
import { getCollectionQuery } from '@/composables/getCollection';

export default {
    components:{
        Notivue, Notification
    },
    setup(){
        const name=ref("");
        const phoneNumber=ref("");
        const telegram=ref("");
        const province=ref("");
        const comment=ref("");
        const timestamp=ref(null);
        timestamp.value=serverTimestamp();

        const handleSubmitInbox=async()=>{
            try{
                const data={
                    courseID:null,
                    name:name.value,
                    nameLowerCase:name.value.toLowerCase(),
                    telegram:telegram.value,
                    phone:phoneNumber.value,
                    comments:comment.value,
                    province:province.value,
                    status:false,
                    createdAt:timestamp.value,
                    submitType:"Via Inbox"
                }

                const {addDocs}=useCollection("userSubmissions");
                const success=await addDocs(data);
                if(success){
              
                    push.success("Congratulations ! Your data has been submit successfully.");
                    console.log("data submit success")
                    handleClearForm();
                 
                }

            }catch(err){
                console.log("failed o add data",err);
            }
        }


        const handleClearForm=()=>{
            name.value="",
            phoneNumber.value="",
            comment.value="",
            province.value="";
            telegram.value="";
        }

        const companyInfo = ref({});
        // const phone = ref([]);
        // const socialAccount = ref([]);

        const fetchCompanyInfo = async () => {
           await getCollectionQuery("companyInfo",[],(data) => {
                companyInfo.value = data[0];
                console.log(companyInfo.value)
                // phone.value = companyInfo.value.phone;
                // socialAccount.value = companyInfo.value.socialAccount;
            });
        }   

        onMounted(() => {
             fetchCompanyInfo()
        })

        return{
            handleSubmitInbox,
            name,
            phoneNumber,
            telegram,
            province,
            comment,
            companyInfo
     

        }



        
    }

}
</script>

<style>

</style>