<template>
    <div class="font-poppins animate-fade-up animate-once animate-duration-[400ms]">
      <div class="space-y-5">
        <div class="font-bold text-xl">Welcome, to Feng Shui Astrology Center</div>
  
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-x-5 gap-y-10">
            <div class="border-2 border-primary rounded-lg">
            <div class="flex items-center justify-between text-white bg-gradient-to-tr from-primary to-primary/80 text-btcha p-5">
              <div>
                <font-awesome-icon icon="fa fa-user" size="2xl" />
              </div>
  
              <div class="flex flex-col items-end">
                <div class="text-hd1 font-bold">{{ dataUserPredict.length }}</div>
                <div>User Prediction</div>
              </div>
            </div>
  
            <div class="flex items-center justify-between px-5 py-2.5 text-primary font-medium cursor-pointer">
              <!-- <div>View all</div> -->
  
              <div>
                <font-awesome-icon icon="fa fa-circle-arrow-right" />
              </div>
            </div>
            </div>
         
            <div class="border-2 border-blood rounded-lg">
            <div class="flex items-center text-white justify-between bg-gradient-to-r from-blood to-blood/80 text-btcha p-5">
              <div>
                <font-awesome-icon icon="fa fa-book" size="2xl" />
              </div>
  
              <div class="flex flex-col items-end">
                <div class="text-hd1 font-bold">{{ totalCourse.length}}</div>
                <div>Total Courses</div>
              </div>
            </div>
  
            
            </div>


            <div class="border-2 border-green-400 rounded-lg">
            <div class="flex items-center text-white justify-between bg-green-500 text-btcha p-5">
              <div>
                <font-awesome-icon icon="fa fa-user-group" size="2xl" />
              </div>
  
              <div class="flex flex-col items-end">
                <div class="text-hd1 font-bold">{{ totalUsersubmission.length}}</div>
                <div>User Submission</div>
              </div>
            </div>
  
            
            </div>
  
         
    
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from "vue";
import { getCollectionQuery } from '@/composables/getCollection';
  
  export default {
    setup() {

      const dataUserPredict=ref([]);  
      const fetchTotalUserPredict=async()=>{
        try{
          await getCollectionQuery("userPrediction",[],(data)=>{
              dataUserPredict.value=data;
          },null,true);
        }catch(err){
          console.log("failed to fetchd data",err);
        }
      }
     
      const totalCourse=ref([]);
      const fetchTotalCourse=async  ()=>{
        try{
          await getCollectionQuery("courses",[],(data)=>{
            totalCourse.value=data;
          },null,true);
        }catch(err){
          console.log("faile to fetch data",err);
        }
      }


      const totalUsersubmission=ref([]);  
      const fetchUSerSubmission=async()=>{
        try{
          await getCollectionQuery("userSubmissions",[],(data)=>{
             totalUsersubmission.value=data;
          },null,true);
        }catch(err){
          console.log("failed to fetch data",err);
        }
      }
   
  
      onMounted(() => {
        
        fetchUSerSubmission();
        fetchTotalCourse();
        fetchTotalUserPredict();
      });
  
      return {
        totalUsersubmission,
        totalCourse,
        dataUserPredict
      
      };
    },
  };
  </script>