<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-40 font-notoSans">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="border-t-8 mt-28 md:mt-0 border-primary w-full font-poppins max-w-3xl transform overflow-hidden rounded-xl bg-white text-tertiary p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle as="h3"
                                class="text-2xl font-semibold leading-6 text-primary text-center">
                                <span>Ask To Join The Course</span>
                            </DialogTitle>
                            <div class="mt-2">
                                 
                                        <div class="flex space-x-2">
                                                <span class="text-quaternary">Course : </span>
                                                <span>{{doc.title}}</span>
                                        </div>
                                        <div class="flex flex-col md:flex-row md:justify-between">
                                                <div class="flex space-x-1">
                                                        <span class="text-quaternary">Price : </span>
                                                        <span class="space-x-2">
                                                                <span class="line-through text-quaternary text-lg">{{doc.discount}}$</span>
                                                                <span class="font-semibold">{{doc.price}}$</span>
                                                        </span>
                                                </div>
                                                <div class="flex space-x-2">
                                                    <span class="text-quaternary">Start on : </span>
                                                    <span>{{formatDate(doc.startDate)}}</span>
                                                </div>
                                        </div>
                                   

                                    <form class="mt-4" @submit.prevent="handleSubmitjoinCourse">
                                            <div class="mb-3">
                                                <label for="" class="text-quaternary ml-4 block">Name <span class="text-blood ">*</span></label>
                                                <input v-model="name" required type="text" class="form-control w-full" placeholder="Enter name">
                                            </div>     
                                            <div class="mb-3 grid grid-cols-1 md:grid-cols-2 gap-5">
                                                <div>
                                                    <label for="" class="text-quaternary ml-4 block">Phone number <span class="text-blood ">*</span></label>
                                                    <input v-model="phoneNumber" required type="text" class="form-control w-full" placeholder="Phone number">
                                                </div>  
                                                <div>
                                                    <label for="" class="text-quaternary ml-4 block">Telegram <span class="text-blood "></span></label>
                                                    <input v-model="telegram"  type="text" class="form-control w-full" placeholder="Telegram">
                                                </div>  
                                            </div>
                                            <div class="mb-3">
                                                <label for="" class="text-quaternary ml-4 block">Province <span class="text-blood ">*</span></label>
                                                <input v-model="province" required type="text" class="form-control w-full" placeholder="Your province">
                                            </div>  

                                            <div class="mb-3">
                                                <label for="" class="text-quaternary ml-4 block">Comment <span class="text-blood "></span></label>
                                                <textarea v-model="comment" class="form-control w-full" rows="5" placeholder="Your comment"></textarea>
                                            </div>  

                                            <div class="flex space-x-3">
                                                <button type="submit" class="btn btn-primary rounded-full px-10">Send</button>
                                                <button @click="closeModal" type="button" class="btn rounded-full px-10">Cancel</button>
                                            </div>
                                    </form>


                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
            <Notivue v-slot="item">
                <Notification :item="item" />
            </Notivue>
        </Dialog>
    </TransitionRoot>

</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue';
import {  ref, watchEffect } from 'vue';
import moment from 'moment';
import { serverTimestamp } from 'firebase/firestore';
import useCollection from '@/composables/useDocument';

import { Notivue, Notification ,push} from 'notivue'
export default {
    emits: ['closeModal'],
    props: ['doc', 'isOpen'],
    components: {
        Notivue, Notification,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
  
    },

    setup(props, { emit }) {

        const name=ref("");
        const phoneNumber=ref("");
        const telegram=ref("");
        const province=ref("");
        const comment=ref("");
        const timestamp=ref(null);
        timestamp.value=serverTimestamp();
        const courseID=ref(null);

        watchEffect(()=>{
            if(props.doc){
                courseID.value=props.doc.id;
            }
        })




        const handleSubmitjoinCourse=async()=>{
            try{
                const data={
                    courseID:courseID.value,
                    name:name.value,
                    nameLowerCase:name.value.toLowerCase(),
                    telegram:telegram.value,
                    phone:phoneNumber.value,
                    comments:comment.value,
                    province:province.value,
                    status:false,
                    createdAt:timestamp.value,
                    submitType:"Via JoinCourse"
                }

                const {addDocs}=useCollection("userSubmissions");
                const success=await addDocs(data);
                if(success){
              
                    push.success("Congratulations ! Your data has been submit successfully.");
                    handleClearForm();
                    closeModal();
                }

            }catch(err){
                console.log("failed o add data",err);
            }
        }

        const handleClearForm=()=>{
            name.value="",
            phoneNumber.value="",
            comment.value="",
            province.value="";
            telegram.value="";
        }


        const closeModal = () => {
            emit('closeModal', false);
        }

        const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD-MMM-YYYY");
            } else {
                return "";
            }
        };






        return {
            handleSubmitjoinCourse,
            name,
            phoneNumber,
            telegram,
            province,
            comment,
            formatDate,
            closeModal
        }
    }
}
</script>