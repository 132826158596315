<template>
    <div class="font-notoKhmer text-xs px-4 font-medium pb-2 select-none hidden-print">
      Copyright&#169; by MasterITSystem - version-1.0
    </div>
</template>
  
<script>
export default {};
</script>

<!-- <style scoped>
@media print {
  .hidden-print {
    display: none;
  }

  .print-header {
    font-size: 20px;
    line-height: 1.5rem /* 24px */;
  }

  #printTable {
    visibility: visible;
    position: absolute;
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
    left: 0;
    top: 0;
  }
}

</style> -->

  