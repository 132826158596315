<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="relative z-40" @close="handleClose">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black/35 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto cursor-move">
        <div class="flex mt-[32px] justify-center overflow-x-auto">
          <TransitionChild as="template" enter="ease-out duration-100"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform transition-all min-w-fit m-5 cursor-default">
              <div class="bg-white font-notoKhmer shadow rounded-lg border border-neutral-300">
                <div class="flex flex-shrink-0 items-center justify-between p-5 border-b border-neutral-300">
                  <h5 class="w-full flex items-center space-x-1 text-lg font-bold capitalize">
                    <span>Confirm to Delete</span>
                  </h5>

                  <div @click="handleClose"
                    class="flex items-center justify-center w-7 h-7 rounded-full hover:bg-red-600 hover:text-white cursor-pointer">
                    <font-awesome-icon icon="fa fa-x" size="sm" />
                  </div>
                </div>

                <div class="flex flex-col sm:items-center justify-center px-4 my-2 font-noto font-medium">
                  <p class="text-base">
                    Are you sure you want to delete this category?
                  </p>

                  <p class=" text-base font-noto">Once you delete, it will be deleted permanenttly</p>
                </div>

                <div class="flex justify-end items-center p-3 space-x-2">

                  <button @click="handleClose" type="button" class="btn bg-gray-300 px-8 py-1.5 rounded-lg font-noto">
                    Close
                  </button>

                  <button type="submit" class="btn text-white bg-red-600 px-8 py-1.5 rounded-lg  font-noto"
                    @click="handleDelete()">
                    Delete
                  </button>

                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import useCollection from '@/composables/useDocument';
import { push } from 'notivue';
import { watchEffect, ref } from "vue";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },
  props: ["doc", "lastID"],
  emits: ["closeModal", "handleDeleteSuccess", "onDeletelastID"],
  setup(props, { emit }) {
    const handleClose = () => {
      emit("closeModal");
    };

    const lastID = ref(null);

    const deletID = ref(null);
    watchEffect(() => {
      if (props.doc) {
        deletID.value = props.doc.id;
      }



      if (props.lastID) {
        lastID.value = props.lastID;
      }
    })

    const { removeDoc } = useCollection("course-categories");
    const handleDelete = async () => {
      try {
        if(lastID.value){

          console.log("condition has last ID");
          emit('onDeletelastID');
          handleClose();
        }else{
          console.log("condition no last ID")
        await removeDoc(deletID.value);
        handleClose();
        emit('handleDeleteSuccess')
        }
    

      } catch (err) {
        push.error("Something went wrong! please try again!")
      }
    }

    return { handleDelete, handleClose };
  },
};
</script>

<style></style>