<template>
    <div class="animate-fade-up animate-duration-300 font-poppins">
        <BreadCrumbsVue path="User submission report" />
        <div class="bg-secondary-light p-4 rounded-md space-y-4  hidden-print">
            <h2 class="text-lg ">Filter Report</h2>

            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-5 max-w-5xl items-center mx-auto">
                <div>
                    <label for="input-partner" class="block text-base font-medium mb-2">Submit Type</label>

                    <select v-model="selectSubmitType" id="input-partner" class="form-control w-full cursor-pointer">
                        <option value="" disabled selected>--Choose--</option>
                        <option value="All">All</option>
                        <option value="Inbox">Inbox</option>
                        <option value="Join Course">Join Course</option>
                    </select>
                </div>

                <div>
                    <label for="input-from-date" class="block text-base font-medium mb-2">Start Date</label>
                    <input v-model="startDate" type="date" id="input-from-date"
                        class="cursor-pointer form-control w-full" />
                </div>
                <div>
                    <label for="input-to-date" class="block text-base font-medium mb-2">End Date</label>
                    <input v-model="endDate" type="date" id="input-to-date"
                        class="form-control cursor-pointer w-full" />
                </div>

                <div class="flex space-x-2 mt-8">
                    <button class="btn btn-primary py-4 w-[100px]" @click="handleFilterReport">Filter</button>
                    <button class="btn w-[100px]" @click="handleClearReport">Clear</button>
                </div>
            </div>

        </div>

        <div class="mt-2" id="printTable">
            <div class="justify-center items-center space-y-1 ">
                <div class="w-40 mx-auto">
                    <img class="w-full h-full object-cover" src="../../assets/logo.png" alt="">
                </div>
                <p class="text-center text-xl font-semibold">User Submission Report</p>
                <p class="text-center">Phone៖ ...</p>
                <p class="text-center">Address៖ ...</p>
            </div>

            <div class="w-full flex justify-end items-center mb-2">

                <button @click="handlePrintReport" class="btn btn-primary flex hidden-print">
                    <span><font-awesome-icon icon="fa-solid fa-print" /></span>
                    <span>Print</span>
                </button>
            </div>

            <div class="relative overflow-x-auto">
                <table class="w-full text-base border-primary text-left">
                    <thead class="uppercase">
                        <tr
                            class="[&>*]:border [&>*]:p-2 [&>*]:border-primary bg-primary font-semibold text-white uppercase">
                            <td class="whitespace-nowrap">Course</td>
                            <td class="whitespace-nowrap">Date</td>
                            <td class=" whitespace-nowrap">Name</td>
                            <td class=" whitespace-nowrap">Telegram</td>
                            <td class=" whitespace-nowrap">Phone</td>
                            <td class=" whitespace-nowrap">Status</td>
                            <td class=" whitespace-nowrap">Submit Type</td>
                            <td class="whitespace-nowrap">Comments</td>
                        </tr>
                    </thead>
                    <tbody v-if="reportData.length > 0">
                        <tr v-for="s in reportData" :key="s.id"
                            class="[&>*]:border-2 [&>*]:p-2  [&>*]:border-primary text-tertiary">
                            <td class="whitespace-nowrap">{{ formatCourseName(s.courseID) }}</td>
                            <td class="whitespace-nowrap text-center">{{ formatDate(s.createdAt) }}</td>
                            <td class="whitespace-nowrap">{{ s.name }}</td>
                            <td class="whitespace-nowrap">{{ s.telegram }}</td>
                            <td class="whitespace-nowrap">{{ s.phone }}</td>
                            <td class="whitespace-nowrap " >
                                <div :class="s.status ? 'text-green-600' : 'text-red-600'"
                                    class="px-1.5 w-fit rounded-full text-white">
                                    <span v-if="s.status" class="flex space-x-1 items-center text-green-600">
                                       
                                        <span>Checked</span>
                                    </span>
                                    <span v-else class="flex space-x-1 items-center text-red-600" >
                                    
                                        <span>unChecked</span>
                                    </span>
                                </div>

                            </td>
                            <td class="whitespace-nowrap">{{ s.submitType }}</td>
                            <td class="whitespace-nowrap">{{ s.comments }}</td>


                        </tr>

                    </tbody>
                    <tbody v-else>
                        <tr class="text-center bg-white [&>*]:border [&>*]:p-2 [&>*]:border-primary">
                            <td colspan="8" class="px-6 py-4 text-center whitespace-nowrap">
                                No data has been found!
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
import BreadCrumbsVue from '@/components/admin/ui/BreadCrumbsVue.vue';
import { onMounted, ref } from 'vue';
import {  orderBy, Timestamp, where } from 'firebase/firestore';
import { getCollectionQuery } from '@/composables/getCollection';
import moment from 'moment';

// import moment from 'moment';
export default {
    components: {
        BreadCrumbsVue
    },
    setup() {

        const startDate = ref(null);
        const endDate = ref(null);
        const selectSubmitType = ref(null);
        const reportData = ref([]);
     
    
        
        


        const handleFilterReport =async () => {
            try {

                const fromDateFormatted = ref(null);
                const toDateFormatted = ref(null);
                const fromDateTimestamp = ref(null);
                const toDateTimestamp = ref(null);

                // Set the date range to the current month
                const currentDate = new Date();
                const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

                // Check if startDate.value and endDate.value are truthy, otherwise use current date
                const startDateValue = startDate.value ? new Date(startDate.value) : firstDayOfMonth;
                const endDateValue = endDate.value ? new Date(endDate.value) : lastDayOfMonth;

                fromDateFormatted.value = startDateValue.toLocaleDateString(
                    "en-US",
                    {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }
                );

                toDateFormatted.value = endDateValue.toLocaleDateString(
                    "en-US",
                    {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }
                );

                fromDateTimestamp.value = Timestamp.fromDate(new Date(fromDateFormatted.value));
                toDateTimestamp.value = Timestamp.fromDate(new Date(toDateFormatted.value));
                if(selectSubmitType.value ==='All'){
                  await getCollectionQuery("userSubmissions",[orderBy("createdAt","desc"), where("createdAt", ">=", fromDateTimestamp.value), where("createdAt", "<=", toDateTimestamp.value)],(data)=>{
                    reportData.value=data;
                    console.log("report daata all",reportData)
                  },null,false);
                }else if(selectSubmitType.value==='Inbox'){
                    await getCollectionQuery("userSubmissions",[orderBy("createdAt","desc"),where("submitType","==","Via Inbox"),where("createdAt", ">=", fromDateTimestamp.value), where("createdAt", "<=", toDateTimestamp.value)],(data)=>{
                    reportData.value=data;
                    console.log("report data via inbox",reportData)
                  },null,false);
                }else if(selectSubmitType.value==='Join Course'){
                    await getCollectionQuery("userSubmissions",[orderBy("createdAt","desc"),where("submitType","==","Via JoinCourse"),where("createdAt", ">=", fromDateTimestamp.value), where("createdAt", "<=", toDateTimestamp.value)],(data)=>{
                    reportData.value=data;
                    console.log("report Data via join course",reportData)
                  },null,false);
                }   
            } catch (err) {
                console.log("failed to fetch report", err);
            }
        }



        const courseData = ref([]);
        const fetchCourses = async () => {
            try {
                await getCollectionQuery("courses", [], (data) => {
                    courseData.value = data;
                }, null, true);

            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const formatCourseName = (ID) => {
            const course = courseData.value.find((c) => c.id === ID);
            return course ? course.title : "";
        };

        const handlePrintReport=()=>{
            window.print();
        }
        const handleClearReport = () => {
            startDate.value = null;
            endDate.value = null;
            selectSubmitType.value = null;
            reportData.value = [];
        }

             //for format Date
             const formatDate = (date) => {
            if (date && date.toDate) {
                return moment(date.toDate()).format("DD/MMM/YYYY h:mm A");
            } else {
                return "";
            }
        };


        onMounted(()=>{
            fetchCourses();
        })

        return {
            formatDate ,
            formatCourseName ,
            handlePrintReport,
            reportData,
            handleClearReport,
            handleFilterReport,
            startDate,
            endDate,
            selectSubmitType
        }
    }
}
</script>
<style scoped>
@media print {
    .hidden-print {
        display: none;
    }

    .print-header {
        font-size: 20px;
        line-height: 1.5rem
            /* 24px */
        ;
    }

    #printTable {
        visibility: visible;
        position: absolute;
        font-size: 1rem
            /* 16px */
        ;
        line-height: 1.5rem
            /* 24px */
        ;
        left: 0;
        top: 0;
    }
}
</style>