import { getFirestore} from "firebase/firestore";
import { getAuth ,onAuthStateChanged} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions  } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC_YO_DZAFWCylKkuXx9nu0pwT17zb9l2o",
  authDomain: "feng-shui-4bea1.firebaseapp.com",
  projectId: "feng-shui-4bea1",
  storageBucket: "feng-shui-4bea1.appspot.com",
  messagingSenderId: "152519145416",
  appId: "1:152519145416:web:18b6eda0c7f87af21672fd"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);



// Initialize Firestore
const projectFirestore = getFirestore(app);


// Initialize Auth
const projectAuth = getAuth(app);

// Initialize Performance Monitoring and get a reference to the service
const performance = getPerformance(app);


// Initialize Storage
const projectStorage = getStorage(app);

// Initialize Functions

const functions= getFunctions(app,"asia-southeast1");




export { projectFirestore,performance,functions,projectAuth,onAuthStateChanged, projectStorage};
