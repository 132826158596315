<template>
    <TransitionRoot appear :show="true" as="template">
        <Dialog as="div" @close="handleCloseModal" class="relative z-40 font-">
            <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
                leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-black/35" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex font-notoKhmer min-h-full items-start justify-center p-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <DialogPanel
                            class="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <DialogTitle as="h3"
                                class="text-2xl font-semibold leading-6 text-tertiary flex justify-between items-center">
                                <span> {{ id ? 'Update user' : 'Create new user' }}</span>


                                <span @click="handleCloseModal" class="text-red-600 cursor-pointer">
                                    <font-awesome-icon size="lg" icon="fa-solid fa-circle-xmark" />
                                </span>
                            </DialogTitle>
                            <div class="mt-8">
                                <form @submit.prevent="handleCreateandUpdateUser"
                                    class="my-4 grid gap-5 grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <label for="title" class="text-lg text-quaternary">Username <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="username" class="form-control w-full" id="title" type="text"
                                            placeholder="Username" required>
                                            <span class="text-red-500 text-sm font-sans" v-if="errorCheckUserName">{{
        errorCheckUserName }}</span>
                                    </div>


                                    <div>
                                        <label for="password" class="text-lg text-quaternary">Password<span
                                                class="text-red-600">*</span></label>
                                        <input v-model="password" class="form-control w-full" id="password"
                                            type="password" placeholder="Password" required>
                                        <span class="text-red-500 text-sm font-sans" v-if="errorCheckPassword">{{
        errorCheckPassword }}</span>
                                    </div>
                                    <div>
                                        <label for="email" class="text-lg text-quaternary">Email <span
                                                class="text-red-600">*</span></label>
                                        <input v-model="email" class="form-control w-full" id="email" type="email"
                                            placeholder="Email" required>
                                        <span class="text-red-500 text-sm font-sans" v-if="errorCheckEmail">{{
        errorCheckEmail }}</span>
                                    </div>
                                    <!-- <div>
                                            <label for="role" class="text-lg text-quaternary">Role <span class="text-red-600">*</span></label>
                                                <input 
                                                    v-model="role"
                                                    class="form-control w-full"
                                                    id="role"
                                                    type="text"
                                                    placeholder="Role"
                                                    required
                                                >
                                        </div> -->
                                    <div class="flex flex-col" v-if="userAuthRole==='SuperAdmin'" >
                                        <label for="role" class="text-lg text-quaternary">Role <span
                                                class="text-red-600">*</span></label>
                                        <select v-model="role" id="role" required
                                            class="form-control w-full px-[20px] py-[12px] rounded-lg cursor-pointer bg-white border-2 border-quaternary-low-light">
                                            <option value="" selected disabled>--Choose Role--</option>
                                            <option v-for="(doc, i) in optionRoles" :key="i" :value="doc.name">
                                                {{ doc.name }}
                                            </option>

                                        </select>
                                    </div>


                                    <div>
                                        <label for="image" class="text-lg text-quaternary">Image<span
                                                class="text-red-600"></span></label>
                                        <div v-if="!image.src">
                                            <label @change="handleUploadImage">
                                                <div
                                                    class="flex border-2 border-quaternary-low-light items-center justify-center bg-quaternary-low-light rounded-md h-20 text-sm cursor-pointer">
                                                    Upload image
                                                </div>

                                                <input type="file" class="hidden" />
                                            </label>
                                        </div>
                                        <div v-else>
                                            <div class="relative group w-full flex justify-center overflow-hidden">
                                                <img class="w-1/2 h-1/2 object-cover object-center p-px border cursor-text"
                                                    :src="image.url ? image.url : image.src" alt="" />
                                                <span @click="handleRemoveImage(index)"
                                                    class="cursor-pointer absolute top-1 right-1 bg-red-600 text-white hover:shadow hover:border w-5 h-5 rounded-full flex items-center justify-center">
                                                    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div v-if="fileError" class="text-red-500 text-xs">{{ fileError }}</div>
                                    </div>
                                    <div>
                                        <label for="bio" class="text-lg text-quaternary">Bio<span
                                                class="text-red-600">*</span></label>
                                        <textarea v-model="bio" class="form-control w-full" id="bio"
                                            placeholder="Write your bio here!" required></textarea>
                                    </div>

                                    <div v-if="userAuthRole==='SuperAdmin'" 
                                        class="col-span-1 md:col-span-2 space-x-2 flex justify-start items-center text-sm md:text-base lg:text-lg">
                                        <Switch v-model="status" :class="status ? 'bg-primary' : 'bg-gray-200'"
                                            class="relative inline-flex h-6 w-11 items-center rounded-full">
                                            <span :class="status ? 'translate-x-6' : 'translate-x-1'"
                                                class="inline-block h-4 w-4 transform rounded-full bg-white transition" />
                                        </Switch>

                                        <span>{{ status ? 'Active' : 'Inactive' }}</span>

                                    </div>

                                    <div class="flex col-span-1 md:col-span-2 items-center justify-end">
                                        <!-- <button class="btn btn-primary rounded-full">Add</button> -->
                                        <button v-if="id == null && !isLoading" type="submit"
                                            class="btn btn-primary  rounded-full">Add</button>
                                        <button v-if="id != null && !isLoading" type="submit"
                                            class="btn btn-primary rounded-full">Update</button>

                                        <button v-if="isLoading===true" type="button"
                                            class="btn btn-primary rounded-full" disabled>
                                            <span class="mr-1">
                                                <svg aria-hidden="true" role="status"
                                                    class="inline w-4 h-4 text-gray-500 animate-spin"
                                                    viewBox="0 0 100 101" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="#E5E7EB"></path>
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {{ id != null ? 'Update' : 'Add' }}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Switch

} from '@headlessui/vue'
import { onMounted, ref, watch } from 'vue';

import { getCollectionQuery } from '@/composables/getCollection';
import useStorage from '@/composables/useStorage';
import useCollection from '@/composables/useDocument';
import { httpsCallable } from "firebase/functions";
import { functions } from "@/firebase/config";
import { where, serverTimestamp } from "firebase/firestore";
// import { push } from 'notivue';

import { projectAuth, onAuthStateChanged } from "@/firebase/config";
import { v4 as uuidv4 } from 'uuid';

export default {
    emits: ['closeModal', 'handleUpdate', 'handleSubmit'],
    props: ['docUpdate'],
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        Switch
    },

    setup(props, { emit }) {
        const username = ref("");
        const email = ref("");
        const password = ref("");
        const role = ref("");
        const bio = ref("");
        const status = ref(true);
        const image = ref({});
        const file = ref('');
        const fileError = ref("");
        const timeStamp = ref("");
        const originalUsername = ref("");
        const originalEmail = ref("");
        const id = ref(null);
        const isLoading = ref(false);
        const imageURL = ref("");
        const optionRoles = ref([
            {
                id: 1,
                name: 'SuperAdmin'
            },
            {
                id: 2,
                name: 'Admin'
            },
            {
                id: 3,
                name: 'Client'
            },

        ]);
        timeStamp.value = serverTimestamp();
        const imageFile = ref(null);

        const types = ["image/png", "image/jpg", "image/jpeg", "image/svg"];


        const handleUploadImage = (e) => {
            const selected = e.target.files[0];
            const limitedSize = 1024 * 1024;

            if (selected.size > limitedSize) {
                fileError.value = 'Size image must be equal or less than 1MB';
                file.value = null;
            } else {
                if (selected && types.includes(selected.type)) {
                    image.value = {
                        name: selected.name,
                        src: URL.createObjectURL(selected),
                    };
                    file.value = selected;
                    fileError.value = null;
                } else {
                    fileError.value = 'Only file of type jpg, jpeg, svg, png are allowed.';
                    file.value = null;
                }

                imageFile.value = selected;

            }
        }

        const handleRemoveImage = () => {
            image.value = {};
            file.value = [];
        }






        const userAuthRole=ref(null);
        const userAuth=ref(null);
        const fetchUserAuthentication = () => {
            onAuthStateChanged(projectAuth, async (_user) => {
                if (_user) {
                    userAuth.value = _user;
                    await getCollectionQuery("users", [where("email", "==", _user.email)], (data) => {
                        data.forEach((user) => {
                            userAuthRole.value = user.role;
                           
                        });
                    },null, true);
                   
                }
            });
        }

        const errorCheckUserName = ref(null);
        const handleCheckUserExistenceByUserName = async () => {
            try {
                const datacheckusernameExists = ref([]);

                await getCollectionQuery("users", [where("username", "==", username.value)], (data) => {
                    datacheckusernameExists.value = data;
                },null, false);


                if (datacheckusernameExists.value.length > 0) {

                    errorCheckUserName.value = "Username already exists!";

                } else {
                    errorCheckUserName.value = "";
                }

            } catch (err) {
                console.log("failed to check  username exist", err);
            }
        }




        const errorCheckEmail = ref(null);
        const handleCheckUserExistenceByEmail = async () => {
            try {
                const checkUserExistenceFunction = httpsCallable(functions, "checkUserExistenceByEmailV2");
                const response = await checkUserExistenceFunction({ email: email.value });
                if (response.data.exists) {

                    errorCheckEmail.value = "Email already exists";


                } else {
                    errorCheckEmail.value = "";
                }

            } catch (err) {
                console.error("Error checking user existence:", err);
            }
        };


        watch(email, (newVal) => {
            if (email.value === originalEmail.value) {
                errorCheckEmail.value = "";
            } else if (newVal) {

                originalEmail.value = "";
                handleCheckUserExistenceByEmail();

            }


        })


        watch(username, (newVal) => {
            if (username.value === originalUsername.value) {
                errorCheckUserName.value = "";
            } else if (newVal) {
                originalUsername.value = "";
                handleCheckUserExistenceByUserName();

            }
        })


        const errorCheckPassword = ref(null);
        const handleCheckuserPasswordLength = () => {
            if (password.value && password.value.length < 6) {
                errorCheckPassword.value = "Password at least must be 6 characters long !"
            } else {
                errorCheckPassword.value = "";
            }
        }


        watch(password, (newVal) => {
            if (newVal) {
                handleCheckuserPasswordLength();
            } else {
                errorCheckPassword.value = "";
            }
        });


        const { setDocs: creatUser, updateDocs: updateUser } = useCollection("users");
        const handleCreateandUpdateUser = async () => {
            try {
                if (!id.value) {

                    const uid = uuidv4(); // Generate a UUID
                    if (errorCheckPassword.value || errorCheckUserName.value || errorCheckEmail.value) {
                        return;
                    } else {

                      
                        isLoading.value = true;

                        if (imageFile.value) {
                            const { uploadImage } = useStorage();
                            const storagePath = `users/${imageFile.value.name}`;
                            imageURL.value = await uploadImage(storagePath, imageFile.value);
                        }


                        const dataUser = {

                            username: username.value,
                            usernameLowerCase: username.value.toLowerCase(),
                            password: password.value,
                            bio: bio.value,
                            email: email.value,
                            imageURL: imageURL.value ? imageURL.value : "",
                            role: userAuthRole.value === 'SuperAdmin' ? role.value : 'Client',
                            status: status.value ? status.value : false,
                            createdAt: timeStamp.value
                        }
                
                        const createUserFunction = httpsCallable(functions, 'createUserV2');
                        const signupPromise = createUserFunction({ email: email.value, password: password.value, uid });
                        const setDocsPromise = creatUser(uid, dataUser);

                        const [signupResponse, setDocsResponse] = await Promise.all([signupPromise, setDocsPromise]);

                        if (setDocsResponse && signupResponse.data.success) {
                            emit('handleSubmit', dataUser);
                            handleCloseModal();
                            clearData();
                            isLoading.value = false
                        }

                    }

                } else {
                    if (errorCheckPassword.value || errorCheckUserName.value || errorCheckEmail.value) {
                        return;
                    } else {


                     
                        if (imageFile.value) {
                            const { uploadImage } = useStorage();
                            const storagePath = `users/${imageFile.value.name}`;
                            imageURL.value = await uploadImage(storagePath, imageFile.value);
                        }

                        isLoading.value = true;


                        const dataUser = {

                            username: username.value,
                            usernameLowerCase: username.value.toLowerCase(),
                            password: password.value,
                            bio: bio.value,
                            email: email.value,
                            imageURL: imageURL.value ? imageURL.value : "",
                            role: role.value,
                            status: status.value,
                          
                        }

                        const updateUserFunction = httpsCallable(functions, 'updateUserV2');
                        const updatePromise = updateUserFunction({ email: email.value, password: password.value, uid: id.value });

                        const updateDocsPromise = updateUser(id.value, dataUser);
                        const [updateResponse, updateDocResponse] = await Promise.all([updatePromise, updateDocsPromise]);

                        if (updateDocResponse && updateResponse.data.success) {
                             clearData();
                            emit('handleUpdate', dataUser);
                           handleCloseModal();
                            isLoading.value = false;


                        }

                    }
                }


            } catch (err) {
                console.log("Failed to add or update user", err);
            }
        }



        const clearData = () => {
            handleRemoveImage();
            id.value = null;
            username.value = null;
            email.value = null;
            password.value = null;
            role.value = null;
            status.value = true;
            imageURL.value = "";
            imageFile.value = "";
            bio.value = ""

        }

        const handleCloseModal = () => {
            clearData();
             emit('closeModal',false);
       
        }


        onMounted(() => {
            if (props.docUpdate) {
              
              console.log("doc update",props.docUpdate)
              id.value = props.docUpdate.id;
      
              username.value = props.docUpdate.username;
              email.value = props.docUpdate.email;
              password.value = props.docUpdate.password;
              role.value = props.docUpdate.role;
              status.value = props.docUpdate.status;
              console.log("status",status);
              imageURL.value = props.docUpdate.imageURL;
              originalEmail.value = props.docUpdate.email;
              originalUsername.value = props.docUpdate.username;
              image.value = {
                  src: props.docUpdate?.imageURL,
              };
              bio.value = props.docUpdate?.bio
             
          }
            fetchUserAuthentication();
         
        })

        return {
            userAuthRole,
            status,
            errorCheckUserName,
            errorCheckEmail,
            id,
            isLoading,
            handleCreateandUpdateUser,
            errorCheckPassword,
            optionRoles,
            username,
            password,
            email,
            bio,
            role,
            fileError,
            image,

            handleCloseModal,
            handleUploadImage,
            handleRemoveImage
        }
    }
}
</script>