<template>
  <div>
    <ckeditor v-model="content" :editor="editor" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ref, watch ,onMounted} from "vue";
export default {
  props: ["data"],
  emits: ["onListenToData"],
  setup(props, { emit }) {
    const content = ref(null);
    // const { data } = toRefs(props)

    // if(props.data){
    //    content.value = data;
    // }
    
    watch(() => props.data, () => {
      content.value = props.data;
    })
    
    watch(content, () => {
      emit("onListenToData", content.value);
    });

    onMounted(() => {
        if(props.data){
            content.value = props.data;
            console.log(content.value)
        }
    })

    return {
      content,
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [],
        toolbar: {
          items: [
              'undo', 'redo',
              '|', 'heading',
              '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
              '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
              '|', 'alignment',
              'link', 'blockQuote', 'codeBlock',
              '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
          ],
          shouldNotGroupWhenFull: true
       }
      },
    };
  },
};
</script>

<style>
.ck.ck-content {
  min-height: 250px;
}
</style>
