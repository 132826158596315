<template>
    <div style="display: flex; flex-direction: row; padding: 20px;">
        <!-- <div style="background-color: red; width: 100px;padding: 10px; border-bottom: 20px;" @click="handlesumbit">Add
        </div> -->


        <div style="margin-left: 20px;">
            <label for="">Unknow Time of Birth</label>
            <input v-model="unknownTime" type="checkbox">
        </div>


        <div style="margin-left: 20px;">
            <label for="">time</label>
            <select v-model="time" style="width: 150px; height: 30px; margin-left: 10px;">
                <option v-for="t in timeoptions" :key="t.time">{{ t.time }}</option>
            </select>
        </div>



        <div style="margin-left: 20px;">
            <label for="">Min</label>
            <select v-model="minutes" style="width: 150px; height: 30px; margin-left: 10px;">
                <option v-for="m in  optionMinutes" :key="m.minute">{{ m.minute }}</option>
            </select>
        </div>

        <div style="margin-left: 20px;">

            <label for="">Day</label>
            <select v-model="day" style="width: 150px; height: 30px; margin-left: 10px;">
                <option v-for="d in dayoptions" :key="d.day">{{ d.day }}</option>

            </select>
        </div>
        <div style="margin-left: 20px;">
            <label for="">Month</label>
            <select v-model="month" style="width: 150px; height: 30px; margin-left: 10px;">
                <option v-for="(m, index) in monthoptions" :key="index" :value="index">{{ m.month }}</option>
            </select>

        </div>
        <div style="margin-left: 20px;">
            <label for="">Year</label>
            <select v-model="year" style="width: 150px; height: 30px; margin-left: 10px;">
                <option v-for="y in yearoptions" :key="y.year">{{ y.year }}</option>
            </select>
        </div>
        <div style="margin-left: 20px;">
            <label for="">Gender</label>
            <select v-model="gender" style="width: 150px; height: 30px; margin-left: 10px;">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </select>
        </div>

        <div style="margin-left: 20px;">
            <label for="">Twin 2</label>
            <input v-model="twin" type="checkbox">
        </div>


        <div style="background-color: red; width: 100px;padding: 10px; border-bottom: 20px; margin-left: 20px;"
            @click="handleCalculatePrediction">Predict Now</div>

        <div style="background-color: red; width: 100px;padding: 10px; border-bottom: 20px; margin-left: 20px;"
            @click="handleReset">Reset</div>
    </div>

    <!-- <div>
    <input type="file" @change="handleFileChange">
</div> -->
    <div style="display: flex; flex-direction: row; padding: 20px;">



        <div style="display: flex; flex-direction:column">
            <div style="margin-left: 50px;">
                <table>
                    <tbody>
                        <tr v-for="(row, rowIndex) in chunkedData" :key="rowIndex">
                            <td v-for="(item, colIndex) in row" :key="colIndex" class="table-cell">
                                {{ item.elementName }}{{ item.elementSign }} <br>
                                {{ item.zodiacName }}</td>
                        </tr>

                    </tbody>
                </table>
            </div>


            <div>
                <h1>វ័យចរ</h1>
                <table>

                    <tr v-for="(row, rowIndex) in  dataRangeAgeWalk" :key="rowIndex" style="text-align: center;">
                        <td v-for="(range, colIndex) in row" :key="colIndex" style="white-space: nowrap;"
                            class="table-cell">

                            {{ range }}
                        </td>
                    </tr>

                    <tr v-for="(row, rowIndex) in dataAgeW" :key="rowIndex" style="text-align: center;">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="table-cell">
                            {{ item.elementName }}{{ item.elementSign }}
                        </td>
                    </tr>
                    <tr v-for="(row, rowIndex) in dataAgeW" :key="rowIndex" style="text-align: center;">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="table-cell">

                            {{ item.zodiacName }}
                        </td>
                    </tr>
                </table>
            </div>


            <div>
                <h1>ឆ្នាំ</h1>
                <table>

                    <tr v-for="(row, rowIndex) in  datarangeYearFuture" :key="rowIndex" style="text-align: center;">
                        <td v-for="(range, colIndex) in row" :key="colIndex" style="white-space: nowrap;"
                            class="table-cell">

                            {{ range }}
                        </td>
                    </tr>

                    <tr v-for="(row, rowIndex) in dataYearFuture" :key="rowIndex" style="text-align: center;">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="table-cell">
                            {{ item.elementName }}{{ item.elementSign }}
                        </td>
                    </tr>
                    <tr v-for="(row, rowIndex) in dataYearFuture" :key="rowIndex" style="text-align: center;">
                        <td v-for="(item, colIndex) in row" :key="colIndex" class="table-cell">

                            {{ item.zodiacName }}
                        </td>
                    </tr>
                </table>
            </div>




        </div>



        <div style="margin-left: 20px;  display: flex; flex-direction:column; ">


            <div style="margin-left: 50px; display: flex; flex-direction: row; ">

                <div style="margin-left: 50px; display: flex; flex-direction: row; ">
                    <span for="">ថ្ងៃ</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ day }} </span>
                </div>

                <div style="margin-left: 29px; display: flex; flex-direction: row; ">
                    <span for="">ខែ</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ month + 1 }} </span>
                </div>


                <div style="margin-left: 29px; display: flex; flex-direction: row; ">
                    <span for="">ឆ្នាំ</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ year }} </span>
                </div>

                <div style="margin-left: 29px; display: flex; flex-direction: row; ">
                    <span for="">ម៉ោង</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ time }} </span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;"> : </span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;"> {{ minutes.toString().padStart(2, '0') }}
                    </span>
                </div>


                <div style="margin-left: 29px; display: flex; flex-direction: row; ">
                    <span for="">អាយុ</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ userAge }} </span>

                </div>

                <div style="margin-left: 10px; display: flex; flex-direction: row; ">
                    <span for="">លេខគ័រ</span>
                    <span for="" style="margin-left: 5px; margin-top: 6px;">{{ kouyNumber }} </span>

                </div>
            </div>


            <h1>Result</h1>
            <table>
                <tr>
                    <th class="table-cell">ម៉ោង</th>
                    <th class="table-cell">ថ្ងៃ</th>
                    <th class="table-cell">ខែ</th>
                    <th class="table-cell">ឆ្នាំ</th>
                </tr>
                <tr v-for="p in dataResultPredict" :key="p">
                    <td class="table-cell">
                        {{ p.elementNameTime }}{{ p.elementNameTime != 'N/A' ? p.elementSignTime : " " }}

                    </td>
                    <td class="table-cell">
                        {{ p.elementNameDay }}{{ p.elementSignDay }}

                    </td>
                    <td class="table-cell">
                        {{ p.elementNameMonth }}{{ p.elementSignMonth }}

                    </td>
                    <td class="table-cell">
                        {{ p.elementNameYear }}{{ p.elementSignYear }}

                    </td>




                </tr>
                <tr v-for="p in dataResultPredict" :key="p">
                    <td class="table-cell">
                        {{ p.zodiacSignTime }}

                    </td>
                    <td class="table-cell">
                        {{ p.zodiacSignDay }}

                    </td>
                    <td class="table-cell">
                        {{ p.zodiacSignMonth }}

                    </td>
                    <td class="table-cell">
                        {{ p.zodiacSignYear }}

                    </td>
                </tr>
                <tr v-for="p in dataResultPredict" :key="p" style="white-space: nowrap;">
                    <td class="table-cell">
                        {{ p.rootZodiacTime }}

                    </td>
                    <td class="table-cell">
                        {{ p.rootZodiacDay }}

                    </td>
                    <td class="table-cell">
                        {{ p.rootZodiacMonth }}

                    </td>
                    <td class="table-cell">
                        {{ p.rootZodiacYear }}

                    </td>
                </tr>

            </table>




        </div>

        <div style="margin-left: 20px;">
            <table>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ស្នេហ៍</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameLV }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ជំនូយ</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameNB[0] }} {{ s.zodiacNameNB[1] }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ប្រាជ្ញា</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameIT }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ធ្វើដំណើរ</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameSH }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ឯកា</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameSL[0] }} {{ s.zodiacNameSL[1] }}</td>

                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>រទេះមាស</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameGD }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>អាងទឹក</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameP[0] }} {{ s.zodiacNameP[1] }}</td>
                </tr>
                <tr style="border: 20px; white-space: nowrap">
                    <th>ឃុងបួង</th>
                    <td v-for="s in dataResultStarPredict" :key="s">{{ s.zodiacNameDE[0] }} {{ s.zodiacNameDE[1] }}</td>
                </tr>



            </table>
        </div>


    </div>
</template>

<script>
// import useCollection from '@/composables/useDocument'
// import useStorage from '@/composables/useStorage';
import { computed, onMounted, ref, watch, } from 'vue';
import { orderBy, serverTimestamp } from 'firebase/firestore';
import { getCollectionQuery } from '@/composables/getCollection';
// import { generateDocCode } from '@/composables/generateDocumentCode';


export default {
    setup() {


        const timestamp = ref(null);
        timestamp.value = serverTimestamp();
        const month = ref(null);
        const day = ref(null);
        const time = ref(null);
        const gender = ref("");
        const year = ref(null);
        const twin = ref(false);
        const dataResultPredict = ref([]);
        const dataResultStarPredict = ref([]);
        const kouyNumber = ref(null);
        const dayoptions = ref([]);
        const timeoptions = ref([]);
        const monthoptions = ref([]);
        const yearoptions = ref([]);
        const userAge = ref(null);
        const minutes = ref(null);
        const optionMinutes = ref([]);
        const rootZodiac = ref([]);
        const dataZodiac = ref([]);
        const datasElements = ref([]);
        const dataAgeWalk = ref([]);
        const rangeAgeWalk = ref([]);
        const dataYear = ref([]);
        const rangeYearFuture = ref([]);
        const unknownTime = ref(false);



        watch(twin, (newV) => {
            console.log("newV twin", newV);
        })


        optionMinutes.value = [

            { minute: 0 },
            { minute: 1 },
            { minute: 2 },
            { minute: 3 },
            { minute: 4 },
            { minute: 5 },
            { minute: 6 },
            { minute: 7 },
            { minute: 8 },
            { minute: 9 },
            { minute: 10 },
            { minute: 11 },
            { minute: 12 },
            { minute: 13 },
            { minute: 14 },
            { minute: 15 },
            { minute: 16 },
            { minute: 17 },
            { minute: 18 },
            { minute: 19 },
            { minute: 20 },
            { minute: 21 },
            { minute: 22 },
            { minute: 23 },
            { minute: 24 },
            { minute: 25 },
            { minute: 26 },
            { minute: 27 },
            { minute: 28 },
            { minute: 29 },
            { minute: 30 },
            { minute: 31 },
            { minute: 32 },
            { minute: 33 },
            { minute: 34 },
            { minute: 35 },
            { minute: 36 },
            { minute: 37 },
            { minute: 38 },
            { minute: 39 },
            { minute: 40 },
            { minute: 41 },
            { minute: 42 },
            { minute: 43 },
            { minute: 44 },
            { minute: 45 },
            { minute: 46 },
            { minute: 47 },
            { minute: 48 },
            { minute: 49 },
            { minute: 50 },
            { minute: 51 },
            { minute: 52 },
            { minute: 53 },
            { minute: 54 },
            { minute: 55 },
            { minute: 56 },
            { minute: 57 },
            { minute: 58 },
            { minute: 59 }

        ];


        watch(time, () => {
            console.log("time", Number(time.value))
        })



        // watch(year, (newV) => {
        //     if (newV) {


        //         // const currentYear = new Date().getFullYear();
        //         // userAge.value = currentYear - newV;


        //         const currentYear = new Date();
        //         const date = new Date();
        //         console.log("date", date);



        //         console.log("currenct year :", currentYear.getFullYear());


        //         const resultElement = (newV - 3 - 1) % 10 + 1;
        //         const resultZodiacSign = (newV - 3 - 1) % 12 + 1;

        //         console.log("result element", resultElement);
        //         console.log("result sign", resultZodiacSign);
        //         console.log("zodiac sign array length", dataZodiac);
        //         console.log("element array length", datasElements);
        //         // Ensure the result is within the bounds of the array
        //         const index1 = resultElement >= 0 ? resultElement : resultElement + 10;

        //         const index2 = resultZodiacSign >= 0 ? resultZodiacSign : resultZodiacSign + 12;

        //         console.log("index elemet", index1);
        //         console.log("inde sign", index2)

        //         const findex1 = index1 - 1;
        //         const findex2 = index2 - 1;
        //         const findElement = datasElements.value[findex1];
        //         const findSign = dataZodiac.value[findex2];
        //         if (findElement && findSign) {

        //             dataResultPredictElement.value = [];
        //             dataResultPredictElement.value.push(findElement);
        //             console.log("Found element:", findElement);
        //         }

        //         if (findSign) {
        //             dataResultPredictSign.value = [];
        //             dataResultPredictSign.value.push(findSign);
        //             console.log("found zodiac sign", findSign);
        //         }


        //         if (gender.value === 'Male') {

        //             const digitalRoot = 1 + ((newV - 1) % 9)
        //             const result = 11 - digitalRoot;
        //             kouyNumber.value = null;// Subtract the digital root from 11
        //             kouyNumber.value = 1 + ((result - 1) % 9);

        //             if (kouyNumber.value === 5) {
        //                 console.log("condition number=5 male");
        //                 kouyNumber.value = 2;
        //             }

        //             console.log("male kouy number :", kouyNumber);

        //         } else if (gender.value === 'Female') {
        //             const digitalRoot = 1 + ((newV - 1) % 9)
        //             const result = 4 + digitalRoot;
        //             kouyNumber.value = null;// Subtract the digital root from 11
        //             kouyNumber.value = 1 + ((result - 1) % 9);

        //             if (kouyNumber.value === 5) {
        //                 console.log("condition number =5 female");
        //                 kouyNumber.value = 8;
        //             }

        //             console.log("female kouy number :", kouyNumber);
        //         }
        //     }
        // });


        const userDOB = ref(null);
        const january1900 = ref(null);
        const days = ref(null);
        const handleCalculatePrediction = async () => {
            await getCollectionQuery("elements", [orderBy("elementCode")], (data) => {
                datasElements.value = data;
            },null, false);

            await getCollectionQuery("zodiacSigns", [orderBy("zodiacCode")], (data) => {
                dataZodiac.value = data;

            },null, false);
            userDOB.value = new Date(year.value, (month.value), Number(day.value), Number(time.value), Number(minutes.value));
            console.log("user DOB", userDOB)
            january1900.value = new Date(1900, 0, 1); //january 1st 1900 at 12:00 AM midnight

            const currentDate = new Date();

            userAge.value = currentDate.getFullYear() - Number(year.value);

            const differenceMilliseconds = userDOB.value.getTime() - january1900.value.getTime();
            console.log("differet mili test",differenceMilliseconds)

            // Convert milliseconds to days
            const total = Math.floor(differenceMilliseconds / (1000 * 60 * 60 * 24))
            days.value = total + 1;//បូកថែម1ថ្ងៃបង្គ្រប់;
            console.log("day test",days)





            const indexSignbyTime = ref(null);
            const indexElementbyTime = ref(null);
            const indexElementByMonth = ref(null);
            const indexSignByMonth = ref(null);
            const findSignbyTime = ref({});
            const findSignByYear = ref({});
            const findElementByyear = ref({});
            const elementIndexByYear = ref(null);
            const signIndexByYear = ref(null);



            const resultElementbyDay = ((days.value - 1) % 10) + 1;
            const resultZodiacSignbyDay = ((days.value + 10 - 1) % 12) + 1;






            const findtimeSign = dataZodiac.value.find((z) => z.startTime === Number(time.value) || z.endTime === Number(time.value));
            if (findtimeSign) {
                const zodiacCode = findtimeSign.zodiacCode;

                const sliceLength = zodiacCode.length >= 2 ? 2 : 1;

                indexSignbyTime.value = zodiacCode.slice(-sliceLength);


                findSignbyTime.value = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLength) === indexSignbyTime.value);

            }

            indexElementbyTime.value = ((days.value - 1) * 12 + (Number(indexSignbyTime.value) - 1)) % 10 + 1;
            const sliceLengthTime = String(indexElementbyTime.value).length >= 2 ? 2 : 1
            const findElementByTime = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthTime) === String(indexElementbyTime.value));




            const sliceLengthDay = String(resultZodiacSignbyDay).length >= 2 ? 2 : 1;
            const findSignByDay = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLengthDay) === String(resultZodiacSignbyDay));
            const sliceLengthEDay = String(resultElementbyDay).length >= 2 ? 2 : 1;
            const findElementByDay = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthEDay) === String(resultElementbyDay));






            //find month

            const findselectMonth = monthoptions.value.find((m) => m.id === Number(month.value));
            const findMonthSign = ref({});
            if (findselectMonth) {


                findMonthSign.value = dataZodiac.value.find((z) => z.startMonth === findselectMonth.month && z.startDay.includes(Number(day.value)) || (z.endMonth === findselectMonth.month && z.endDay.includes(Number(day.value))));



                if (findMonthSign.value) {
                    const zodiacCode = findMonthSign.value.zodiacCode;
                    const sliceLength = zodiacCode.toString().length >= 2 ? 2 : 1;

                    indexSignByMonth.value = zodiacCode.slice(-sliceLength);

                }


            }


            indexElementByMonth.value = ((Number(year.value) - 1) * 12 + (Number(indexSignByMonth.value) - 6 - 1)) % 10 + 1;
            const index = String(indexElementByMonth.value);
            const sliceLength = index.length >= 2 ? 2 : 1;
            const findElementByMonth = datasElements.value.find((e) => e.elementCode.slice(-sliceLength) === index);



            //find kouy number
            if (gender.value != null) {

                if (gender.value === 'Male') {

                    const digitalRoot = 1 + ((Number(year.value) - 1) % 9)
                    const result = 11 - digitalRoot;
                    kouyNumber.value = null;// Subtract the digital root from 11
                    kouyNumber.value = 1 + ((result - 1) % 9);

                    if (kouyNumber.value === 5) {

                        kouyNumber.value = 2;
                    }



                } else if (gender.value === 'Female') {
                    const digitalRoot = 1 + ((Number(year.value) - 1) % 9)
                    const result = 4 + digitalRoot;
                    kouyNumber.value = null;// Subtract the digital root from 11
                    kouyNumber.value = 1 + ((result - 1) % 9);

                    if (kouyNumber.value === 5) {

                        kouyNumber.value = 8;
                    }


                }

            }


            //find year element and sign 
            const inputDate = new Date(year.value, Number(month.value), Number(day.value)); // month - 1 because months are zero-based in JavaScript
            const feb4 = new Date(year.value, 1, 4);
            if (inputDate >= feb4) {
                // Calculate the ID of the element using the provided formula
                elementIndexByYear.value = (year.value - 3 - 1) % 10 + 1;
                signIndexByYear.value = (year.value - 3 - 1) % 12 + 1;





            } else if (inputDate < feb4) {
                elementIndexByYear.value = (year.value - 4 - 1) % 10 + 1;
                signIndexByYear.value = (year.value - 4 - 1) % 12 + 1;

            }



            const sliceLengthSignYear = String(signIndexByYear.value).length >= 2 ? 2 : 1;
            const sliceLengthEYear = String(elementIndexByYear.value).length >= 2 ? 2 : 1;
            findSignByYear.value = dataZodiac.value.find((z) => z.zodiacCode.slice(-sliceLengthSignYear) === String(signIndexByYear.value));
            findElementByyear.value = datasElements.value.find((e) => e.elementCode.slice(-sliceLengthEYear) === String(elementIndexByYear.value));





            const elementSignBymonth = ref("");
            const elementSignByYear = ref("");

            if (findElementByTime || findSignbyTime.value || findElementByDay || findSignByDay || findElementByMonth || findMonthSign.value || findSignByYear.value || findElementByyear.value) {

                elementSignBymonth.value = findElementByMonth.elementSign;
                elementSignByYear.value = findElementByyear.value.elementSign;


                const resutlPredictObject = {
                    elementNameTime: unknownTime.value === true ? "N/A" : findElementByTime.elementName,
                    elementSignTime: unknownTime.value === true ? "N/A" : findElementByTime.elementSign,
                    zodiacSignTime: unknownTime.value === true ? "N/A" : findSignbyTime.value.zodiacName,
                    rootZodiacTime: unknownTime.value === true ? "N/A" : findSignbyTime.value.rootZodiac,
                    elementNameDay: findElementByDay.elementName,
                    elementSignDay: findElementByDay.elementSign,
                    zodiacSignDay: findSignByDay.zodiacName,
                    rootZodiacDay: findSignByDay.rootZodiac,
                    elementNameMonth: findElementByMonth.elementName,
                    elementSignMonth: findElementByMonth.elementSign,
                    zodiacSignMonth: findMonthSign.value.zodiacName,
                    rootZodiacMonth: findMonthSign.value.rootZodiac,
                    elementNameYear: findElementByyear.value.elementName,
                    elementSignYear: findElementByyear.value.elementSign,
                    zodiacSignYear: findSignByYear.value.zodiacName,
                    rootZodiacYear: findSignByYear.value.rootZodiac,
                };



                dataResultPredict.value = [];
                // Pushing the new objects into dataResultPredictByTime
                dataResultPredict.value.push(resutlPredictObject);


                //find Star death&Emptiness
                const findDEStar = dataDE.value.find((k) => k.elementName === findElementByDay.elementName && k.elementSign === findElementByDay.elementSign && k.zodiacName === findSignByDay.zodiacName);

                //find star nobleMan
                const findNBStar = dataNB.value.find((n) => n.elementName === findElementByDay.elementName && n.elementSign === findElementByDay.elementSign);

                //find peachBlossom as love star
                const findLoveStar = dataLoveStar.value.find((l) => l.zodiacName === findSignByDay.zodiacName);

                //find poolStar
                const findpoolStar = dataPoolStar.value.find((p) => p.elementName === findElementByDay.elementName && p.elementSign === findElementByDay.elementSign);

                //find ITStar
                const findITStar = dataITStar.value.find((i) => i.elementName === findElementByDay.elementName && i.elementSign === findElementByDay.elementSign);


                //find GDStar
                const findGDStar = dataGD.value.find((i) => i.elementName === findElementByDay.elementName && i.elementSign === findElementByDay.elementSign);

                //find solitaryStar
                const findSLStar = dataSLStar.value.find((l) => l.zodiacName === findSignByDay.zodiacName);

                //find SHStar
                const findSHStar = dataSHStar.value.find((s) => s.zodiacName === findSignByDay.zodiacName);


                if (findDEStar || findNBStar || findLoveStar || findpoolStar || findITStar || findGDStar || findSLStar || findSHStar) {
                    const resultPredicStarObj = {
                        zodiacNameDE: findDEStar.deStar,
                        zodiacNameNB: findNBStar.star,
                        zodiacNameLV: findLoveStar.star,
                        zodiacNameP: findpoolStar.star,
                        zodiacNameIT: findITStar.star,
                        zodiacNameGD: findGDStar.star,
                        zodiacNameSL: findSLStar.star,
                        zodiacNameSH: findSHStar.star

                    }

                    dataResultStarPredict.value = [];

                    dataResultStarPredict.value.push(resultPredicStarObj);
                }




                //find វ័យចរ
                if (gender.value) {
                    const findAgeWalk = dataBazi.value.find((b) => b.elementName === findElementByMonth.elementName && b.elementSign === findElementByMonth.elementSign && b.zodiacName === findMonthSign.value.zodiacName);
                    if (findAgeWalk) {
                        if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === false) {

                            //for forward
                            const endIndexF = dataBazi.value.indexOf(findAgeWalk);
                            const endIndexForward = Math.min(endIndexF + 10, dataBazi.value.length); // Ensure endIndexForward doesn't exceed the array length

                            dataAgeWalk.value = [];

                            for (let i = endIndexF + 1; i < endIndexForward || dataAgeWalk.value.length < 10; i++) {
                                // Ensure cycling forward through dataBazi
                                let index = i % dataBazi.value.length;

                                dataAgeWalk.value.push(dataBazi.value[index]);

                                if (i === dataBazi.value.length - 1 && dataAgeWalk.value.length < 10) {
                                    i = -1; // Restart loop from the beginning if more elements are needed
                                }
                            }

                            console.log("age walk forward male", dataAgeWalk);



                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === false) {

                            //for backward
                            const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                            console.log("start IndexB", startIndexB);

                            const backwardLength = 10;

                            // Calculate the start index for backward traversal
                            const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                            dataAgeWalk.value = [];


                            for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                                // Ensure cycling backward through dataBazi
                                let index = (i + dataBazi.value.length) % dataBazi.value.length;

                                if (index < 0) {
                                    index += dataBazi.value.length; // Ensure index is non-negative
                                }

                                dataAgeWalk.value.push(dataBazi.value[index]);

                            }
                            console.log("age walk backward male", dataAgeWalk);


                        } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === true) {
                            //for backward

                            const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                            console.log("start IndexB", startIndexB);

                            const backwardLength = 10;

                            // Calculate the start index for backward traversal
                            const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                            dataAgeWalk.value = [];


                            for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                                // Ensure cycling backward through dataBazi
                                let index = (i + dataBazi.value.length) % dataBazi.value.length;

                                if (index < 0) {
                                    index += dataBazi.value.length; // Ensure index is non-negative
                                }

                                dataAgeWalk.value.push(dataBazi.value[index]);

                            }
                            console.log("age walk backward male", dataAgeWalk);
                        } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === true) {
                            //for forward
                            const endIndexF = dataBazi.value.indexOf(findAgeWalk);
                            const endIndexForward = Math.min(endIndexF + 10, dataBazi.value.length); // Ensure endIndexForward doesn't exceed the array length

                            dataAgeWalk.value = [];

                            for (let i = endIndexF + 1; i < endIndexForward || dataAgeWalk.value.length < 10; i++) {
                                // Ensure cycling forward through dataBazi
                                let index = i % dataBazi.value.length;

                                dataAgeWalk.value.push(dataBazi.value[index]);

                                if (i === dataBazi.value.length - 1 && dataAgeWalk.value.length < 10) {
                                    i = -1; // Restart loop from the beginning if more elements are needed
                                }
                            }

                            console.log("age walk forward male", dataAgeWalk);
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === false) {
                            //for backward

                            const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                            console.log("start IndexB", startIndexB);

                            const backwardLength = 10;

                            // Calculate the start index for backward traversal
                            const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                            dataAgeWalk.value = [];


                            for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                                // Ensure cycling backward through dataBazi
                                let index = (i + dataBazi.value.length) % dataBazi.value.length;

                                if (index < 0) {
                                    index += dataBazi.value.length; // Ensure index is non-negative
                                }

                                dataAgeWalk.value.push(dataBazi.value[index]);

                            }
                            console.log("age walk backward female", dataAgeWalk);
                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === false) {
                            //for forward
                            const endIndexF = dataBazi.value.indexOf(findAgeWalk);
                            const endIndexForward = Math.min(endIndexF + 10, dataBazi.value.length); // Ensure endIndexForward doesn't exceed the array length

                            dataAgeWalk.value = [];

                            for (let i = endIndexF + 1; i < endIndexForward || dataAgeWalk.value.length < 10; i++) {
                                // Ensure cycling forward through dataBazi
                                let index = i % dataBazi.value.length;

                                dataAgeWalk.value.push(dataBazi.value[index]);

                                if (i === dataBazi.value.length - 1 && dataAgeWalk.value.length < 10) {
                                    i = -1; // Restart loop from the beginning if more elements are needed
                                }
                            }

                            console.log("age walk forward female", dataAgeWalk);
                        } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === true) {
                            //for forward
                            const endIndexF = dataBazi.value.indexOf(findAgeWalk);
                            const endIndexForward = Math.min(endIndexF + 10, dataBazi.value.length); // Ensure endIndexForward doesn't exceed the array length

                            dataAgeWalk.value = [];

                            for (let i = endIndexF + 1; i < endIndexForward || dataAgeWalk.value.length < 10; i++) {
                                // Ensure cycling forward through dataBazi
                                let index = i % dataBazi.value.length;

                                dataAgeWalk.value.push(dataBazi.value[index]);

                                if (i === dataBazi.value.length - 1 && dataAgeWalk.value.length < 10) {
                                    i = -1; // Restart loop from the beginning if more elements are needed
                                }
                            }

                            console.log("age walk forward female", dataAgeWalk);
                        } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === true) {
                            //for backward

                            const startIndexB = dataBazi.value.indexOf(findAgeWalk);
                            console.log("start IndexB", startIndexB);

                            const backwardLength = 10;

                            // Calculate the start index for backward traversal
                            const startIndexBackward = Math.max(0, startIndexB - backwardLength);

                            dataAgeWalk.value = [];


                            for (let i = startIndexB - 1; i >= startIndexBackward || dataAgeWalk.value.length < backwardLength; i--) {
                                // Ensure cycling backward through dataBazi
                                let index = (i + dataBazi.value.length) % dataBazi.value.length;

                                if (index < 0) {
                                    index += dataBazi.value.length; // Ensure index is non-negative
                                }

                                dataAgeWalk.value.push(dataBazi.value[index]);

                            }
                            console.log("age walk backward female", dataAgeWalk);
                        }


                        const zodiacCodeByMonth = ref(null);


                        const remaininDaysOld = ref(null);
                        const remainingEndDay = ref(null);
                        const numberAgeWalk = ref(null);


                        if (dataAgeWalk.value.length > 0) {

                            const findMonthSignRangeSM = dataZodiac.value.find((z) => z.startMonth === findselectMonth.month && z.startDay.includes(Number(day.value)));
                            console.log("findMonth Sign Range SM", findMonthSignRangeSM);
                            const findMontSignRangeEM = dataZodiac.value.find((z) => z.endMonth === findselectMonth.month && z.endDay.includes(Number(day.value)));


                            const rangeZodiacName = ref(null);
                            if (findMonthSignRangeSM != undefined && findMontSignRangeEM === undefined) {
                                rangeZodiacName.value = findMonthSignRangeSM.zodiacName;
                                zodiacCodeByMonth.value = findMonthSignRangeSM.zodiacCode;

                                const currentDateOldMonth = new Date(year.value, month.value, Number(day.value));
                                const endOfMonthOld = new Date(currentDateOldMonth.getFullYear(), currentDateOldMonth.getMonth() + 1, 0);

                                if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === false) {


                                    //forward
                                    remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;
                                    remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                                    console.log("remaining EndDay", remainingEndDay)


                                    console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                                    numberAgeWalk.value = Math.floor((remaininDaysOld.value + remainingEndDay.value) / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === false) {

                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === true) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }

                                } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === true) {

                                    //forward


                                    remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                                    remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                                    console.log("remaining EndDay", remainingEndDay)


                                    console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                                    numberAgeWalk.value = Math.floor((remaininDaysOld.value + remainingEndDay.value) / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === false) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                                    console.log(" remaining old days backward ", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === false) {
                                    //forward
                                    remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                                    remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                                    console.log("remaining EndDay", remainingEndDay)


                                    console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                                    numberAgeWalk.value = Math.floor((remaininDaysOld.value + remainingEndDay.value) / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === true) {
                                    //forward
                                    remaininDaysOld.value = endOfMonthOld.getDate() - currentDateOldMonth.getDate() + 1;

                                    remainingEndDay.value = (findMonthSignRangeSM.endDay.length)
                                    console.log("remaining EndDay", remainingEndDay)


                                    console.log("total day forward", remaininDaysOld.value + remainingEndDay.value);

                                    numberAgeWalk.value = Math.floor((remaininDaysOld.value + remainingEndDay.value) / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === true) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMonthSignRangeSM.startDay[0]) + 1;
                                    console.log(" remaining old days backward ", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                }



                            } else if (findMontSignRangeEM != undefined && findMonthSignRangeSM === undefined) {
                                rangeZodiacName.value = findMontSignRangeEM.zodiacName;
                                zodiacCodeByMonth.value = findMontSignRangeEM.zodiacCode;
                                if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === false) {
                                    //forward
                                    remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                                    numberAgeWalk.value = Math.floor(remainingEndDay.value / 3);//ues math floor to round down every time to signle digit

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                    console.log("condition EM")

                                } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === false) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMontSignRangeEM.endDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Male" && elementSignByYear.value === "+" && twin.value === true) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMontSignRangeEM.endDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Male" && elementSignByYear.value === "-" && twin.value === true) {
                                    //forward
                                    remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                                    numberAgeWalk.value = Math.floor(remainingEndDay.value / 3);//ues math floor to round down every time to signle digit

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                    console.log("condition EM")
                                } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === false) {
                                    //backward

                                    console.log("find Month Sign Range EM", findMontSignRangeEM.endDay[0])
                                    remaininDaysOld.value = (Number(day.value) - findMontSignRangeEM.endDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === false) {
                                    //forward
                                    remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;
                                    numberAgeWalk.value = Math.floor(remainingEndDay.value / 3);//ues math floor to round down every time to signle digit

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                    console.log("condition EM")
                                } else if (gender.value === "Female" && elementSignByYear.value === "+" && twin.value === true) {
                                    //forward
                                    remainingEndDay.value = (findMontSignRangeEM.endDay.length - Number(day.value)) + 1;

                                    console.log(" remaining old days forward EM", remainingEndDay);
                                    numberAgeWalk.value = Math.floor(remainingEndDay.value / 3);//ues math floor to round down every time to signle digit

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                } else if (gender.value === "Female" && elementSignByYear.value === "-" && twin.value === true) {
                                    //backward
                                    remaininDaysOld.value = (Number(day.value) - findMontSignRangeEM.endDay[0]) + 1;
                                    console.log(" remaining old days backward", remaininDaysOld);
                                    numberAgeWalk.value = Math.floor(remaininDaysOld.value / 3);

                                    let start = numberAgeWalk.value;

                                    rangeAgeWalk.value = [];
                                    for (let i = 0; i < 10; i++) {
                                        const end = start + 10;

                                        rangeAgeWalk.value.push(`${start} - ${end}`);
                                        start = end;
                                    }
                                }


                            }
                        }

                    }


                } else {
                    rangeAgeWalk.value = [];
                    dataAgeWalk.value = [];
                }

                const findAgeWalk = dataBazi.value.find((b) => b.elementName === findElementByyear.value.elementName && b.elementSign === findElementByyear.value.elementSign && b.zodiacName === findSignByYear.value.zodiacName);
                // Find the index of the found element
                const endIndexF = dataBazi.value.indexOf(findAgeWalk);
                dataYear.value = [];

                // Loop to populate dataYear.value
                for (let i = endIndexF, count = 0; count < 10; i++, count++) {
                    // Ensure cycling forward through dataBazi
                    let index = i % dataBazi.value.length;
                    dataYear.value.push(dataBazi.value[index]);

                    // If reached the end of the array, reset the index
                    if (i === dataBazi.value.length - 1) {
                        i = -1; // Restart loop from the beginning
                    }
                }

                //find rangeNumber Year future
                rangeYearFuture.value = [];
                let start = parseInt(year.value); // Convert string to number
                for (let i = 0; i < 10; i++) {
                    rangeYearFuture.value.push(start); // Push the current year into the array
                    start++; // Increment the year for the next iteration
                }

            }
        }






        monthoptions.value = [
            { month: 'January', id: 0 },
            { month: 'February', id: 1 },
            { month: 'March', id: 2 },
            { month: 'April', id: 3 },
            { month: 'May', id: 4 },
            { month: 'June', id: 5 },
            { month: 'July', id: 6 },
            { month: 'August', id: 7 },
            { month: 'September', id: 8 },
            { month: 'October', id: 9 },
            { month: 'November', id: 10 },
            { month: 'December', id: 11 }
        ]






        timeoptions.value = [
            { time: 0 },
            { time: 1 },
            { time: 2 },
            { time: 3 },
            { time: 4 },
            { time: 5 },
            { time: 6 },
            { time: 7 },
            { time: 8 },
            { time: 9 },
            { time: 10 },
            { time: 11 },
            { time: 12 },
            { time: 13 },
            { time: 14 },
            { time: 15 },
            { time: 16 },
            { time: 17 },
            { time: 18 },
            { time: 19 },
            { time: 20 },
            { time: 21 },
            { time: 22 },
            { time: 23 },
        ]


        // Get current local date
        const currentDate = new Date();

        year.value = currentDate.getFullYear();
        month.value = currentDate.getMonth();
        day.value = currentDate.getDate();
        time.value = currentDate.getHours();
        minutes.value = currentDate.getMinutes();

        function updateDateTime() {
            const currentDate = new Date();
            year.value = currentDate.getFullYear();
            month.value = currentDate.getMonth();
            day.value = currentDate.getDate();
            time.value = currentDate.getHours();
            minutes.value = currentDate.getMinutes();
        }




        // Populate year options from current year to 1900
        for (var i = 2100; i >= 1900; i--) {
            yearoptions.value.push({ year: i });
        }

        // Function to get the number of days in a month for a given year
        function getDaysInMonth(month, year) {
            return new Date(year, month + 1, 0).getDate();
        }

        // Populate day options based on selected month and year
        function populateDayOptions(selectedMonth, selectedYear) {
            var daysInMonth = getDaysInMonth(selectedMonth, selectedYear);

            for (var i = 1; i <= daysInMonth; i++) {
                dayoptions.value.push({ day: i });
            }
            return dayoptions.value;
        }

        // Populate day options for the current month and year
        populateDayOptions(month.value, year.value);




        // Watch for changes in year and month
        watch([year, month], ([newYear, newMonth]) => {
            if (newYear && newMonth !== undefined) {
                dayoptions.value = [];
                populateDayOptions(newMonth, newYear);
            }
        });










        const image = ref({});
        const files = ref([]);
        const fileError = ref("");
        const imageFile = ref(null);
        const types = ["image/png", "image/jpg", "image/jpeg", "image/svg"];
        const handleFileChange = (e) => {
            const limitedMB = 2 * 1024 * 1024;
            const selected = e.target.files[0];
            if (selected?.size > limitedMB) {
                fileError.value = `Size of the image must be less than 1MB.`;
                files.value = [];
            } else {
                if (selected && types.includes(selected.type)) {
                    image.value = {
                        name: selected.name,
                        src: URL.createObjectURL(selected),
                    };
                    files.value.push(selected);
                    fileError.value = null;
                } else {
                    fileError.value = `Only file of type jpg, jpeg, png, and svg.`;

                    files.value = [];

                }
            }

            imageFile.value = selected;
        }

        // const handlesumbit = async () => {
        //     try {
        //         const { addDocs } = useCollection("60Bazi");

        //         // const docDataList = [
        //         //     {
        //         //         elementName: "ឈើ",
        //         //         elementSign: "+",
        //         //         star: "រោង",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ឈើ",
        //         //         elementSign: "-",
        //         //         star: "ម្សាញ់",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ភ្លើង",
        //         //         elementSign: "+",
        //         //         star: "មមែ",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ភ្លើង",
        //         //         elementSign: "-",
        //         //         star: "វក",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ដី",
        //         //         elementSign: "+",
        //         //         star: "មមែ",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ដី",
        //         //         elementSign: "-",
        //         //         star: "វក",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "មាស",
        //         //         elementSign: "+",
        //         //         star: "ច",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "មាស",
        //         //         elementSign: "-",
        //         //         star: "កុរ",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ទឹក",
        //         //         elementSign: "+",
        //         //         star: "ឆ្លូវ",
        //         //         createdAt: timestamp.value
        //         //     },
        //         //     {
        //         //         elementName: "ទឹក",
        //         //         elementSign: "-",
        //         //         star: "ខាល",
        //         //         createdAt: timestamp.value
        //         //     }
        //         // ];

        //         const dataListAbove = [

        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "ជូត",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "ឆ្លូវ",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "ខាល",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "ថោះ",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "រោង",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "ម្សាញ់",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "មមី",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "មមែ",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "វក",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "រកា",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "ច",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "កុរ",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "ជូត",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "ឆ្លូវ",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "ខាល",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "ថោះ",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "រោង",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "ម្សាញ់",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "មមី",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "មមែ",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "វក",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "រកា",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "ច",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "កុរ",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "ជូត",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "ឆ្លូវ",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "ខាល",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "ថោះ",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "រោង",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "ម្សាញ់",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "មមី",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "មមែ",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "វក",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "រកា",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "ច",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "កុរ",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "ជូត",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "ឆ្លូវ",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "ខាល",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "ថោះ",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "រោង",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "ម្សាញ់",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "មមី",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "មមែ",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "វក",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "រកា",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "ច",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "កុរ",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "ជូត",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "ឆ្លូវ",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "+",
        //                 zodiacName: "ខាល",

        //             },
        //             {
        //                 elementName: "ឈើ",
        //                 elementSign: "-",
        //                 zodiacName: "ថោះ",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "+",
        //                 zodiacName: "រោង",

        //             },
        //             {
        //                 elementName: "ភ្លើង",
        //                 elementSign: "-",
        //                 zodiacName: "ម្សាញ់",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "+",
        //                 zodiacName: "មមី",

        //             },
        //             {
        //                 elementName: "ដី",
        //                 elementSign: "-",
        //                 zodiacName: "មមែ",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "+",
        //                 zodiacName: "វក",

        //             },
        //             {
        //                 elementName: "មាស",
        //                 elementSign: "-",
        //                 zodiacName: "រកា",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "+",
        //                 zodiacName: "ច",

        //             },
        //             {
        //                 elementName: "ទឹក",
        //                 elementSign: "-",
        //                 zodiacName: "កុរ",

        //             },


        //         ]

        //         // Generate and add 10 documents
        //         for (const docData of dataListAbove) {
        //             const code = await generateDocCode("60Bazi", "B-", "baziCode", 2);
        //             const datanoblemanstar = {
        //                 baziCode: code,
        //                 ...docData,
        //                 createdAt: timestamp.value
        //             };
        //             const success = await addDocs(datanoblemanstar);
        //             if (!success) {
        //                 console.log("Failed to add document:", datanoblemanstar);
        //                 return; // Exit early if any document fails to add
        //             }
        //         }

        //         console.log("All documents added successfully");
        //     } catch (err) {
        //         console.log("Failed to insert", err);
        //     }
        // };



        // const fetchData = async () => {
        //     try {
        //         await getCollectionQuery("elements", [orderBy("elementCode")], (data) => {
        //             datasElements.value = data;
        //         }, true);

        //     } catch (err) {
        //         console.log("failed to fetch data", err);
        //     }
        // }



        // const fetchZodiac = async () => {
        //     try {
        //         await getCollectionQuery("zodiacSigns", [orderBy("zodiacCode")], (data) => {
        //             dataZodiac.value = data;

        //         }, true);

        //     } catch (err) {
        //         console.log("failed to fetch data", err);
        //     }
        // }


        const dataDE = ref([]);
        const fetchDeathandEmptinessStar = async () => {
            try {
                await getCollectionQuery("death&emptiness", [orderBy("starCode")], (data) => {
                    dataDE.value = data;
                }, null, true);
            } catch (err) {
                console.log("faled to fetch data", err);
            }
        }
        const dataNB = ref([]);
        const fetchNBStar = async () => {
            try {
                await getCollectionQuery("noblemanStar", [orderBy("starCode")], (data) => {
                    dataNB.value = data;

                }, null, true);
            } catch (err) {
                console.log("faled to fetch data", err);
            }
        }

        const dataLoveStar = ref([]);
        const fetchLoveStar = async () => {
            try {
                await getCollectionQuery("peachBlossomStar", [orderBy("starCode")], (data) => {
                    dataLoveStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const dataITStar = ref([]);
        const fetchITStar = async () => {
            try {
                await getCollectionQuery("intelligenceStar", [orderBy("starCode")], (data) => {
                    dataITStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        const dataSHStar = ref([]);
        const fetchSHStar = async () => {
            try {
                await getCollectionQuery("skyHorseStar", [orderBy("starCode")], (data) => {
                    dataSHStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataSLStar = ref([]);
        const fetchSLStar = async () => {
            try {
                await getCollectionQuery("solitrayStar", [orderBy("starCode")], (data) => {
                    dataSLStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataGD = ref([]);
        const fetchGDStar = async () => {
            try {
                await getCollectionQuery("goldenCarriageStar", [orderBy("starCode")], (data) => {
                    dataGD.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const dataPoolStar = ref([]);
        const fetchpoolStar = async () => {
            try {
                await getCollectionQuery("poolStar", [orderBy("starCode")], (data) => {
                    dataPoolStar.value = data;
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }




        const dataBazi = ref([]);
        const fetch60Bazi = async () => {
            try {
                await getCollectionQuery("60Bazi", [orderBy("baziCode")], (data) => {
                    dataBazi.value = data;
                }, null, true);




            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }

        // Chunk the data into rows with up to 10 items per row
        const chunkedData = computed(() => {
            const result = [];
            let currentRow = []; // Track items for the current row
            for (const item of dataBazi.value) {
                if (currentRow.length === 10) {
                    result.push(currentRow); // Add completed row to the result
                    currentRow = []; // Reset current row
                }
                currentRow.push(item); // Add item to current row
            }
            if (currentRow.length > 0) {
                result.push(currentRow); // Add the last row if it's not full
            }
            return result;
        });


        const dataAgeW = computed(() => {
            const reversedData = dataAgeWalk.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });


        const dataRangeAgeWalk = computed(() => {
            const reversedData = rangeAgeWalk.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });

        const datarangeYearFuture = computed(() => {
            const reversedData = rangeYearFuture.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        });



        const dataYearFuture = computed(() => {
            const reversedData = dataYear.value.slice().reverse(); // Create a copy of dataAgeWalk and reverse it
            const result = [];
            let currentRow = [];

            for (let i = 0; i < reversedData.length; i++) {
                const item = reversedData[i];
                currentRow.push(item);

                // If the current row has 10 items or it's the last item, push it to the result and start a new row
                if (currentRow.length === 10 || i === reversedData.length - 1) {
                    result.push(currentRow);
                    currentRow = [];
                }
            }

            return result;
        })






        const handleReset = () => {
            // year.value = currentDate.getFullYear();
            // month.value = currentDate.getMonth();
            // day.value = currentDate.getDate();
            // time.value = currentDate.getHours();
            // minutes.value = currentDate.getMinutes();
            updateDateTime();

            handleCalculatePrediction();

            gender.value = null;
            kouyNumber.value = null;
            gender.value = null;
            userAge.value = null;
            unknownTime.value = false;
        }

        onMounted(() => {
            handleCalculatePrediction();
            fetchDeathandEmptinessStar();
            fetchNBStar();
            fetchLoveStar();
            fetchITStar();
            fetchSHStar();
            fetchSLStar();
            fetchGDStar();
            fetchpoolStar();
            fetch60Bazi();
        })

        return {
            unknownTime,
            dataYearFuture,
            datarangeYearFuture,
            dataRangeAgeWalk,
            rangeAgeWalk,
            dataAgeW,
            dataAgeWalk,
            chunkedData,
            dataBazi,
            dataResultStarPredict,
            dataDE,
            optionMinutes,
            handleCalculatePrediction,
            rootZodiac,
            minutes,
            userAge,
            kouyNumber,
            dataResultPredict,
            twin,
            gender,
            handleReset,
            monthoptions, timeoptions,
            yearoptions,
            dayoptions,
            dataZodiac,
            day,
            month, year,
            time,
            datasElements,
            handleFileChange,
            // handlesumbit
        }
    }
}
</script>

<style>
table {
    border-collapse: collapse;
    width: 20%;
    border: 2px solid #ddd;

    /* Border around the table */
}

th,
td {
    padding: 8px;
    text-align: left;
    border-bottom: 2px solid #ddd;
    /* Bottom border for table cells */
}

th {
    background-color: #f2f2f2;
    /* Background color for table header */
}

tr:last-child td {
    border-bottom: none;

    /* Remove bottom border for last row */
}

.cell-container {
    display: flex;
    flex-direction: column;
}

.table-cell {
    padding: 8px;
    text-align: center;
}

/* Border style for left, right, and bottom of each cell */
.table-cell {
    border-right: 2px solid #ddd;
    /* Right border */
    border-bottom: 2px solid #ddd;
    /* Bottom border */
}

/* Remove right border for last cell in each row */
.table-cell:last-child {
    border-right: none;
}
</style>