<template>
    <div class="bg-secondary-light py-4">
        <div class="max-w-[90%] xl:max-w-[1000px] mx-auto">
            <h2 class="text-[18px] font-medium space-x-1 py-4">
                <router-link :to="{ name: 'all-course-category-page', params: { allCourse: 'allCourse' } }" class="text-quaternary" >Course</router-link>
                <span>/</span>
                <span class="text-tertiary">{{ formatCategoryName(idCate) }}</span>
            </h2>

            <div class="hidden md:block">
                <div v-if="courseByCateData.length != 0" class="mt-5">
                    <CardVue :courseByCate="courseByCateData" />
                </div>

                <div v-else>
                     <p>This Category doesn't have course yet! </p>
                </div>
            </div>

            <div class="block md:hidden">
                <div v-if="courseByCateData.length != 0">
                    <MobileCardVue :courseByCate="courseByCateData" />
                </div>
                <div v-else>
                     <p>This Category doesn't have course yet! </p>
                </div>
            </div>

            <div v-if="courseByCateData.length != 0" class="flex justify-center space-x-4 my-4">
                <button class="btn flex items-center space-x-1 " @click="loadPreviousPage"
                    :disabled="currentPage === 1"
                    :class="currentPage == 1 && 'cursor-not-allowed'"
                    >
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
                        </svg>
                    </span>
                    <span>Prevoius</span>
                </button>
                <button class="btn rounded-full">{{ currentPage }}</button>
                <button class="btn flex items-center space-x-1" @click="loadNextPage"
                    :disabled="totalPages === 0 || totalPages === 1 || currentPage === totalPages"
                    :class="currentPage == 1 && 'cursor-not-allowed'"
                    >
                    <span>Next</span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                        </svg>
                    </span>
                </button>
            </div>

            <div class="mt-20">
                <h4 class="text-[16px] sm:text-[24px]">You can view more Category</h4>

                <div class="flex flex-wrap  gap-4 my-4">
                    <button  v-for="c in filteredCategories   " :key="c.id" @click="showCourseByCate(c.id)" class="btn-outline">{{ c.categoryName }}</button>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import CardVue from "@/components/CardVue.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import getCollectionPaginate from "@/composables/getCollectionPaginate2";
import { where, limit, orderBy } from 'firebase/firestore';
import { getCollectionQuery } from "@/composables/getCollection";
import MobileCardVue from '@/components/MobileCardVue'
export default {
    components: {
        CardVue,
        MobileCardVue
    },
    setup() {
        const currentPage = ref(1);
        const totalPages = ref(0);
        const route = useRoute();
        const router = useRouter();
        const idCate = ref();

        const courseByCateData = ref([]);
        const pageSize = ref(15);
        const paginateData = ref([])


        const queryDocs = ref([where("categoryID", "==", route.params.id),where("status","==",true), limit(pageSize.value), orderBy("createdAt")]);


        paginateData.value = getCollectionPaginate("courses", queryDocs.value, async (data) => {
            courseByCateData.value = data;

        });


        // Watch for changes in route.params.id
        watch(() => route.params.id, async (newId) => {

            if (newId) {
                courseByCateData.value = [];
                idCate.value = newId;


                queryDocs.value = [
                    where("categoryID", "==", newId),
                    where("status","==",true),
                    limit(pageSize.value),
                    orderBy("createdAt"),
                ];


                paginateData.value = getCollectionPaginate("courses", queryDocs.value, (data) => {

                    courseByCateData.value = data;

                });





                const totalCourse = await paginateData.value.getCollectionLength("courses", [where("categoryID", "==", newId)]);

                totalPages.value = Math.ceil(
                    totalCourse / pageSize.value
                );
                currentPage.value = 1;
                console.log("total Pages", totalPages)

                




            }

        });

        const showCourseByCate = async (catID) => {
            const path = 'by-course-category-page'; 
            const params = { id: catID }; 
            router.push({name:path , params });
        }







        const loadPreviousPage = async () => {
            try {
                const firstDocument = courseByCateData.value[0];

                if (firstDocument) {
                    await paginateData.value.previousPage(firstDocument.id, pageSize.value);
                    currentPage.value -= 1;
                }
            } catch (err) {
                console.error("Error fetching previous page:", err);
            }
        };

        const loadNextPage = async () => {
            try {
                const lastDocument = courseByCateData.value[courseByCateData.value.length - 1];

                if (lastDocument) {
                    await paginateData.value.nextPage(lastDocument.id, pageSize.value);
                    currentPage.value += 1;
                }
            } catch (err) {
                console.error("Error fetching next page:", err);
            }
        };

        const courseCategories = ref([]);
        const moreCategory = ref([]);
        const fetchCategory = async () => {
            try {
                await getCollectionQuery("course-categories", [where("status", "==", true),orderBy("createdAt")], (data) => {
                    courseCategories.value = data;
             
                    moreCategory.value = data;
                 
                }, null, true);
            } catch (err) {
                console.log("failed to fetch data", err);
            }
        }


        const filteredCategories = computed(() => {
      return moreCategory.value.filter(c => c.id !== idCate.value);
    });



        const formatCategoryName = (ID) => {
            const category = courseCategories.value.find((c) => c.id === ID);
            return category ? category.categoryName : "";
        };


        onMounted(async () => {
            idCate.value = route.params.id;
           await fetchCategory();

            const totalCourse = await paginateData.value.getCollectionLength("courses", [where("categoryID", "==", idCate.value)]);

            totalPages.value = Math.ceil(
                totalCourse / pageSize.value
            );


        })

        return {
            filteredCategories ,
            showCourseByCate,
            moreCategory,
            idCate,
            formatCategoryName,
            currentPage,
            totalPages,
            courseByCateData,
            loadPreviousPage,
            loadNextPage,

        };
    },
};
</script>