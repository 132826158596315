<template>
    <div class="grid place-items-center h-screen font-notoSans">
                <div class="text-center text-tertiary flex flex-col gap-5">
                        <h1 class="text-[30px] md:text-[48px] font-bold">404 Page not found</h1>
                       
                        <p>Look like something's broken. It's not you it's us. <br>
                           How about going back to the home page?
                        </p>
                        <router-link to="/" class="btn w-fit mx-auto">Home page</router-link>
                </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>